import React from 'react';

const WebsiteLive = ({liveUrl}) => {
  return (
    <div className="h-screen bg-no-repeat bg-cover flex items-center justify-center" style={{ backgroundImage: "url('https://img.freepik.com/free-vector/men-hands-with-party-confetti-decoration_24877-54067.jpg')" }}>
      <div className="text-center p-5">
        <h1 className="text-4xl font-extrabold text-deep-purple-800  shadow-purple-500/50 mb-4">Congratulations!</h1>
        <p className="text-xl text-gray-800  shadow-purple-500/50 mb-6">Your website is now live. Start sharing it with the world!</p>
        <a
  href={liveUrl}
  target="_blank"
  rel="noopener noreferrer"
  className="bg-white text-deep-purple-800 hover:bg-purple-100 font-bold py-2 px-4 rounded-full transition-all duration-300 ease-in-out shadow-md"
>
  Visit Your Website
</a>

      </div>
    </div>
  );
}

export default WebsiteLive;
