import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FiTrash, FiUpload } from "react-icons/fi";
const Accordian = ({
  infoBoxes,
  setIsModalOpen,
  setHeaderColorModalOpen,
  deleteInfoBox,
  handleTextChange,
  setTextColorModalOpen,
  setCurrentInfoBoxId,
  infoBoxErrors,
  deleteImageIcon,
  setIsGalleryModalOpen,
}) => {
  return (
    <>
      {infoBoxes.map((infoBox, idx) => (
        <Accordion key={infoBox.id} className="mt-4">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Information Box {idx + 1}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-col space-y-4 w-full">
              <div className="flex justify-between items-start space-x-4">
                <div className="w-1/2">
                  <label
                    htmlFor="colorPicker1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Background Color
                  </label>
                  {/* Your input or color picker for left side */}
                  <div className="flex justify-start items-start space-x-4 w-full">
                    <div
                      id="colorPicker"
                      className="mt-1 w-full border rounded p-2 cursor-pointer flex items-center space-x-2"
                      onClick={() => {
                        setIsModalOpen(true);
                        setCurrentInfoBoxId(infoBox.id); // Set the currently edited InfoBox ID
                      }}
                    >
                      <div
                        className="w-10 h-10 border rounded"
                        style={{ backgroundColor: infoBox.background_colour }}
                      ></div>
                      <code className="bg-gray-100 p-1 rounded">
                        {infoBox.background_colour}
                      </code>
                    </div>
                  </div>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="iconUpload"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Icon
                  </label>
                  <div className="mt-1 w-full border rounded p-2 cursor-pointer flex justify-center items-center space-x-2">
                    <button
                      onClick={() => {
                        setCurrentInfoBoxId(infoBox.id);
                        setIsGalleryModalOpen(true);
                      }}
                      className="flex items-center space-x-2 cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-full lg:w-auto overflow-hidden mb-2 lg:mb-0"
                    >
                      <FiUpload className="xl:hidden block"/>
                      <span className="hidden xl:inline">Upload Icon</span>
                    </button>
                    {infoBox.icon && (
                      <div className="flex flex-wrap items-center space-x-2 pl-4">
                        <img
                          src={infoBox.icon}
                          alt="Selected Icon"
                          className="h-20 w-20 rounded "
                        />
                       <button
    onClick={() => {
      setCurrentInfoBoxId(infoBox.id);
      deleteImageIcon(infoBox.id);
    }}
  className="cursor-pointer  mt-2 xl:mt-0  text-red-600 rounded-full pl-4"
>
  <FiTrash />
</button>
                      </div>
                    )}
                  </div>
                  {infoBoxErrors[`icon-${idx}`] && (
                    <span className="text-red-500 font-poppins text-sm">
                      {infoBoxErrors[`icon-${idx}`]}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-start space-x-4">
                <div className="w-1/2">
                  <label
                    htmlFor="colorPicker1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Header Color
                  </label>
                  {/* Your input or color picker for left side */}
                  <div className="flex justify-start items-start space-x-4 w-full">
                    <div
                      id="colorPicker"
                      className="mt-1 w-full border rounded p-2 cursor-pointer flex items-center space-x-2"
                      onClick={() => {
                        setHeaderColorModalOpen(true);
                        setCurrentInfoBoxId(infoBox.id);
                      }}
                    >
                      <div
                        className="w-10 h-10 border rounded"
                        style={{ backgroundColor: infoBox.header_colour }}
                      ></div>
                      <code className="bg-gray-100 p-1 rounded">
                        {infoBox.header_colour}
                      </code>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 ">
                  <label
                    htmlFor="colorPicker3"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Text Color
                  </label>
                  {/* Your input or color picker for left side */}
                  <div className="flex justify-start items-start space-x-4 w-full">
                    <div
                      id="colorPicker"
                      className="mt-1 w-full border rounded p-2 cursor-pointer flex items-center space-x-2"
                      onClick={() => {
                        setTextColorModalOpen(true);
                        setCurrentInfoBoxId(infoBox.id);
                      }}
                    >
                      <div
                        className="w-10 h-10 border rounded"
                        style={{ backgroundColor: infoBox.text_colour }}
                      ></div>
                      <code className="bg-gray-100 p-1 rounded">
                        {infoBox.text_colour}
                      </code>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-start space-x-4">
                <div className="w-1/2">
                  <label
                    htmlFor={`header_text-${infoBox.id}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Header Text (Max 30 characters)
                  </label>

                  <input
                    id={`header_text-${infoBox.id}`}
                    name="header_text"
                    type="text"
                    maxLength="30"
                    placeholder={infoBox.header_text}
                    value={infoBox.header_text}
                    onChange={(e) => handleTextChange(e, infoBox.id)} // pass the id for specific handling
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  {infoBoxErrors[`header_text-${idx}`] && (
                    <span className="text-red-500 font-poppins text-sm">
                      {infoBoxErrors[`header_text-${idx}`]}
                    </span>
                  )}
                </div>
                <div className="w-1/2">
  <label
    htmlFor={`sub_text-${infoBox.id}`}
    className="block text-sm font-medium text-gray-700"
  >
    Sub Text (Max 80 characters)
  </label>

  <input
    id={`sub_text-${infoBox.id}`}
    name="sub_text"
    type="text"
    placeholder={infoBox.sub_text}
    value={infoBox.sub_text}
    onChange={(e) => handleTextChange(e, infoBox.id)} // pass the id for specific handling
    maxLength="80"
    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
  />
  {infoBoxErrors[`sub_text-${idx}`] && (
    <span className="text-red-500 font-poppins text-sm">
      {infoBoxErrors[`sub_text-${idx}`]}
    </span>
  )}
</div>

              </div>

              {/* Add more alternating rows as needed */}

              {/* The existing color picker modal trigger */}
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => deleteInfoBox(infoBox.id)}
                  className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg shadow-md"
                >
                  Delete
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default Accordian;
