import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../config";
const REACT_APP_BASE_URL = BASE_URL;
const ShimmerLoader = ({ count = 3 }) => {
  return (
    <div className="max-w-screen-lg mx-auto py-8 px-4">
      <div className="flex overflow-x-auto space-x-4">
        {Array(count)
          .fill()
          .map((_, idx) => (
            <div
              key={idx}
              className="flex-none w-64 rounded overflow-hidden shadow-lg p-4 bg-white animate-pulse"
            >
              <div className="w-full h-48 bg-gray-200"></div>
              <div className="mt-4">
                <div className="h-4 bg-gray-200 w-3/4 mx-auto rounded"></div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const Preview = ({ widgetId, showPreview }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function fetchData() {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/product-slider/preview/${widgetId}`
      );
      setProducts(response.data);
      setLoading(false);
    } catch (err) {
      console.log("error", err.response.data.message);
      setError(err.response.data.message);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, [showPreview]);

  if (isLoading) {
    return <ShimmerLoader count={products.length || 3} />;
  }

  return (
    <>
      {products.length != 0 ? (
        <div className="max-w-screen-lg mx-auto border-2 border-gray-200 p-3 rounded-lg">
          <div className="flex overflow-x-auto space-x-4">
            {products.map((product, idx) => (
              <div
                key={idx}
                className="flex-none w-64 rounded overflow-hidden shadow-lg p-4 bg-white"
              >
                <img
                  src={atob(product.image_url)}
                  alt={product.product_name}
                  className="w-full h-48 object-cover"
                />
                <div className="mt-4">
                  <div className="text-md text-center font-poppins capitalize">
                    {product.product_name}
                  </div>
                  {product.sku ? (
                    <div className="text-sm text-gray-600 text-center font-poppins ">
                      Sku : {product.sku}
                    </div>
                  ) : (
                    false
                  )}
                  <div className="text-md font-semibold mt-3 text-gray-600 text-center font-poppins">
                    {product.price ? (
                      <span>
                        {" "}
                        Price : ₹{" "}
                        {new Intl.NumberFormat("en-IN").format(product.price)}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="max-w-screen-lg mx-auto py-8 px-4 text-center">
          <div className="p-10 bg-white shadow-md rounded-xl">
            <svg
              className="mx-auto h-24 w-24 text-gray-400 mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
              ></path>
            </svg>
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              No products found
            </h2>
            <p className="text-gray-600">
              We couldn't find any products matching your menu select. Try
              refining your menu select or check back later!
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Preview;
