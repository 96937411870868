import React, { useState, useRef, useEffect } from "react";

/**
 * CustomDropdown Component:
 * A customizable dropdown component tailored for font selection. It supports search and outside click detection.
 *
 * @param {Array} fonts - Array of font objects that contain font details.
 * @param {string} value - The currently selected value from the dropdown.
 * @param {Function} onChange - Function to handle value changes.
 * @param {Object} errors - Object containing any potential errors related to font selection.
 */

const CustomDropdown = ({ fonts, value, onChange, errors }) => {
  // State to manage the visibility of the dropdown.
  const [isOpen, setIsOpen] = useState(false);
  // State to manage the search term entered by the user.
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFonts, setFilteredFonts] = useState([]);
  // Reference to the dropdown container for click outside logic.
  const dropdownRef = useRef(null);

  /**
   * Handler for detecting outside clicks.
   * If the click is outside the dropdown, the dropdown will be closed.
   *
   * @param {Event} event - The triggering event.
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  // Reference to the button that triggers the dropdown for positioning logic.
  const buttonRef = useRef(null);
  // State to store and manage the position of the dropdown.
  const [position, setPosition] = useState({ top: 0, left: 0 });

  /**
   * useEffect hook to adjust dropdown position.
   * This ensures the dropdown appears right below the button when opened.
   */
  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [isOpen]);

  /**
   * useEffect hook to handle outside clicks.
   * When the dropdown is open, this will listen for mousedown events and check if the click is outside the dropdown.
   */
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Filter the fonts list based on the search term.
  // const handleSearch = fonts
  // .filter((font) =>
  //   font.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    setFilteredFonts(fonts);
  }, [fonts, onChange]);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term) {
      const filtered = fonts.filter(font => font.toLowerCase().includes(term.toLowerCase()));
      setFilteredFonts(filtered);
    } else {
      setFilteredFonts(fonts);
    }
  };


  return (
    <>
      <div className="relative w-full " ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`border p-2 w-full flex justify-between items-center ${
            isOpen ? "opacity-0 pointer-events-none" : ""
          }`}
        >
          <span style={{ fontFamily: value }}>{value || "Select a font"}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {isOpen && (
          <div
            style={{
              position: "absolute",
              top: `${position.top}px`,
              left: `${position.left}px`,
              overflow: "auto",
              width: "100%",
              zIndex: 1000, // Ensure that this value is higher than the z-index of elements below the dropdown
              backgroundColor: "white", // Ensure the dropdown has a solid background color
            }}
            className={`origin-top-right relative right-0 mt-2 w-full border border-gray-200 rounded shadow max-h-40 overflow-y-auto max-w-md`}
          >
            <input
              type="text"
              placeholder="Search font..."
              className="w-full p-2 border-b border-gray-200 focus:outline-none focus:ring-0 focus:border-none focus:shadow-none"
              value={searchTerm}
              onChange={handleSearch}
            />

            {filteredFonts.length > 0 ? (
              filteredFonts.map((font, i) => (
                <div
                  key={i}
                  onClick={() => {
                    onChange(font);
                    setSearchTerm("");
                    setIsOpen(false);
                  }}
                  className="cursor-pointer p-2 hover:bg-gray-200"
                  style={{ fontFamily: font }}
                >
                  {font.replace(/_/g, " ")}
                </div>
              ))
            ) : (
              <div className="text-gray-500 font-poppins p-2 text-center">
                No Records Found...
              </div>
            )}
          </div>
        )}
        {/* <p className="text-red-500 text-sm font-poppins">{errors}</p> */}
      </div>
    </>
  );
};

export default CustomDropdown;
