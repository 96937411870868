import React, { useState, useEffect } from "react";
import ReactSwitch from "react-switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import useAxios from "../../Axios/useAxios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import Swal from "sweetalert2";
import { BASE_URL } from "../../config";
const REACT_APP_BASE_URL = BASE_URL;
/**
 * Products Component
 *
 * This component allows users to manage settings and preferences
 * related to products on the website. Users can toggle the view of products,
 * determine how many products to display, and fetch/update product-related data.
 *
 * @param {Function} onToggle - Callback to manage accordion toggling.
 * @param {string} activeAccordion - Represents the currently active accordion name.
 * @param {Function} IsSavedInputs - Callback to notify about unsaved inputs.
 */

const Products = ({ onToggle, activeAccordion, IsSavedInputs }) => {
  // State to manage if data has been submitted or not.
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isBannerEnabled, setIsBannerEnabled] = useState(true);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const handleImageSelect = (imageUrl) => {
    setProducts({ ...products, banner_image: imageUrl });
    closeModal();
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  /**
   * Toggles the state of the Products accordion.
   * If it's currently open, it'll close it. If it's closed, it'll open it.
   */

  const handleAccordionChange = () => {
    if (activeAccordion === "ProductsPageAccordian") {
      onToggle && onToggle(null); // close the current accordion
    } else {
      onToggle && onToggle("ProductsPageAccordian"); // open the clicked accordion
    }
  };
  // State to manage products settings.
  const [products, setProducts] = useState({
    products_page_id: null,
    displayProducts: 3,
    displaySingleProductView: false,
    is_weight_enabled: false,
    // is_banner_enabled: false,
    // banner_image: ''
  });
  // State to manage input validation errors.
  const [errors, setErrors] = useState({});
  /**
   * Toggles the display mode of products (single view or not).
   *
   * @param {boolean} checked - Indicates if single product view is active.
   */
  const handleToggle = (checked, type) => {
    if (type === "product_view") {
      setProducts({ ...products, displaySingleProductView: checked });
    } else {
      setProducts({ ...products, is_weight_enabled: checked });
    }
    IsSavedInputs();
  };

  // const handleIsBannerAvail = (checked) => {
  //   setProducts({ ...products, is_banner_enabled: checked });
  //   setIsBannerEnabled(!isBannerEnabled);
  // }

  /**
   * Prevents direct typing in an input field.
   *
   * @param {Event} event - The associated event object.
   */
  const preventDirectInput = (event) => {
    // Prevent direct typing
    event.preventDefault();
  };

  /**
   * Handles the change in the number of products displayed.
   *
   * @param {Event} event - The associated event object.
   */
  const handleDisplayProductsChange = (event) => {
    const newValue = parseInt(event.target.value, 10);

    if (!isNaN(newValue) && newValue >= 2 && newValue <= 3) {
      setProducts({ ...products, displayProducts: newValue });
    } else {
      // Optional: Set an error if the value is out of range
      setErrors({
        ...errors,
        displayProducts: "Product count must be between 2 and 3",
      });
    }
    IsSavedInputs();
  };

  /**
   * Fetches the product data from the server.
   * Sets the state based on the received data.
   */
  const fetchProductData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/products-page`);

      if (response.data) {
        setDataSubmitted(true);
      }
      if (response.data && response.data.length > 0) {
        const fetchedData = response.data[0];
        setProducts({
          products_page_id: fetchedData.products_page_id,
          displayProducts: fetchedData.display_products_count,
          displaySingleProductView:
            fetchedData.is_single_product_view === 1 ? true : false,
          is_weight_enabled:
            fetchedData.display_product_weight === 1 ? true : false,
          // is_banner_enabled: fetchedData.is_banner_enabled === 1 ? true : false,
          // banner_image: fetchedData.banner_image
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Products Page Not Found"
      ) {
        setDataSubmitted(false);
      }
      console.error("Error fetching data:", error);
    }
  };
  // Effect to fetch product data whenever `dataSubmitted` state changes.
  useEffect(() => {
    fetchProductData();
  }, [dataSubmitted]);

  /**
   * Submits the updated product settings to the server.
   * Provides feedback to the user based on the outcome of the request.
   */
  const handleSubmit = async () => {
    const payload = {
      is_single_product_view: products.displaySingleProductView ? 1 : 0,
      display_product_weight: products.is_weight_enabled ? 1 : 0,
      display_products_count: products.displayProducts,
      // is_banner_enabled: products.is_banner_enabled ? 1 : 0,
      // banner_image: products.banner_image
    };

    try {
      const response = await axios.patch(
        `${REACT_APP_BASE_URL}/products-page`,
        payload
      );
      if (response.status === 200 && response.data) {
        const fetchedData = response.data.productPageDetails[0];
        setProducts({
          products_page_id: fetchedData.products_page_id,
          displayProducts: fetchedData.display_products_count,
          displaySingleProductView:
            fetchedData.is_single_product_view === 1 ? true : false,
          is_weight_enabled:
            fetchedData.display_product_weight === 1 ? true : false,
        });
        Swal.fire({
          title: "Success!",
          text: "Datas Updated successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        setDataSubmitted(true);
        // fetchProductData();
      }
    } catch (error) {
      // Handle error
      console.error("Error updating data:", error);
      // Optionally, show an error message to the user.
    }
  };

  return (
    <>
      <Accordion
        expanded={activeAccordion === "ProductsPageAccordian"}
        onChange={handleAccordionChange}
        // expanded={isExpanded} onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="store-panel-content"
          id="store-panel-header"
        >
          <Typography
            className="font-medium text-xl"
            style={{ fontFamily: "poppins" }}
          >
            Products Page
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-2/3 space-y-4 mb-3">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <span className="font-poppins font-medium text-gray-600 w-full">
                  Display Single Products View
                </span>
                <ReactSwitch
                  id="toggle1"
                  checked={products.displaySingleProductView}
                  className="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={(status) => handleToggle(status, "product_view")}
                />
              </div>
              <div>
                <span className="font-poppins font-medium text-gray-600 w-full">
                  Display Product Weight
                </span>
                <ReactSwitch
                  id="toggle2"
                  checked={products.is_weight_enabled}
                  className="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={(status) => handleToggle(status, "product_weight")}
                />
              </div>
            </div>
            <label className="font-medium font-poppins text-gray-600 block">
              Display Products Count
            </label>
            <input
              type="number"
              min={2}
              max={3}
              onKeyDown={preventDirectInput}
              placeholder="Number of products to display"
              value={products.displayProducts}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              onChange={handleDisplayProductsChange}
            />
          </div>
          <button
            onClick={handleSubmit}
            className={`bg-gradient-to-r from-indigo-500 to-blue-600 mt-4 hover:from-indigo-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
              Object.keys(errors).length > 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            //   disabled={Object.keys(errors).length > 0}
          >
            {Object.keys(errors).length > 0
              ? "Fill the required fields"
              : dataSubmitted
              ? "Update"
              : "Save"}
          </button>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Products;
