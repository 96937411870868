import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { ClipLoader } from 'react-spinners';


// ="https://api.sportswander.com/images/event/sportswander_event-1709202010420.jpg"

const ImageCropper = ({
  imgURL, 
  dimension,
  onCropData
}) => {
  const [imageSrc, setImageSrc] = useState('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState(null)
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(1)
  const [loading, setLoading] = useState(false);

  const onCropComplete = useCallback(
    (croppedAreaPercentage, croppedAreaPixels) => {
      setCroppedArea(croppedAreaPixels)
    },
    []
  )

  const handleCrop = async () => {
    try {
      setLoading(true)
       await getCroppedImg(imageSrc, croppedArea)
      setTimeout(() => {
        setLoading(false)
      }, 4000);
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    setImageSrc(imgURL)
  }, [imgURL])

  useEffect(() => {
    let aspectRatio
    switch (dimension) {
      case '1:1':
        aspectRatio = 1
        break
      case '16:9':
        aspectRatio = 16 / 9
        break
      case '4:3':
        aspectRatio = 4 / 3
        break
      case '2:3':
        aspectRatio = 2 / 3
        break
      case '32:20':
        aspectRatio = 32 / 20
        break
      case '32:9':
        aspectRatio = 32 / 9
        break
      case "36:13": //for desktops
        aspectRatio = 36 / 13;
        break;
      case "36:18": //for tablets
        aspectRatio = 36 / 18;
        break;
      case "38:18": //for mobile devices
        aspectRatio = 38 / 18;
        break;
      case 'freeform':
        aspectRatio = NaN
        break
      default:
        aspectRatio = 1
        break
    }
    setSelectedAspectRatio(aspectRatio)
  }, [dimension])

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const canvas = document.createElement('canvas')
    const image = new Image()

    const ctx = canvas.getContext('2d')

    image.onload = () => {
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      canvas.width = pixelCrop.width
      canvas.height = pixelCrop.height

      ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      )

      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty')
          return
        }
        blob.name = 'cropped.jpg'
        window.URL.createObjectURL(blob)
      }, 'image/jpeg')

     const croppedBase64 = canvas.toDataURL();
      onCropData(croppedBase64);
    }

    image.src = imageSrc
    image.crossOrigin = 'anonymous'
  }

  return (
   (imgURL && 
   <div>
    <div style={{ width: '100%', height: 300, position: 'relative' }}>
      <Cropper
        image={imageSrc}
        crop={crop}
        zoom={zoom}
        aspect={selectedAspectRatio}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
      />
    </div>
    {/* <div>
      <p>Test</p>
      <img src={cropped} alt='test' />
    </div> */}
    <div className='flex justify-end'>
      <button
        className='bg-slate-500 my-2 p-1 rounded-md text-white mr-2 '
        onClick={handleCrop}
        >
        {loading ?  
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='mx-1'>
              <ClipLoader size={20}/>
            </div>
            <span className='ml-2'>Loading...</span>
          </div>
          : 
          "Crop & Save"
        }
      </button>
    </div>
  </div>) 
  )
}

export default ImageCropper