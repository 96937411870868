import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "animate.css";
import "tailwindcss/tailwind.css";
import "./shimmergodaddy.css";
import SSLComponent from "./SslCertificate";
// Import BASE_URL from config.js
import { BASE_URL } from '../../config';
import { useNavigate } from 'react-router-dom';
// Define a constant for the base URL of the application from environment variables.
const REACT_APP_BASE_URL = BASE_URL;

// Component: Shimmer
// Description: This component provides a shimmer effect placeholder, typically used during data loading.
const Shimmer = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-4 mt-8 animate__animated animate__fadeIn">
      <div className="w-full md:w-1/3">
        <div className="w-2/3 mx-auto h-24 animate-shimmer rounded"></div>
      </div>
      <div className="w-full md:w-1/3 text-center">
        <div className="h-6 mt-4 mb-2 w-3/4 mx-auto animate-shimmer rounded"></div>
        <div className="h-6 mb-2 w-3/4 mx-auto animate-shimmer rounded"></div>
        <div className="h-6 mb-4 w-1/4 mx-auto animate-shimmer rounded"></div>
        <div className="h-10 w-1/2 mx-auto animate-shimmer rounded"></div>
      </div>
      <div className="w-full md:w-1/3">
        <div className="w-2/3 mx-auto h-24 animate-shimmer rounded"></div>
      </div>
    </div>
  );
};
// Component: RelatedDomainShimmer
// Description: Placeholder for the related domain with shimmer effect.
const RelatedDomainShimmer = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <div className="flex flex-col space-y-4">
        <div className="animate-pulse text-center text-xl font-semibold mt-2 font-poppins bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-600 h-6 w-3/4 mx-auto rounded"></div>
        <div className="text-center h-6 w-3/4 mx-auto animate-shimmer rounded"></div>
        <div className="text-center h-6 w-3/4 mx-auto animate-shimmer rounded"></div>
        <div className="flex-grow"></div>
        <div className="mt-4 flex justify-center h-10 w-1/2 mx-auto animate-shimmer rounded"></div>
      </div>
    </div>
  );
};

// Component: GoDaddyUI
// Description: This component represents the main UI for domain search and related actions.
const GoDaddyUI = () => {
  // State variables to manage various UI states like SSL, loading, domain data, etc.
  const [showSSL, setShowSSL] = useState(false);
  const [showGoDaddy, setShowGoDaddy] = useState(true);
  let navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [domainData, setDomainData] = useState(null);
  const [suggestedDomains, setSuggestedDomains] = useState(null);
  const [sslAmount, setSslAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  // Event handler to set the domain suggestion on click.
  const handleSuggestionClick = (domain) => {
    setSearchInput(domain);
    localStorage.setItem('Domain', domain);
    setIsFocused(false);
  };
  
  // Event handler to toggle SSL view.
  function handleClick(domainName) {
    setShowSSL(true);
    setShowGoDaddy(false);
      // Also store the domain name in local storage
  localStorage.setItem('Domain', domainName);
    // Update the state that will be passed as a prop to SSLComponent
    setSearchInput(domainName);
  }

  // Function to handle the domain search operation.
  const handleSearch = async () => {
    setLoading(true);
    setIsFocused(false);
    setSearchPerformed(true);
    const apiUrl = `${REACT_APP_BASE_URL}/domain/suggest?domain_name=${searchInput}`;
   
    try {
      const response = await axios.get(apiUrl);
      const data = response.data;
      setDomainData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching domain data:", error);
    }
  };
  // Event listener to handle outside click for the focused state.
  useEffect(() => {
    if (isFocused) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFocused]);

  // Check and handle outside click for focused elements.
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsFocused(false);
    }
  };

  const wrapperRef = useRef(null);

  // Function to check if the searched domain is available.
  const isSearchedDomainAvailable = () => {
    if (domainData) {
      const searchedDomain = domainData.find(
        (domain) => domain.domain === searchInput
      );
      return searchedDomain && searchedDomain.available;
    }
    return false;
  };

  // Function to fetch domain suggestions based on input.
  const fetchDomainSuggestions = async (input) => {
    setLoading1(true);
    if (input.length > 1) {
      const apiUrl = `${REACT_APP_BASE_URL}/domain/suggest?domain_name=${input}`;

      try {
        const response = await axios.get(apiUrl);
        const data = response.data;
        setSuggestedDomains(data);
        setLoading1(false);
      } catch (error) {
        console.error("Error fetching domain suggestions:", error);
      }
    } else {
      setSuggestedDomains(null);
    }
  };
  // Debounce the fetch domain suggestions call to prevent multiple unnecessary requests.
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchDomainSuggestions(searchInput);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [searchInput]);

  // Function to format number in Indian rupees format.
  function formatIndianRupees(number) {
    const formatter = new Intl.NumberFormat("en-IN", {
      // style: 'currency',
      currency: "INR",
    });
    return formatter.format(number);
  }
  const handleBack = () => {
    setShowSSL(false);  // Hide SSLComponent
    setShowGoDaddy(true);  // Show GoDaddyUI
  };
  
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        {showGoDaddy ? (
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h1 className="text-center text-2xl lg:text-2xl rounded-lg font-poppins pb-4">
              Find Your Favourite Domain Here!
            </h1>
            <div
              ref={wrapperRef}
              className="flex flex-col md:flex-row justify-center mb-4"
            >
              <div className="relative w-full md:w-1/2">
                <input
                  type="text"
                  placeholder="Search for a domain"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  onFocus={() => setIsFocused(true)}
                  className={`transition-all duration-300 border-2 ${
                    isFocused ? "border-indigo-600" : "border-indigo-400"
                  } bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none w-full`}
                />
                {isFocused && suggestedDomains && (
                  <div
                    className={`absolute z-10 w-full mt-2 overflow-hidden bg-white p-2 rounded-lg shadow-lg transition-all duration-300 ease-in-out${
                      loading1 ? " shimmer-effect" : ""
                    }`}
                  >
                    {suggestedDomains.slice(0, 5).map((domain, index) => (
                      <div
                        key={index}
                        onClick={() => handleSuggestionClick(domain.domain)}
                        className={`text-sm text-gray-600 py-1 px-2 hover:bg-gray-100 cursor-pointer${
                          loading1 ? " shimmer-effect" : ""
                        }`}
                      >
                        {loading1 ? (
                          <span className="invisible">{domain.domain}</span>
                        ) : (
                          domain.domain
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <button
                onClick={handleSearch}
                className="bg-indigo-600 text-white px-4 py-2 ml-2 rounded-lg shadow-md text-sm focus:outline-none w-full md:w-auto mt-2 md:mt-0 transition duration-300 ease-in-out hover:bg-indigo-700"
              >
                Search
              </button>
            </div>
            {loading ? (
              <div className="mt-8 space-y-4">
                <Shimmer />
              </div>
            ) : isSearchedDomainAvailable() ? (
              <div className="flex flex-col md:flex-row justify-center items-center gap-4 mt-8 animate__animated animate__fadeIn">
                <div className="w-full md:w-1/3">
                  <img
                    className="w-2/3 mx-auto"
                    src="https://www.dimakhconsultants.com/img/web-hosting/domain-name-registration/overview.png"
                    alt="Domain-related icon"
                  />
                </div>
                <div className="w-full md:w-1/3 text-center">
                  <div className="text-green-600 font-bold font-poppins capitalize">
                  Good News..Your Domain Is Available for Registration ...!
                  </div>
                  <div className="text-xl font-semibold mt-2 font-poppins bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-600">
                    Domain Name : {domainData[0].domain}
                  </div>
                  <div className="text-green-600 text-xl font-semibold mt-1 font-poppins">
                    ₹ {formatIndianRupees(Math.round(domainData[0].price))} for{" "}
                    {domainData[0].period} Year
                  </div>
                  <div className="mt-4">
                    <button
                      onClick={() => handleClick(domainData[0].domain)}
                      className="bg-indigo-600 text-white px-4 py-2 rounded-lg shadow-md text-sm focus:outline-none transform hover:scale-105 transition-all duration-300 ease-in-out"
                    >
                      Proceed to Buy
                    </button>
                  </div>
                </div>
                <div className="w-full md:w-1/3">
                  <img
                    className="w-2/3 mx-auto"
                    src="https://www.dimakhconsultants.com/img/web-hosting/domain-name-registration/overview.png"
                    alt="Domain-related icon"
                  />
                </div>
              </div>
            ) : searchPerformed ? (
              <div className="text-center mt-8 animate__animated animate__fadeIn mb-3">
                <p className="text-green-600 font-bold">
              <span className="text-gray-700"> Sorry, your domain is not available for Registration ...! </span>  <br/> <span className="text-green-700">  Please select from the list of available domains below.</span>
                </p>
              </div>
            ) : null}
            {loading ? (
              <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 animate__animated animate__fadeIn">
                {Array.from({ length: 12 }).map((_, index) => (
                  <RelatedDomainShimmer key={index} />
                ))}
              </div>
            ) : (
              <>
                {domainData && (
                  <h1 className="text-center text-2xl lg:text-2xl rounded-lg font-poppins pb-4 pt-4">
                    Related Domains Here!
                  </h1>
                )}
                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 animate__animated animate__fadeIn">
                  {domainData?.map((domain, index) => (
                    <div
                      key={index}
                      className="bg-white p-4 rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
                    >
                      <div className="flex flex-col space-y-4">
                        <div className=" text-center text-xl font-semibold mt-2 font-poppins bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-600">
                          {domain.domain}
                        </div>
                        <div className="text-center text-lg text-green-600 font-semibold font-poppins">
                          ₹ {formatIndianRupees(Math.round(domain.price))}
                        </div>
                        <div className="text-center text-lg text-gray-600 font-poppins">
                          for {domain.period} year
                        </div>
                        <div className="flex-grow"></div>
                        <div className="mt-4 flex justify-center">
                          <button
                             onClick={() => handleClick(domain.domain)}
                            className="bg-indigo-600 text-white px-4 py-2 rounded-lg shadow-md text-sm focus:outline-none transform hover:scale-110 transition-all duration-300 ease-in-out"
                          >
                          Proceed to Buy
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ) : null}
        {showSSL && <SSLComponent domainName={searchInput} onBack={handleBack} />}

      </div>
    </div>
  );
};

export default GoDaddyUI;
