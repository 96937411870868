import React from "react";
import { useInView } from "react-intersection-observer";

const LazyImage = ({ src, alt, handleImageClick }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} style={{ minHeight: "150px", position: "relative" }}>
      {inView ? (
        <img
          src={src}
          alt={alt}
          className="w-full h-auto object-cover rounded-lg shadow-md transition duration-200 ease-in-out transform hover:scale-105"
          onClick={() => handleImageClick(src)}
        />
      ) : (
        <div className="w-9 h-9 border-4 border-solid border-gray-200 border-t-blue-500 rounded-full animate-spin absolute top-16 left-16"></div>
      )}
    </div>
  );
};

export default LazyImage;
