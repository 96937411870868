import React from "react";
import logo from "../../../images/logo.webp";
import banner from "../../../images/at_banner.webp";
import at1 from "../../../images/at_earring.webp";
import at2 from "../../../images/at_necklace.webp";
import at3 from "../../../images/at_haram.webp";
import at4 from "../../../images/at_haram1.webp";
import at5 from "../../../images/at_bangles1.webp";
import at6 from "../../../images/at_menring.webp";
import at7 from "../../../images/at_jhumka.webp";
import at8 from "../../../images/at_bangles2.webp";
import at9 from "../../../images/at_earring1.webp";
import at10 from "../../../images/at_womenring.webp";
import at11 from "../../../images/at_dhokra.webp";
import at12 from "../../../images/at_buddha.webp";
import at13 from "../../../images/at_bangles3.webp";
import at14 from "../../../images/at_ottiyanam.webp";
import at15 from "../../../images/at_s1.webp";
import at16 from "../../../images/at_s2.webp";
import at17 from "../../../images/at_s3.webp";
import { FaSearch } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaBars } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";

const AntiqueTheme = () => {
  return (
    <>
      <div className="bg-secondary py-2">
        <div className="container mx-auto grid grid-cols-2">
          <div className="text-lg font-semibold">Today's Metal Rate</div>
          <div className="flex justify-end items-center text-lg font-semibold">
            Plan Your Investment
          </div>
        </div>
      </div>

      <div className="container mx-auto flex items-center justify-between py-4">
        <div className="flex items-center">
          <img className="w-64 h-24" src={logo} alt="img" />
        </div>

        <div className="flex-1 px-4">
          <div className="relative flex items-center justify-center w-full">
            <input
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded-lg p-4 pr-12 w-2/3"
            />
            <FaSearch className="absolute right-40 mr-3 text-gray-400 bg-primary p-2 w-14 h-full rounded text-white" />
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex flex-col items-center mr-4">
            <IoBag className="text-[25px]" />
            <span className="text-md font-semibold">Cart</span>
          </div>

          <div className="flex flex-col items-center mr-4">
            <FaRegHeart className="text-[25px]" />
            <span className="text-md font-semibold">Wishlist</span>
          </div>

          <div className="flex flex-col items-center">
            <FaUser className="text-[25px]" />
            <span className="text-md font-semibold">Login</span>
          </div>
        </div>
      </div>

      <div className="bg-secondary">
        <div className="container mx-auto text-md flex items-center space-x-8">
          <div className="flex items-center">
            <h1 className="bg-primary text-white px-4 py-2 rounded flex items-center">
              <FaBars className="mr-2" />
              Categories
            </h1>
          </div>

          <ul className="list-none p-0 m-0 flex space-x-10 font-semibold">
            <li>Home</li>
            <li>About Us</li>
            <li>Contact Us</li>
            <li>Gold Plan</li>
          </ul>
        </div>
      </div>

      {/* End of Header Section */}

      <div className="">
        <img className="image" src={banner} alt="banner-image"></img>
      </div>

      {/* End of Banner Content */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Adorn Yourself with Antique Elegance
      </div>

      <div className="container mx-auto grid grid-cols-3 gap-4">
        <div className="flex justify-center">
          <img className="image " src={at1} alt="img"></img>
        </div>
        <div className="flex justify-center ">
          <img className="image " src={at2} alt="img"></img>
        </div>
        <div className="flex justify-center">
          <img className="image" src={at3} alt="img"></img>
        </div>
      </div>

      {/* End of section1 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Delight in Our Classic Jewellery Collection
      </div>

      <div className=" container mx-auto grid grid-cols-2 gap-4">
        <img className="image" src={at4} alt="img"></img>
        <div className="grid grid-cols-2 grid-rows-2 gap-2">
          <img className="image" src={at5} alt="img"></img>
          <img className="image" src={at6} alt="img"></img>
          <img className="image" src={at7} alt="img"></img>
          <img className="image" src={at8} alt="img"></img>
        </div>
      </div>

      {/* End of section2 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        See Our Jewels Shine in Every Frame
      </div>

      <div className=" container mx-auto grid grid-cols-5 gap-4">
        <div className="justify-self-center">
          <img className="image" src={at9} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">Earrings</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={at10} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">Rings</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={at11} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">
            Dhokra Wall Framing
          </p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={at12} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">Buddha</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={at13} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">Bangles</p>
        </div>
      </div>

      {/* End of section3 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Revel in the Beauty of Classic Jewellery
      </div>

      <div className="container mx-auto ">
        <img className="image" src={at14} alt="img"></img>
      </div>

      {/* End of section4 */}

      <div
        className="flex justify-center 
    text-2xl font-semibold my-7"
      >
        Elegant Artistry
      </div>

      <div className="container mx-auto grid grid-cols-3 gap-4">
        <div className="flex justify-center">
          <img className="image " src={at15} alt="img"></img>
        </div>
        <div className="flex justify-center">
          <img className="image" src={at16} alt="img"></img>
        </div>
        <div className="flex justify-center">
          <img className="image" src={at17} alt="img"></img>
        </div>
      </div>

      {/* End of section4 */}

      <div className="bg-primary w-full mt-5">
        <div className="container mx-auto py-4">
          <div className="grid grid-cols-2 gap-8">
            <article className="text-white flex items-center space-x-4">
              <div className="flex items-center justify-center border border-slate-50 rounded-full bg-slate-50 w-10 h-10">
                <FaBell className="text-black" />
              </div>
              <div>
                <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                <p>
                  Get all the latest information on Events, Sales and Offers.
                </p>
              </div>
            </article>

            <form className="flex items-center">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full px-3 py-2 border rounded-lg mr-2"
              ></input>
              <button
                type="submit"
                className="bg-md_secondary text-black font-semibold px-4 py-2 rounded-lg"
              >
                <span>SUBSCRIBE</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-secondary grid grid-cols-3 gap-4 ">
        <div className="flex justify-center">
          <img className="image w-64 h-24  " src={logo} alt="img"></img>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">GET US HELP YOU</h2>
          <ul className="list-none p-0 m-0">
            <li className="py-2 ">Contact Us</li>
            <li className="py-2">Terms and Condition</li>
            <li className="py-2">FAQ</li>
          </ul>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">OUR POLICIES</h2>
          <ul className="list-none p-0 m-0 ">
            <li className="py-2 ">Privacy Policy</li>
            <li className="py-2">Shipping Policy</li>
            <li className="py-2 ">Cancellation Policy</li>
            <li className="py-2 ">Exchange Policy</li>
          </ul>
        </div>
      </div>

      <div className=" bg-primary ">
        <div className="container mx-auto text-white">
          ⓒ All rights reserved
        </div>
      </div>

      {/* End of footer section */}
    </>
  );
};

export default AntiqueTheme;
