import { useState, useRef, useEffect, useCallback,React } from "react";
import { Tab } from "@headlessui/react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import Swal from "sweetalert2";
import { AiFillHeart, AiOutlineHeart, AiOutlineClose } from "react-icons/ai";
import "./style.css";
import { useDropzone } from "react-dropzone";
import axios from "axios";
// Import BASE_URL from config.js
import { BASE_URL } from '../config';

//  Gallery component for displaying and managing user's own photos, public photos, and wishlist
//  Features:
//  1. Display public photos fetched from API
//  2. Display own photos fetched from API
//  3. Upload new own photos with aspect ratio selection and image cropping
//  4. Add/remove photos to/from wishlist
//  5. Delete own photos with confirmation prompt
//  6. Shimmer loading effect for own and public photos
//  7. Display upload progress bar for new own photos
{
  /* <div className=" w-full 2xl:mr-[-17rem]  lg:h-[25rem]   md:w-[18%] p-4 bg-gradient-to-br from-blue-500 rounded-lg via-purple-500 to-red-500 "> */
}
function DefaultGallery() {
  const [activeTab, setActiveTab] = useState("own");
  const [publicPhotos, setPublicPhotos] = useState([]);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState("1:1");
  const [purposeFor, setPurposeFor] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [wishlist, setWishlist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const imageElement = useRef(null);
  const [ownPhotos, setOwnPhotos] = useState([]);
  const [loadingImages, setLoadingImages] = useState([]);
  const [user_id, setUserId] = useState("124");
  // Function to handle tab change
  const handleTabChange = (event) => {
    setActiveTab(event.target.value);
  };

  // Function to fetch public photos from API
  const fetchPublicPhotos = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${BASE_URL}/gallery-uploads?type=2&wishlist_jbo_id=${user_id}`
      ); // Replace with the actual API URL for public photos
      const fetchedPhotos = response.data.map((photo, index) => ({
        id: photo.uploads_id,
        imageUrl: photo.url,
        isLiked: photo.is_liked,
      }));
      setPublicPhotos(fetchedPhotos);
    } catch (err) {
      setError(err.message);
    }
    setIsLoading(false);
  };

  // useEffect to fetch public photos when activeTab changes
  useEffect(() => {
    if (activeTab === "public") {
      fetchPublicPhotos();
    }
  }, [activeTab]);

  // Progress Bar component
  const ProgressBar = ({ progress }) => (
    <div className="w-full h-8 bg-gray-200 rounded-lg relative">
      <div
        className="h-full bg-gradient-to-r from-blue-500 mt-[16px]  via-purple-500 to-red-500 w-full transition-all duration-300 ease-in-out"
        style={{ width: `${progress}%` }}
      ></div>
      <span className="text-sm absolute top-0 left-0 w-full font-poppins h-full flex items-center justify-center ">
        {progress}%
      </span>
    </div>
  );

  // Function to fetch own photos from API
  const fetchOwnPhotos = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${BASE_URL}/gallery-uploads?type=1&jbo_id=124&wishlist_jbo_id=${user_id}`
      ); // Replace with the actual API URL
      const fetchedPhotos = response.data.map((photo, index) => ({
        id: photo.uploads_id,
        imageUrl: photo.url,
        isLiked: photo.is_liked,
      }));
      setOwnPhotos(fetchedPhotos);
    } catch (err) {
      setError(err.message);
    }
    setIsLoading(false);
  };
  const fetchWishlistData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/gallery-uploads/${user_id}` // Replace with the actual API URL for fetching wishlist data
      );
      console.log("response", response.data);
      const wishListPhotos = response.data.map((photo, index) => ({
        id: photo.uploads_id,
        imageUrl: photo.url,
        isLiked: photo.is_liked,
      }));
      setWishlist(wishListPhotos);

      // setWishlist([...wishlist, photo]);
    } catch (err) {
      console.error("Error fetching wishlist data:", err);
    }
  };
  // useEffect to fetch own photos when the component mounts
  useEffect(() => {
    fetchOwnPhotos();
    fetchWishlistData();
  }, []);

  // useEffect to handle the update after a new image is uploaded
  useEffect(() => {
    if (uploadedImage) {
      fetchOwnPhotos();
    }
  }, [uploadedImage]);

  // Function to send image to the backend
  const sendImageToBackend = async (
    croppedImage,
    userId,
    aspectRatio,
    type,
    purposeFor
  ) => {
    try {
      const formData = new FormData();
      console.log("cropperImage", croppedImage);
      formData.append("uploadedImage", await dataUrlToBlob(croppedImage));
      formData.append("jbo_id", userId);
      formData.append("dimension", aspectRatio);
      formData.append("type", type);
      formData.append("purpose", purposeFor);
      // formData.append("type", 1);

      const response = await axios.post(
        `${BASE_URL}/gallery-uploads`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      fetchOwnPhotos();
      setUploadProgress(0);

      // Handle the response from the server
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Error response data: ", error.response.data);
        console.log("Error response status: ", error.response.status);
        console.log("Error response headers: ", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Error request instance: ", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error message: ", error.message);
      }
      console.error("Error sending image to backend:", error);
    }
  };

  // Convert dataUrl to Blob
  const dataUrlToBlob = async (dataUrl) => {
    const response = await fetch(dataUrl);
    const blob = await response.blob();
    const customTypeBlob = new Blob([blob], { type: "image/png" });
    console.log("Blob", blob);
    return customTypeBlob;
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleImageUpload({ target: { files: acceptedFiles } });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  // Function to add photo to wishlist
  const addToWishlist = async (photo) => {
    // if (!wishlist.find((item) => item.id === photo.id)) {
    // }
    try {
      await axios.post(
        `${BASE_URL}/gallery-wishlist`, // Replace with the actual API URL for adding to wishlist
        { user_id: user_id, gallery_uploads_id: photo.id, is_liked: 1 }
      );
      fetchWishlistData();
      fetchOwnPhotos();
      fetchPublicPhotos();
    } catch (err) {
      console.error("Error adding photo to wishlist:", err);
      // Handle error case accordingly
    }
  };
  const removeFromWishlist = async (photoId) => {
    try {
      await axios.delete(
        `${BASE_URL}/gallery-wishlist/?gallery_uploads_id=${photoId}&user_id=${user_id}` // Replace with the actual API URL for removing from wishlist
      );
      fetchWishlistData();
      fetchOwnPhotos();
      fetchPublicPhotos();
    } catch (err) {
      console.error("Error removing photo from wishlist:", err);
      // Handle error case accordingly
    }
  };
  const toggleWishlist = (photo) => {
    console.log("photo data", photo);
    if (photo.isLiked === 0) {
      addToWishlist(photo);
    } else {
      removeFromWishlist(photo.id);
    }
  };
  useEffect(() => {
    if (uploadedImage) {
      if (cropper) {
        cropper.destroy();
      }

      let aspectRatio;
      switch (selectedAspectRatio) {
        case "1:1":
          aspectRatio = 1;
          break;
        case "16:9":
          aspectRatio = 16 / 9;
          break;
          case "22:9":
            aspectRatio = 22 / 9;
            break;
        case "4:3":
          aspectRatio = 4 / 3; // Add this case
          break;
        case "2:3":
          aspectRatio = 2 / 3; // Add this case
          break;
        default:
          aspectRatio = 1;
      }

      const newCropper = new Cropper(imageElement.current, {
        aspectRatio: aspectRatio,
        viewMode: 1,
      });

      setCropper(newCropper);
    }
  }, [uploadedImage, selectedAspectRatio]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // Shimmer effect component
  const Shimmer = () => (
    <div className="w-full h-32 bg-gradient-to-r animate-shimmer">
      <div className="h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 w-1/4"></div>
    </div>
  );

  const handleCropImage = async () => {
    if (cropper) {
      const croppedImageDataURL = cropper.getCroppedCanvas().toDataURL();
      const userId = "124"; // Replace with the actual static userId
      const type = "1"; // Replace with the actual static userId
      await sendImageToBackend(
        croppedImageDataURL,
        userId,
        selectedAspectRatio,
        type,
        purposeFor
      );

      handleOwnPhotoUpload({
        target: { files: [dataURLToFile(croppedImageDataURL)] },
      });
      setUploadedImage(null);

      cropper.destroy();
    }
  };

  const confirmDelete = async (photoId, uploads_id) => {
    // window.location.reload();
    Swal.fire({
      title: "Are you sure you want to delete this image?",
      // text: "Did u want to delete this session",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${BASE_URL}/gallery-uploads/${uploads_id}`
          );
          // setDeletedPhotoIds([...deletedPhotoIds, photoId]); // Replace with the actual API URL
          // setOwnPhotos(ownPhotos.filter((photo) => photo.id !== photoId)); // Update ownPhotos state
          Swal.fire({
            title: "Deleted!",
            text: "Image has been deleted successfully.",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            timer: 2000, // Auto-close after 2000ms (2 seconds)
            timerProgressBar: true,
          });
          fetchOwnPhotos();
          fetchWishlistData();
        } catch (err) {
          console.error("Error deleting own photo:", err);
          Swal.fire("Error", "There was an error deleting the image.", "error");
        }
      } else Swal.fire("Cancelled", " Image is safe ", "success");
    });
  };

  const dataURLToFile = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
  };

  const tabs = [
    { id: "own", name: "Own photos" },
    { id: "public", name: "Public photos" },
    // { id: "wishlist", name: "Liked Photos" },
  ];

  const handleOwnPhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    const newPhotos = files.map((file) => ({
      id: ownPhotos.length + 1,
      imageUrl: URL.createObjectURL(file),
    }));
    setOwnPhotos([...ownPhotos, ...newPhotos]);
  };

  const renderPhotos = (photos, deletable = false) => {
    console.log("photosData", photos);
    if (isLoading) {
      return (
        <div>
          {Array.from({ length: ownPhotos.length }).map((_, index) => (
            <Shimmer key={index} />
          ))}
        </div>
      );
    }
    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 bg-gray-100 p-6 rounded-md">
        {photos.map((photo) => (
          <div key={photo.id} className="relative photo-card">
            <img
              src={photo.imageUrl}
              alt=""
              onLoad={(e) => e.target.classList.remove("shimmer")}
              className="shimmer w-full h-auto object-cover rounded-lg shadow-md transition duration-200 ease-in-out transform hover:scale-105"
            />

            {deletable && (
              <button
                onClick={() => confirmDelete(photo.id, photo.id)}
                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2"
              >
                <AiOutlineClose className="h-5 w-5" />
              </button>
            )}

            <button
              onClick={() => toggleWishlist(photo)}
              className="absolute top-2 left-2 bg-red-500 text-white rounded-full p-2"
            >
              {photo.isLiked === 1 || photo.isLiked === "1" ? (
                <AiFillHeart className="h-5 w-5" />
              ) : (
                <AiOutlineHeart className="h-5 w-5" />
              )}
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="overflow-auto">
      <div className="flex flex-col md:flex-row md:h-screen">
        <div className=" w-full h-[50%] md:h-screen md:w-[18%] md:fixed p-4 top-0 left-0 bg-gradient-to-br from-blue-500 via-purple-500 to-red-500  z-10">
          <h1 className="font-bold text-xl mb-4 font-poppins text-white">
            Gallery
          </h1>
          <Tab.Group className="flex flex-col gap-3">
            <Tab.List className="space-y-2">
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  className={`${
                    activeTab === tab.id
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-600"
                  } px-4 py-2 rounded-lg mr-2 cursor-pointer font-poppins flex items-center justify-between`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.name}
                  {tab.id === "wishlist" && (
                    <span className="inline-flex items-center justify-center w-5 h-5 bg-red-500 text-white text-xs font-medium rounded-full ml-2">
                      {wishlist.length}
                    </span>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>
        <div className="w-full md:w-3/4 md:ml-[18%] p-4">
          {activeTab === "public" && renderPhotos(publicPhotos)}
          {activeTab === "own" && (
            <div className="bg-gray-100 p-6 rounded-md">
              <div className="font-poppins text-xl font-medium pb-6 ">
                Upload Your Own photos Here
              </div>
              <div className="mb-4 flex gap-5 flex-col md:flex-row items-center">
                <div className="w-full md:w-1/3">
                  <label
                    htmlFor="aspectRatio"
                    className="font-medium text-gray-700"
                  >
                    Select Aspect ratio
                  </label>
                  <select
                    id="aspectRatio"
                    name="aspectRatio"
                    value={selectedAspectRatio}
                    onChange={(e) => setSelectedAspectRatio(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option value="1:1">Square (1:1)</option>
                    <option value="16:9">Banner (16:9)</option>
                    <option value="22:9">large Banner (16:9)</option>
                    <option value="4:3">Monitor Square(4:3)</option>
                    <option value="2:3">Logo Rectangle(2:3)</option>
                  </select>
                </div>
                <div className="w-full md:w-1/3">
                  {uploadedImage && (
                    <div className="mt-4 ">
                      <button
                        onClick={handleCropImage}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 hover:bg-green-600 transition duration-200 ease-in-out"
                      >
                        Crop and Upload
                      </button>
                      <ProgressBar progress={uploadProgress} />
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full md:w-1/3 mb-5">
                <label
                  htmlFor="purposeFor"
                  className="font-medium text-gray-700"
                >
                  Purpose For
                </label>
                <select
                  id="purposeFor"
                  name="purposeFor"
                  value={purposeFor}
                  onChange={(e) => setPurposeFor(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="banner">Banner Post</option>
                  <option value="blog">Blog Post</option>
                  <option value="widget">Widget Post</option>
                  <option value="product">Product Post</option>
                </select>
              </div>
              {uploadedImage ? (
                <div className="mb-4">
                  <img
                    ref={imageElement}
                    src={uploadedImage}
                    alt="Uploaded"
                    style={{ maxHeight: "400px", maxWidth: "100%" }}
                  />
                </div>
              ) : (
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="text-center">Drop the files here...</p>
                  ) : (
                    <p className="text-center">
                      {" "}
                      Drag and drop images here, or click to select files
                    </p>
                  )}
                </div>
              )}
              {renderPhotos(ownPhotos, true)}
            </div>
          )}
          {activeTab === "wishlist" && (
            <div>
              <div className="flex justify-center items-center py-5">
                <span className="text-2xl font-medium font-poppins ">
                  Wishlist
                </span>
              </div>
              {renderPhotos(wishlist)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DefaultGallery;
