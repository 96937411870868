import React from "react";
import logo from "../../../images/logo.webp";
import rtcbanner from "../../../images/rustic_banner.webp";
import rtc1 from "../../../images/rustic1.webp";
import rtc2 from "../../../images/rustic2.webp";
import rtc3 from "../../../images/rustic3.webp";
import rtc4 from "../../../images/rustic4.webp";
import rtc5 from "../../../images/rustic5.webp";
import rtc6 from "../../../images/rustic6.webp";
import rtc7 from "../../../images/rustic7.webp";
import rtc8 from "../../../images/rustic8.webp";
import rtc9 from "../../../images/rustic9.webp";
import rtc10 from "../../../images/rustic10.webp";
import rtc11 from "../../../images/rustic11.webp";
import rtc12 from "../../../images/rustic12.webp";
import rtc13 from "../../../images/rustic13.webp";
import rtc14 from "../../../images/rustic14.webp";
import rtc15 from "../../../images/rustic15.webp";
import rtc16 from "../../../images/rustic16.webp";
import rtc17 from "../../../images/rustic17.webp";
import rtc18 from "../../../images/rustic18.webp";
import { FaSearch } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaBars } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";

const Rustic = () => {
  return (
    <>
      <div className="bg-rustic_secondary py-2">
        <div className="container mx-auto grid grid-cols-2">
          <div className="text-lg font-semibold">Today's Metal Rate</div>
          <div className="flex justify-end items-center text-lg font-semibold">
            Plan Your Investment
          </div>
        </div>
      </div>

      <div className="container mx-auto flex items-center justify-between py-4">
        <div className="flex items-center">
          <img className="w-64 h-24" src={logo} alt="img" />
        </div>

        <div className="flex-1 px-4">
          <div className="relative flex items-center justify-center w-full">
            <input
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded-lg p-4 pr-12 w-2/3"
            />
            <FaSearch className="absolute right-40 mr-4 text-gray-400 bg-rustic_primary p-2 w-14 h-full rounded text-white" />
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex flex-col items-center mr-4">
            <IoBag className="text-[25px]" />
            <span className="text-md font-semibold">Cart</span>
          </div>

          <div className="flex flex-col items-center mr-4">
            <FaRegHeart className="text-[25px]" />
            <span className="text-md font-semibold">Wishlist</span>
          </div>

          <div className="flex flex-col items-center">
            <FaUser className="text-[25px]" />
            <span className="text-md font-semibold">Login</span>
          </div>
        </div>
      </div>

      <div className="bg-rustic_secondary">
        <div class="container mx-auto text-md flex items-center space-x-8">
          <div class="flex items-center">
            <h1 class="bg-rustic_primary text-white px-4 py-2 rounded flex items-center">
              <FaBars class="mr-2" />
              Categories
            </h1>
          </div>

          <ul class="list-none p-0 m-0 flex space-x-10 font-semibold">
            <li>Home</li>
            <li>About Us</li>
            <li>Contact Us</li>
            <li>Gold Plan</li>
          </ul>
        </div>
      </div>

      {/* End of Header Section */}

      <div className="">
        <img className="image" src={rtcbanner} alt="banner-image"></img>
      </div>

      {/* End of Banner Content */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Revel in the Beauty of Classic Jewellery
      </div>

      <div className=" container mx-auto grid grid-cols-2 gap-4">
        <img className="image" src={rtc1} alt="img"></img>
        <div className="grid grid-cols-2 grid-rows-2 gap-2">
          <img className="image" src={rtc2} alt="img"></img>
          <img className="image" src={rtc3} alt="img"></img>
          <img className="image" src={rtc4} alt="img"></img>
          <img className="image" src={rtc5} alt="img"></img>
        </div>
      </div>

      {/* End of section1 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        In sculpture, the hands speak what the heart feels
      </div>

      <div className=" container mx-auto flex justify-center">
        <img className="image" src={rtc6} alt="img"></img>
      </div>

      {/* End of section2 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        The artist sculpts the soul of the world
      </div>

      <div className="container mx-auto grid grid-cols-3 gap-4">
        <div className="flex justify-center">
          <img className="image" src={rtc7} alt="img"></img>
        </div>
        <div className="flex justify-center">
          <img className="image" src={rtc8} alt="img"></img>
        </div>
        <div className="flex justify-center">
          <img className="image" src={rtc9} alt="img"></img>
        </div>
      </div>

      {/* End of section3 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Carving beauty from the chaos of the formless
      </div>

      <div className=" container mx-auto grid grid-cols-5 gap-4">
        <div className="justify-self-center">
          <img className="image" src={rtc10} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">
            Brass Elephant Urli
          </p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={rtc11} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Buddha</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={rtc12} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Elephant</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={rtc13} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Ganesha</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={rtc14} alt="img"></img>
          <p className=" flex justify-center font-semibold  my-7">
            Wooden Parrot
          </p>
        </div>
      </div>

      {/* End of section4 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        In sculpture, the artist's touch gives life to inert matter
      </div>

      <div className=" container mx-auto grid grid-cols-3 gap-4">
        <div className="flex justify-center">
          <img className="image" src={rtc15} alt="img"></img>
        </div>
        <div className="flex justify-center">
          <img className="image" src={rtc16} alt="img"></img>
        </div>
        <div className="flex justify-center">
          <img className="image" src={rtc17} alt="img"></img>
        </div>
      </div>

      {/* End of section5 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Transforming thoughts into tactile artistry
      </div>

      <div className="container mx-auto">
        <img className="image" src={rtc18} alt="img"></img>
      </div>

      {/* End of section6 */}

      <div className="bg-rustic_primary w-full mt-5">
        <div className="container mx-auto py-4">
          <div className="grid grid-cols-2 gap-8">
            <article className="text-white flex items-center space-x-4">
              <div className="flex items-center justify-center border border-slate-50 rounded-full bg-slate-50 w-10 h-10">
                <FaBell className="text-black" />
              </div>
              <div>
                <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                <p>
                  Get all the latest information on Events, Sales and Offers.
                </p>
              </div>
            </article>

            <form className="flex items-center">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full px-3 py-2 border rounded-lg mr-2"
              ></input>
              <button
                type="submit"
                className="bg-rustic_secondary text-black font-semibold px-4 py-2 rounded-lg"
              >
                <span>SUBSCRIBE</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-rustic_secondary grid grid-cols-3 gap-4 ">
        <div className="flex justify-center">
          <img className="image w-64 h-24  " src={logo} alt="img"></img>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">GET US HELP YOU</h2>
          <ul className="list-none p-0 m-0">
            <li className="py-2 ">Contact Us</li>
            <li className="py-2">Terms and Condition</li>
            <li className="py-2">FAQ</li>
          </ul>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">OUR POLICIES</h2>
          <ul className="list-none p-0 m-0 ">
            <li className="py-2 ">Privacy Policy</li>
            <li className="py-2">Shipping Policy</li>
            <li className="py-2 ">Cancellation Policy</li>
            <li className="py-2 ">Exchange Policy</li>
          </ul>
        </div>
      </div>

      <div className=" bg-rustic_primary ">
        <div className="container mx-auto text-white">
          ⓒ All rights reserved
        </div>
      </div>

      {/* End of footer section */}
    </>
  );
};

export default Rustic;
