import React from "react";
import Tooltip from "@mui/material/Tooltip";

const WebsiteBuilderSteps = ({ callback, themeSelected, themeDetails }) => {
  const steps = [
    {
      stepNumber: 1,
      title: "Register Your Domain",
      titleTwo: "Your Domain",
    },
    {
      stepNumber: 2,
      title: "Select Your Theme",
      titleTwo: "Selected Theme",
    },
    {
      stepNumber: 3,
      title: "Home Page Customization",
    },
    {
      stepNumber: 4,
      title: "Website Customization",
    },
    {
      stepNumber: 5,
      title: "Make Website Live",
    },
  ];

  const onProceed = (stepNumber) => {
    callback(stepNumber);
  };

  return (
    <div className="w-full min-h-screen bg-gray-100 flex flex-col items-center px-4 sm:px-10">
      {steps.map((step, index) => (
        <div
          key={step.stepNumber}
          className={`card w-full transform transition-all duration-500 ease-in-out p-4 m-4 rounded-lg shadow-lg
          `}
        >
          <div className="flex flex-col sm:flex-row items-center justify-between w-full">
            <div className="flex-1 text-xl font-bold text-blue-600 mb-2 sm:mb-0">
              Step {step.stepNumber}
            </div>
            <div className="flex-1 font-semibold text-lg mb-2 sm:mb-0 font-poppins bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-600 whitespace-nowrap">
              {step.title}
            </div>
            <div className="flex-1"></div>
            <Tooltip
              title={
                step.stepNumber === 5 && !themeSelected
                  ? "Please select a theme to make it live"
                  : ""
              }
              placement="top"
              arrow
            >
              <button
                onClick={() => onProceed(step.stepNumber)}
                className={`proceed-btn flex-1 py-2 px-4  text-white rounded-lg shadow-md transform transition-all duration-300 ease-in-out hover:bg-blue-600 hover:scale-105 
              ${
                themeDetails && (step.stepNumber == 1 || step.stepNumber == 2)
                  ? "bg-green-500"
                  : "bg-blue-500"
              }
              ${
                step.stepNumber === 5 && !themeSelected
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }
              `}
                disabled={step.stepNumber === 5 && !themeSelected}
              >
                {themeDetails && (step.stepNumber == 1 || step.stepNumber == 2)
                  ? "Done"
                  : "Proceed"}
              </button>
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WebsiteBuilderSteps;
