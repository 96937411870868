import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { MdOutlineSupportAgent, MdEmail, MdCall } from "react-icons/md";

const style = {
  position: "absolute",
  top: "85%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Button onClick={handleOpen}>
          {" "}
          <span className="mr-2">
            <MdOutlineSupportAgent size={30} />{" "}
          </span>{" "}
          For Support
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="flex items-center">
                <MdEmail className="mr-2" />
                <a
                  href="mailto:customercare@jpencil.com"
                  className="text-lg font-semibold hover:text-blue-600"
                >
                  customercare@jpencil.com
                </a>
              </div>

              <div className="flex items-center">
                <MdCall className="mr-2" />
                <a
                  href="tel:9952989657"
                  className="text-lg font-semibold hover:text-blue-600"
                >
                  9952989657
                </a>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
