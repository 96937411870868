import React from 'react';

import 'suneditor/dist/css/suneditor.min.css';
const PreviewPage = ({ content }) => {
    return (
        <div className="p-8 w-full">
            <div className="sun-editor-editable" dangerouslySetInnerHTML={{ __html: content }} />

        </div>
    );
}

export default PreviewPage;
