
import { SketchPicker } from "react-color"; // Import the SketchPicker
import Gallery from "../../Gallery";
import Modal from "react-modal";
import React from "react";
const Modals = ({
  tempTextColor,
  HeaderColorModalOpen,
  TextColorModalOpen,
  tempBgColor,
  tempHeadColor,
  isModalOpen,
  setIsModalOpen,
  isGalleryModalOpen,
  setIsGalleryModalOpen,
  handleTempBgColorChange,
  handleTempHeaderColorChange,
  handleTempTextColorChange,
  handleColorChange,
  handleHeaderColorChange,
  handleTextColorChange,
  handleImageSelect,
}) => {
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Color Picker Modal"
        className="flex items-center justify-center h-screen outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-60" // Slightly darker overlay for better focus on modal
      >
        <div className="bg-white rounded-lg p-6 shadow-xl w-96">
          <h2 className="text-center font-poppins text-gray-600 text-lg mb-4">
            Select Color
          </h2>
          <div className="flex justify-center">
            <SketchPicker
              color={tempBgColor}
              onChangeComplete={handleTempBgColorChange}
            />
          </div>

          <button
            onClick={() => {
              handleColorChange({ hex: tempBgColor });
              setIsModalOpen(false);
            }}
            className="bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-600 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto" // Gradient background and transition effect
          >
            Save
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={HeaderColorModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Color Picker Modal"
        className="flex items-center justify-center h-screen outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-60"
      >
        <div className="bg-white rounded-lg p-6 shadow-xl w-96">
          <h2 className="text-center font-poppins text-gray-600 text-lg mb-4">
            Select Color
          </h2>
          <div className="flex justify-center">
            <SketchPicker
              color={tempHeadColor}
              onChangeComplete={handleTempHeaderColorChange}
            />
          </div>
          <button
            onClick={() => {
              handleHeaderColorChange({ hex: tempHeadColor });
              setIsModalOpen(false);
            }}
            className="bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-600 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto" // Gradient background and transition effect
          >
            Save
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={TextColorModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Color Picker Modal"
        className="flex items-center justify-center h-screen outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-60"
      >
        <div className="bg-white rounded-lg p-6 shadow-xl w-96">
          <h2 className="text-center font-poppins text-gray-600 text-lg mb-4">
            Select Color
          </h2>
          <div className="flex justify-center">
            <SketchPicker
              color={tempTextColor}
              onChangeComplete={handleTempTextColorChange}
            />
          </div>
          <button
            onClick={() => {
              handleTextColorChange({ hex: tempTextColor });
              setIsModalOpen(false);
            }}
            className="bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-600 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto" // Gradient background and transition effect
          >
            Save
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isGalleryModalOpen}
        onRequestClose={() => setIsGalleryModalOpen(false)}
        contentLabel="Icon Picker Modal"
        // className="flex items-center justify-center h-screen outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <Gallery onImageSelect={handleImageSelect} dimension="1:1" closeModal={() => setIsGalleryModalOpen(false)}/>
      </Modal>
    </>
  );
};

export default Modals;
