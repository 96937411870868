// NestedSelect.js

import React, { useState,useEffect } from 'react';
import axios from "axios";
import  useTokenFromURL  from '../../../Context/useTokenFromURL';
import { FaCaretDown } from 'react-icons/fa';
import useAxios from '../../../Axios/useAxios';
import { BASE_URL } from '../../../config';
const REACT_APP_BASE_URL = BASE_URL;
const transformData = (apiData) => {
  return apiData.reduce((acc, item) => {
      if (item.name.trim() !== "") { 
          acc.push({
            id: Number(item.menuId),  // Convert menuId to number // Assuming that the menu items have an 'id' field
              label: item.name,
              value: item.url,
              children: item.child ? transformData(item.child) : []
          });
      }
      return acc;
  }, []);
};




const Option = ({ item, level, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasChildren = item.children && item.children.length > 0;

  return (
    <div>
      <div
        className={`cursor-pointer px-${level * 2} py-2 hover:bg-blue-100`}
        onClick={() => (hasChildren ? setIsOpen(!isOpen) : onSelect(item.label, item.value, item.id))}
      >
        {item.label}
        {hasChildren && <span className="float-right">{isOpen ? '-' : '+'}</span>}
      </div>
      {isOpen && hasChildren && item.children.map((child, index) => (
        <Option key={index} item={child} level={level + 1} onSelect={onSelect} />
      ))}
    </div>
  );
};



const NestedSelect = ({setSelectedValue,selectedValue,IsSavedInputs}) => {
  const [data, setData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(selectedValue ? selectedValue : []);
  const [open, setOpen] = useState(false);

  useTokenFromURL();
  useAxios();

  useEffect(() => {
    axios.get( `${REACT_APP_BASE_URL}/menu-201`)
      .then(response => {
        const transformed = transformData(response.data);
        setData(transformed);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);


  
  const Chip = ({ label, onRemove }) => (
    <span className="inline-flex items-center bg-blue-100 rounded px-2 py-1 mr-1 mb-1">
      {label}
      <button onClick={onRemove} className="ml-1 text-sm">
        ✕
      </button>
    </span>
  );
  const onSelect = (label, value, id) => {
    let updatedOptions = [...selectedOptions];
  
    // Check if a menu with the same ID exists in the current selection.
    const existingOptionIndex = updatedOptions.findIndex(o => o.menuId === id);
  
    if (existingOptionIndex !== -1) {
      // If it exists, remove it from the arrays.
      updatedOptions.splice(existingOptionIndex, 1);
    } else {
      // If it doesn't exist, add the new option to the arrays.
      updatedOptions.push({ menuId: id, menu_filter: label });
    }
  
    setSelectedOptions(updatedOptions);
    setSelectedValue(updatedOptions); // Update the parent immediately with the current selection.
  };
  
  const onRemove = (id) => {
    IsSavedInputs();
    const updatedOptions = selectedOptions.filter(o => o.menuId !== id);
    setSelectedOptions(updatedOptions);
    setSelectedValue(updatedOptions);
  };
  
  return (
    <div className="relative inline-block w-full">
    <div className="mb-2 mt-3">
    {selectedOptions?.map((option, index) => (
        <Chip key={index} label={option.menu_filter} onRemove={() => onRemove(option.menuId)} />
      ))}
      </div>

      <button
        className="w-full py-2 px-3 bg-gray-100 rounded-md text-left relative flex items-center justify-between"
        onClick={() => setOpen(!open)}
      >
        <span> Select options</span>
        <FaCaretDown />
      </button>
      {open && (
        <div className="mt-1 w-full max-h-60 border rounded-md shadow-lg z-10 overflow-y-auto" style={{ maxHeight: '250px' }}>
          {data.map((item, index) => (
            <Option key={index} item={item} level={1} onSelect={onSelect} />
          ))}
        </div>
      )}
    </div>
  );
};

export default NestedSelect;

