import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactSwitch from "react-switch";
import useAxios from "../../Axios/useAxios";
import axios from "axios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import Swal from "sweetalert2";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { BASE_URL } from '../../config';
// import { useToken } from '../../Context/TokenContext';
const REACT_APP_BASE_URL = BASE_URL;
/**
 * ProductCustomization Component
 *
 * This component allows users to customize various aspects of a product.
 * It fetches the current product settings and updates from an API and allows
 * toggling different product attributes for customization.
 *
 * Props:
 * - activeAccordion: String that indicates the currently active accordion.
 * - onToggle: Function callback to toggle accordions.
 * - IsSavedInputs: Function callback to verify if inputs are saved.
 */
const ProductCustomization = ({ activeAccordion, onToggle, IsSavedInputs }) => {
  // Custom hooks to fetch the authentication token and setup Axios.
  useTokenFromURL();
  useAxios();

  // State hooks for various product customization attributes and functionalities.
  const [selectedGallery, setSelectedGallery] = useState("default");
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [defaultDescription, setDefaultDescription] = useState("<p><br></p>");
  // const {jboId} = useToken();
  const [toggleStates, setToggleStates] = useState({
    product_name: false,
    product_description: false,
    sku: false,
    category: false,
    stock_availability: false,
    dispatch_days: false,
    nominal_attributes: false,
    qualitative_attributes: false,
    quantitative_attributes: false,
    price_breakdown: false,
    reviews: false,
    sharing_options: false,
    multiple_quantities: false,
  });
  /**
   * Toggle product attributes for customization.
   *
   * @param {String} key - The attribute key to toggle.
   */
  const handleToggleChange = (key) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    IsSavedInputs();
  };
  const [disclaimer, setDisclaimer] = useState("");
  const prevDescriptionRef = useRef(disclaimer);
  const [skuAllocation, setSkuAllocation] = useState("");
  /**
   * Fetch product details from the API.
   *
   * This function fetches the product's current settings and updates for customization.
   */
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/product-detail-page`
      );

      if (response.data && response.data.length > 0) {
        const data = response.data[0];
        updateStatesWithData(data);
      }
    } catch (error) {
      console.error("There was an error fetching data:", error.response);
      // Handle the error accordingly
    }
  };

  /**
   * Update component state with fetched data.
   *
   * @param {Object} data - The fetched data from the API.
   */
  const updateStatesWithData = (data) => {
    setSelectedGallery(data.gallery_side);
    setToggleStates({
      product_name: !!data.product_name,
      product_description: !!data.product_description,
      sku: !!data.sku,
      category: !!data.category,
      stock_availability: !!data.stock_availability,
      dispatch_days: !!data.dispatch_days,
      nominal_attributes: !!data.nominal_attributes,
      qualitative_attributes: !!data.qualitative_attributes,
      quantitative_attributes: !!data.quant_attributes,
      price_breakdown: !!data.price_breakdown,
      reviews: !!data.reviews,
      sharing_options: !!data.sharing_options,
      multiple_quantities: !!data.multiple_quantities,
    });
    const submitValidate = data.general_disclaimer !== "" ? true : false;
    setDataSubmitted(submitValidate);
    setDisclaimer(data.general_disclaimer);
    prevDescriptionRef.current = data.general_disclaimer;
    setSkuAllocation(data.selected_value);
  };

  // Effect hook to fetch data and update states on component mount or when dataSubmitted changes.
  useEffect(() => {
    fetchData();
  }, [dataSubmitted]);

  /**
   * Submit the updated product details to the API.
   */

  const handleSubmit = async () => {
    const payload = {
      gallery_side: selectedGallery, // This needs to be based on your state value
      product_name: toggleStates.product_name ? 1 : 0,
      product_description: toggleStates.product_description ? 1 : 0,
      sku: toggleStates.sku ? 1 : 0,
      category: toggleStates.category ? 1 : 0,
      stock_availability: toggleStates.stock_availability ? 1 : 0,
      dispatch_days: toggleStates.dispatch_days ? 1 : 0,
      nominal_attributes: toggleStates.nominal_attributes ? 1 : 0,
      qualitative_attributes: toggleStates.qualitative_attributes ? 1 : 0,
      quant_attributes: toggleStates.quantitative_attributes ? 1 : 0,
      price_breakdown: toggleStates.price_breakdown ? 1 : 0,
      reviews: toggleStates.reviews ? 1 : 0,
      sharing_options: toggleStates.sharing_options ? 1 : 0,
      multiple_quantities: toggleStates.multiple_quantities ? 1 : 0,
      general_disclaimer: disclaimer,
      selected_value: skuAllocation,
      jbo_id: 217,
    };

    try {
      const response = await axios.patch(
        `${REACT_APP_BASE_URL}/product-detail-page`,
        payload
      );
      if (response.data) {
        // Handle successful response

        if (dataSubmitted) {
          Swal.fire({
            title: "Success!",
            text: "Datas Updated successfully.",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: "Success!",
            text: "Datas saved successfully.",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        }
        fetchData();
        // You can set a success state or use any UI feedback mechanism to inform the user.
      }
    } catch (error) {
      // Handle the error
      console.error("There was an error:", error.response);
      // You can set an error state or use any UI feedback mechanism to inform the user of the error.
    }
  };

  /**
   * Validate the form data before submitting.
   *
   * Checks for required fields and sets error states if validation fails.
   */
  const validate = () => {
    let tempErrors = {};
    if (!disclaimer.trim() || disclaimer === defaultDescription) {
      tempErrors.disclaimer = "General disclaimer is required";
    }
    // New validation for skuAllocation
    // if (!skuAllocation.trim()) {
    //   tempErrors.skuAllocation = "SKU Allocation is required";
    // }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  // Effect hook to validate the form whenever disclaimer or skuAllocation changes.
  useEffect(() => {
    validate();
  }, [disclaimer, skuAllocation]);

  /**
   * Handle changes in the active accordion.
   *
   * This function toggles between opening and closing the ProductDetailsAccordian.
   */
  const handleAccordionChange = () => {
    if (activeAccordion === "ProductDetailsAccordian") {
      onToggle && onToggle(null); // close the current accordion if it's 'AuthAccordian'.
    } else {
      onToggle && onToggle("ProductDetailsAccordian"); // open the 'AuthAccordian' if another accordion is currently active.
    }
  };

  return (
    <div>
      <Accordion
        className="w-full max-w-6xl "
        expanded={activeAccordion === "ProductDetailsAccordian"}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontFamily: "poppins" }}>
            Product Details Page
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          <div className="flex items-center space-x-6 mb-4">
            <Typography>Gallery Side:</Typography>
            <div className="flex space-x-4 font-poppins">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="gallery"
                  value="left"
                  onChange={() => {
                    setSelectedGallery("left");
                    IsSavedInputs();
                  }}
                  checked={selectedGallery === "left"}
                />
                <span>Left Gallery</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="gallery"
                  value="default"
                  onChange={() => {
                    setSelectedGallery("default");
                    IsSavedInputs();
                  }}
                  checked={selectedGallery === "default"}
                />
                <span>Default Gallery</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="gallery"
                  value="right"
                  onChange={() => {
                    setSelectedGallery("right");
                    IsSavedInputs();
                  }}
                  checked={selectedGallery === "right"}
                />
                <span>Right Gallery</span>
              </label>
            </div>
          </div>
          {selectedGallery === "left" && (
            <div className="flex space-x-4 justify-center p-5 ">
              <div className="flex flex-col space-y-4 w-[20%]">
                <div className="h-28 w-[80%]  ml-[2rem] bg-gray-200 rounded-md shadow-md"></div>
                <div className="h-28 w-[80%] ml-[2rem] bg-gray-300 rounded-md shadow-md"></div>
              </div>
              <div className="h-60 w-[30%] bg-gray-500 rounded-lg shadow-lg flex items-center justify-center text-white"></div>
            </div>
          )}
          {selectedGallery === "default" && (
            <div
              className="flex flex-col space-y-4 justify-center p-5"
              style={{ height: "400px" }}
            >
              <div className="h-48 w-[30%] bg-gray-500 rounded-lg shadow-lg flex items-center justify-center text-white self-center mb-2"></div>
              <div className="flex space-x-4 justify-center">
                <div className="h-28 w-[13%] bg-gray-200 rounded-md shadow-md"></div>
                <div className="h-28 w-[13%] bg-gray-300 rounded-md shadow-md"></div>
              </div>
            </div>
          )}
          {selectedGallery === "right" && (
            <div className="flex space-x-4 justify-center p-5 ">
              <div className="h-60 w-[30%] bg-gray-500 rounded-lg shadow-lg flex items-center justify-center text-white"></div>
              <div className="flex flex-col space-y-4 w-[20%]">
                <div className="h-28 w-[80%] bg-gray-200 rounded-md shadow-md"></div>
                <div className="h-28 w-[80%] bg-gray-300 rounded-md shadow-md"></div>
              </div>
            </div>
          )}
        </AccordionDetails>
        {/* Toggles for Details to Display */}
        <div className="w-full mt-8 bg-white p-6 rounded shadow">
          <Typography className="text-xl font-semibold mb-6">
            Product Details to Display:
          </Typography>
          {[
            ["product_name", "product_description", "sku"],
            ["category", "stock_availability", "dispatch_days"],
            [
              // "nominal_attributes",
              "qualitative_attributes",
              "quantitative_attributes",
            ],
            ["price_breakdown", 
            // "reviews",
             "sharing_options"],
            // ["multiple_quantities"],
          ].map((line, index) => (
            <div key={index} className="grid grid-cols-3 mt-4 gap-6 mb-4">
              {line.map((item) => (
                <div key={item} className="flex gap-3 items-center">
                  <ReactSwitch
                    checked={toggleStates[item]}
                    onChange={() => handleToggleChange(item)}
                  />
                  <Typography className="text-gray-600">
                    {item.replace(/_/g, " ").charAt(0).toUpperCase() +
                      item.replace(/_/g, " ").slice(1)}
                  </Typography>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Discliamer Text area */}
        <div className="w-full mt-3 bg-white p-6 rounded shadow ">
          <label
            className="block text-lg font-poppins mb-3"
            htmlFor="general-disclaimer"
          >
            General Disclaimer:
          </label>

          <SunEditor
            setDefaultStyle="font-family: poppins; font-size: 15px;"
            style={{ width: "100%" }}
            setOptions={{
              height: 400,
              buttonList: [
                [
                  "font",
                  "fontSize",
                  "subscript",
                  "superscript",
                  "bold",
                  "italic",
                  "underline",
                ],
                ["fontColor", "hiliteColor", "outdent", "indent"],
                ["codeView", "undo", "redo", "align", "list", "table"],
                ["fullScreen", "preview"],
              ],
              // imageGalleryUrl:`${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
              font: [
                "Arial",
                "Calibri",
                "Comic Sans",
                "Courier",
                "Poppins",
                "Times New Roman",
              ],
            }}
            setContents={disclaimer}
            onChange={(content) => {
              // Compare the current content with the previous content
              if (prevDescriptionRef.current !== content) {
                IsSavedInputs();
              }
              prevDescriptionRef.current = content; // Update the ref with the new content

              setDisclaimer(content);
              // IsSavedInputs();
            }} // This is how you handle changes
          />
          <div className="mt-3">
            {errors.disclaimer && (
              <span className=" text-red-700 w-1/3 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                {errors.disclaimer}
              </span>
            )}
          </div>
        </div>
        <div className="w-full  p-6 bg-white rounded shadow">
          {/* <label className="block text-lg font-poppins mb-3">
            When selecting the quantity of purchase, what is the order of SKU
            Allocation to the cart?
          </label>

          <div className="space-y-4 font-poppins">
            {[
              { id: "fifo", name: "FIFO by SKU" },
              { id: "lowest", name: "Lowest Weight First" },
              { id: "highest", name: "Highest Weight First" },
              {
                id: "manual",
                name: "Selection table for available SKUs for user to manually select",
              },
            ].map((option) => (
              <label key={option.id} className="block">
                <input
                  type="radio"
                  name="skuAllocation"
                  value={option.id}
                  checked={skuAllocation === option.id}
                  onChange={() => {
                    setSkuAllocation(option.id);
                    IsSavedInputs();
                  }}
                  className="mr-3 text-blue-600"
                />
                {option.name}
              </label>
            ))}
          </div>
          <div className="mt-3">
            {errors.skuAllocation && (
              <span className=" text-red-700 w-1/3 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                {errors.skuAllocation}
              </span>
            )}
          </div> */}
          <button
            onClick={handleSubmit}
            className={`bg-gradient-to-r from-indigo-500 to-blue-600 mt-4 hover:from-indigo-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
              Object.keys(errors).length > 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={Object.keys(errors).length > 0}
          >
           
            {Object.keys(errors).length > 0
              ? "Fill the required fields"
              : dataSubmitted
              ? "Update"
              : "Save"}
          </button>
        </div>
      </Accordion>
    </div>
  );
};

export default ProductCustomization;
