// Context/useTokenFromURL.js
import { useEffect } from 'react';
import { useToken } from './TokenContext';  // Ensure the path is correct

function useTokenFromURL() {
  const { setToken } = useToken();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlParams.get('token');
    if (tokenFromURL) {
      setToken(tokenFromURL);
    }
  }, [setToken]);
}

export default useTokenFromURL;
