import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import SelectBox from "./MultiSelectWithChips";
import useAxios from "../../Axios/useAxios";
import axios from "axios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import ReactSwitch from "react-switch";
import { BASE_URL } from '../../config';
// Base URL from global config for API calls
const REACT_APP_BASE_URL =BASE_URL;
/**
 * FooterCustomization Component
 * A component that provides customization options for the website footer.
 *
 * @param {Object} props
 * @param {function} props.activeAccordion - Determines the currently active accordion.
 * @param {function} props.onToggle - Toggles an accordion.
 * @param {function} props.IsSavedInputs - Indicates if inputs are saved.
 */
const FooterCustomization = ({ activeAccordion, onToggle, IsSavedInputs }) => {
  // Hooks to handle token from URL and setup Axios
  useTokenFromURL();
  useAxios();

  // State declarations
  const [socialMedias, setSocialMedias] = useState([]); // Stores available social media data.
  const [footerContent, setFooterContent] = useState(""); // Content of the footer.
  const [copyRightContent, setCopyRightContent] = useState(""); // Copyright content.

  // Toggle states for each social media.
  const [toggleStates, setToggleStates] = useState(
    socialMedias.reduce((acc, media) => {
      acc[media.key] = true; // This should set every entry to true.
      return acc;
    }, {})
  );
  const [cards, setCards] = useState([]); // State to hold the cards fetched from the API
  const [cardToggles, setCardToggles] = useState({}); // State to hold the toggle states for each card

  const [footers, setFooters] = useState([{ title: "", menus: [] }]); // State set for footer Data.
  const [footerMenus, setFooterMenus] = useState([]); // Menu items for the footer.
  const [selectedMenus, setSelectedMenus] = useState([[]]); //  Selected Menu items for the footer.
  const [isOpen, setIsOpen] = useState({}); // Change from boolean to object
  const [dataSubmitted, setDataSubmitted] = useState(false); // track for form data submitted or not
  const [errors, setErrors] = useState({}); // Holds error messages for form validation

  /**
   * Fetch available social media details from the API.
   */
  const fetchSocialMediaDetails = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/social-media-master`
      );
      const data = response.data.map((item) => ({
        key: item.social_media_master_id,
        name: item.social_media_name,
        image: item.social_media_image,
        url: "", // default URL
      }));
      setSocialMedias(data);

      // Update toggleStates right after updating socialMedias
      const initialToggleStates = data.reduce((acc, media) => {
        acc[media.key] = true; // Set every entry to true.
        return acc;
      }, {});
      setToggleStates(initialToggleStates);
    } catch (error) {
      console.error("Error fetching social media details:", error);
    }
  };

  /**
   * Fetch card details from the API.
   */
  const fetchCardDetails = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/pay-card-master`);
      setCards(response.data);

      const initialToggleState = {};
      response.data.forEach((card) => {
        initialToggleState[card.pay_card_master_id] = false; // Set the initial state for each card's toggle as false
      });
      setCardToggles(initialToggleState);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };
  /**
   * Fetch footer menu details from the API.
   */
  const fetchFooterMenus = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/footer/footer_menu`
      );
      const data = response.data;
      const menuTitles = [];

      // Check if about_us_page exists and has a title before pushing
      if (data.about_us_page && data.about_us_page.title) {
        menuTitles.push(data.about_us_page.title);
      }

      // Check if contact_us_page exists and has a title before pushing
      if (data.contact_us_page && data.contact_us_page.title) {
        menuTitles.push(data.contact_us_page.title);
      }

      // Check if custom_page exists and is an array before processing
      if (data.custom_page && Array.isArray(data.custom_page)) {
        data.custom_page.forEach((page) => {
          if (page.custom_page_title) {
            menuTitles.push(page.custom_page_title);
          }
        });
      }

      setFooterMenus(menuTitles);
    } catch (error) {
      console.error("Error fetching footer menu:", error);
    }
  };

  // Using an effect hook to trigger the fetch when the component mounts
  useEffect(() => {
    fetchFooterMenus();
    fetchSocialMediaDetails();
    fetchCardDetails();
  }, []);

  /**
   * Toggles the dropdown for menu items.
   *
   * @param {number} footerIndex - Index of the footer.
   */

  const toggleDropdown = (footerIndex) => {
    setIsOpen((prev) => ({
      ...prev,
      [footerIndex]: !prev[footerIndex],
    }));
  };

  /**
   * Toggles the selection of a menu item.
   *
   * @param {string} menu - Menu item to toggle.
   * @param {number} footerIndex - Index of the footer.
   */
  const toggleMenuSelection = (menu, footerIndex) => {
    setSelectedMenus((prevMenus) => {
      toggleDropdown();
      // Clone the outer array to avoid direct mutations
      const updatedMenus = [...prevMenus];

      // Check if the menu is already selected for the footer at footerIndex
      if (updatedMenus[footerIndex].includes(menu)) {
        updatedMenus[footerIndex] = updatedMenus[footerIndex].filter(
          (m) => m !== menu
        );
      } else {
        // if (updatedMenus[footerIndex].length < 4) 
        updatedMenus[footerIndex] = [...updatedMenus[footerIndex], menu];
      }
      IsSavedInputs();
      return updatedMenus;
    });
  };
  // Utility function to remove a specific menu item from a footer menu.
  const removeChip = (menu, footerIndex) => {
    // Updating the selected menus by removing the specified menu.
    setSelectedMenus((prevMenus) => {
      const updatedMenus = [...prevMenus];
      updatedMenus[footerIndex] = updatedMenus[footerIndex].filter(
        (m) => m !== menu
      );
      return updatedMenus;
    });
    // Update the save state of the inputs.
    IsSavedInputs();
  };

  // Function to add a new footer section.
  const addFooter = () => {
    if (footers.length < 4) {
      // Add a new empty footer and its corresponding empty menu list.
      setFooters((prevFooters) => [...prevFooters, { title: "", menus: [] }]);
      setSelectedMenus((prevMenus) => [...prevMenus, []]); // Add empty menus for the new footer
    } else {
      alert("You can add a maximum of 4 footers.");
    }
    // Update the save state of the inputs.
    IsSavedInputs();
  };

  // Function to delete a footer section based on its index.
  const deleteFooter = (indexToDelete) => {
    // Remove the specified footer and its corresponding menu list.
    setFooters((prevFooters) =>
      prevFooters.filter((_, index) => index !== indexToDelete)
    );
    setSelectedMenus((prevMenus) =>
      prevMenus.filter((_, index) => index !== indexToDelete)
    );
    // Update the save state of the inputs.
    IsSavedInputs();
  };

 

  // Fetch footer details including social media links, cards, and footer menus.
  const fetchFooterDetails = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/footer`);
      const data = response.data;

      if (!data || !Array.isArray(data) || data.length === 0) {
        console.warn("No footer details found in the response.");
        return;
      }
      if (data && data.footer_id !== null) {
        setDataSubmitted(true);
      }

      const footerData = data[0];

      // Extract and Set footer description and copyright content
      setFooterContent(footerData.footer_description);
      setCopyRightContent(footerData.copyright_content);

      // Process and set social media data
      const processedSocialMedias = footerData.social_media.map((media) => ({
        url: media.social_media_url,
        key: media.social_media_master_id,
        name: media.social_media_master.social_media_name,
        image: media.social_media_master.social_media_image,
      }));
      setSocialMedias(processedSocialMedias);

      // Handle toggles for social media
      const socialMediaToggleStates = {};
      footerData.social_media.forEach((media) => {
        socialMediaToggleStates[media.social_media_master_id] =
          media.is_enabled === 1;
      });
      setToggleStates(socialMediaToggleStates);

      // Extract and set footer menus
      const allFooters = footerData.footer_menu.map((menu) => {
        let menuTitles = [];

        // Check existence and add AboutUsPage title
        if (menu.AboutUsPage && menu.AboutUsPage.title) {
          menuTitles.push(menu.AboutUsPage.title);
        }

        // Check existence and add ContactUsPage title
        if (menu.ContactUsPage && menu.ContactUsPage.title) {
          menuTitles.push(menu.ContactUsPage.title);
        }

        // Add titles from custom_page if they exist
        if (menu.custom_page && menu.custom_page.length > 0) {
          const customPageTitles = menu.custom_page.map(
            (customPage) => customPage.custom_page_title
          );
          menuTitles = [...menuTitles, ...customPageTitles];
        }

        return {
          title: menu.footer_menu_title || "",
          menus: menuTitles,
        };
      });
      setFooters(allFooters);
      // Add this part
      const allSelectedMenus = allFooters.map((footer) => footer.menus);
      setSelectedMenus(allSelectedMenus);
      // Extract and set cards
      const cardData = footerData.card.map((card) => ({
        pay_card_master_id: card.pay_card_master.pay_card_master_id,
        card_name: card.pay_card_master.card_name,
        card_image: card.pay_card_master.card_image,
      }));
      setCards(cardData);

      // Handle toggles for cards
      const cardToggleStates = {};
      footerData.card.forEach((card) => {
        cardToggleStates[card.pay_card_master_id] = card.is_enabled === 1;
      });
      setCardToggles(cardToggleStates);
    } catch (error) {
      console.error("Error fetching footer details:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Footer not found"
      ) {
        setDataSubmitted(false);
      }
    }
  };

  // Using an effect hook to trigger the fetch when the component mounts
  useEffect(() => {
    fetchFooterDetails();
  }, [dataSubmitted]);

  // Function to handle form submission.
  const handleSubmit = async () => {
    // Fetch IDs
    const pageIDs = await fetchPageIDs();
    // if (!pageIDs) {
    //   Swal.fire("Error", "Failed to fetch page IDs!", "error");
    //   return;
    // }

    const payload = {
      footer_description: footerContent,
      copyright_content: copyRightContent,
      website_customization_id: 3,
      social_media: socialMedias.map((media) => ({
        social_media_url: media.url,
        social_media_master_id: media.key,
        is_enabled: toggleStates[media.key] ? 1 : 0,
      })),
      card: cards.map((card) => ({
        pay_card_master_id: card.pay_card_master_id,
        is_enabled: cardToggles[card.pay_card_master_id] ? 1 : 0,
      })),

      footer_menu: footers.map((footer, index) => {
        const selectedCustomPages = selectedMenus[index];
        return {
          footer_menu_id: footer.id,
          footer_menu_title: footer.title,
          about_us_page_id: selectedCustomPages.includes("About Us")
            ? pageIDs.aboutUsPageID
            : null,
          contact_us_page_id: selectedCustomPages.includes("Contact Us")
            ? pageIDs.contactUsPageID
            : null,
          footer_id: 5,
          custom_page_ids: selectedCustomPages
            .filter((page) => page !== "About Us" && page !== "Contact Us")
            .map((pageName) => pageIDs.customPageIDsMap[pageName]), // replace names with their respective IDs
        };
      }),
    };

    try {
       await axios.patch(
        `${REACT_APP_BASE_URL}/footer`,
        payload
      );

      if (dataSubmitted) {
        Swal.fire({
          title: "Success!",
          text: "Datas Updated successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Success!",
          text: "Datas saved successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
      // fetchFooterMenus();
      // fetchSocialMediaDetails();
      //   fetchCardDetails();
      fetchFooterDetails();
    } catch (error) {
      console.error("Error saving the data:", error);
      Swal.fire("Error", "Failed to save data!", "error");
    }
  };
 // Fetch page IDs for various page types like 'AboutUs', 'ContactUs', and custom pages.
 const fetchPageIDs = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/footer/footer_menu`
    );
    const data = response.data;
    // Extract the custom page IDs and map them with their titles.
    let customPageIDsMap = {};
    data.custom_page.forEach((page) => {
      customPageIDsMap[page.custom_page_title] = page.custom_page_id;
    });

    return {
      aboutUsPageID: data.about_us_page?.about_us_page_id,
      contactUsPageID: data.contact_us_page?.contact_us_page_id,
      customPageIDsMap,
    };
  } catch (error) {
    console.error("Error fetching page IDs:", error);
    return null;
  }
};
  // Utility function to validate URLs.
  function isValidURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  // Validate the form inputs and set any validation errors.
  const validateForm = () => {
    let validationErrors = {};

    // Checking if each required field is filled
    if (!footerContent) {
      validationErrors.footerContent = "Footer content is required.";
    }

    if (!copyRightContent) {
      validationErrors.copyRightContent = "Copyright content is required.";
    }

    footers.forEach((footer, index) => {
      if (!footer.title) {
        validationErrors[`footerTitle-${index}`] = "Menu title is required.";
      }
      if (!selectedMenus[index] || selectedMenus[index].length === 0) {
        validationErrors[`footerMenu-${index}`] =
          "At least one menu item is required.";
      }
    });
    // Validation for social media inputs

    socialMedias.forEach((media) => {
      if (toggleStates[media.key]) {
        if (!media.url || media.url.trim() === "") {
          validationErrors[
            `socialMediaURL-${media.key}`
          ] = `${media.name} URL is required.`;
        } else if (!isValidURL(media.url)) {
          validationErrors[
            `socialMediaURL-${media.key}`
          ] = `${media.name} URL is not valid.`;
        }
      }
    });

    setErrors(validationErrors);
  };
  // UseEffect hook to validate the form inputs when specific states change.
  useEffect(() => {
    validateForm();
  }, [footerContent, copyRightContent, footers, selectedMenus, socialMedias]);

  // Handle the opening and closing of the accordion UI component.
  const handleAccordionChange = () => {
    if (activeAccordion === "FooterAccordian") {
      onToggle && onToggle(null); // close the current accordion if it's 'AuthAccordian'.
    } else {
      onToggle && onToggle("FooterAccordian"); // open the 'AuthAccordian' if another accordion is currently active.
    }
  };

  return (
    <div className="mt-3">
      <Accordion
        className=" bg-opacity-50"
        expanded={activeAccordion === "FooterAccordian"}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontFamily: "poppins" }}>
            Footer Customization
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col space-y-4">
          <label className="font-medium font-poppins text-gray-600 block">
            Footer Content
          </label>
          <input
            className="mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-2/3 sm:text-sm border-gray-300 rounded-md"
            type="text"
            placeholder="Footer Content..."
            value={footerContent}
            onChange={(e) => {
              setFooterContent(e.target.value);
              IsSavedInputs();
            }}
          />
          {errors.footerContent && (
            <span className=" text-red-700 w-1/3 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
              {errors.footerContent}
            </span>
          )}
          <label className="font-medium font-poppins text-gray-600 block">
            Copyright Content
          </label>
          <input
            className="mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-2/3 sm:text-sm border-gray-300 rounded-md"
            type="text"
            value={copyRightContent}
            placeholder="Copyright content..."
            onChange={(e) => {
              setCopyRightContent(e.target.value);
              IsSavedInputs();
            }}
          />
          {errors.copyRightContent && (
            <span className=" text-red-700 w-1/3 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
              {errors.copyRightContent}
            </span>
          )}
          <label className="font-medium font-poppins text-gray-800 block">
            Footer Optimizations
          </label>
          {/* dynamic footer starts */}
          {footers.map((footer, index) => (
            <div
              key={index}
              className="border border-gray-300 rounded-lg p-3 w-2/3 relative pb-10"
            >
              <label className="font-medium font-poppins text-gray-600 text-center block">
                Footer {index + 1}
              </label>
              <label className="font-medium font-poppins text-gray-600 block">
                {" "}
                Menu title{" "}
              </label>
              <input
                className="mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                type="text"
                placeholder="Footer title..."
                value={footer.title}
                onChange={(e) => {
                  const updatedTitle = e.target.value;
                  setFooters((prevFooters) =>
                    prevFooters.map((f, idx) =>
                      idx === index ? { ...f, title: updatedTitle } : f
                    )
                  );
                  IsSavedInputs();
                }}
              />
              {errors[`footerTitle-${index}`] && (
                <span className=" text-red-700 w-1/3 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                  {errors[`footerTitle-${index}`]}
                </span>
              )}
              <SelectBox
                isOpen={isOpen[index] || false}
                setIsOpen={() => toggleDropdown(index)}
                toggleDropdown={() => toggleDropdown(index)}
                footerMenus={footerMenus}
                toggleMenuSelection={(menu) => toggleMenuSelection(menu, index)}
                selectedMenus={selectedMenus[index]}
                removeChip={(menu) => removeChip(menu, index)}
                footerIndex={index}
                errors={errors}
                className="w-full mb-6"
              />
              {errors[`footerMenu-${index}`] && (
                <span className=" text-red-700 w-1/3 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                  {errors[`footerMenu-${index}`]}
                </span>
              )}
              <div
                onClick={() => deleteFooter(index)}
                className="absolute bottom-3 right-3 cursor-pointer text-red-500 hover:text-red-600"
              >
                <FaTrash size={20} />
              </div>
            </div>
          ))}

          {/* dynamic footer Ends */}
          {footers.length < 4 && (
            <button
              className="mb-2 mt-3 w-full sm:w-[50%] md:w-[30%] lg:w-[20%] xl:w-[15%] px-2 sm:px-4 py-1.5 sm:py-2 text-sm sm:text-base bg-indigo-600 text-white rounded hover:bg-indigo-700"
              onClick={addFooter}
            >
              Add Footer
            </button>
          )}

          <label className="font-medium font-poppins text-gray-600 block">
            Social Media Url's
          </label>
          <div className="w-full flex flex-wrap border rounded-lg border-gray-300 p-3">
            {socialMedias.map((media, index) => (
              <div
                key={media.key}
                className={`flex items-center space-x-4 mb-4 xl:w-1/2 ${
                  index % 2 === 0 ? "" : "ml-0"
                }`}
              >
                <img
                  src={media.image}
                  alt={media.name}
                  className={`cursor-pointer ${
                    !toggleStates[media.key]
                      ? "opacity-50 pl-5"
                      : "opacity-100 pl-5 "
                  }`}
                />

                <div className="flex flex-col flex-grow">
                  <input
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md ${
                      !toggleStates[media.key] ? "opacity-50" : "opacity-100"
                    }`}
                    type="text"
                    value={media.url}
                    onChange={(e) => {
                      const updatedURL = e.target.value;
                      setSocialMedias((prevState) =>
                        prevState.map((item) =>
                          item.key === media.key
                            ? { ...item, url: updatedURL }
                            : item
                        )
                      );
                      IsSavedInputs();
                    }}
                    placeholder={`${media.name} URL...`}
                    disabled={!toggleStates[media.key]}
                  />
                  {errors[`socialMediaURL-${media.key}`] && (
                    <p className="text-red-700 w-full mt-2 bg-red-50 font-poppins px-2 py-1 rounded shadow-md">
                      {errors[`socialMediaURL-${media.key}`]}
                    </p>
                  )}
                </div>

                <ReactSwitch
                  checked={toggleStates[media.key]}
                  onChange={(checked) => {
                    setToggleStates((prev) => ({
                      ...prev,
                      [media.key]: checked,
                    }));
                    // Clear the error if the switch is toggled off
                    if (!checked) {
                      setErrors((prevErrors) => {
                        const newErrors = { ...prevErrors };
                        delete newErrors[`socialMediaURL-${media.key}`];
                        return newErrors;
                      });
                    }
                    IsSavedInputs();
                  }}
                />
              </div>
            ))}
          </div>
          {/* Cards fetched from the API */}
          <label className="font-medium font-poppins text-gray-600 block mb-2">
            Cards
          </label>
          <div className="w-full grid grid-cols-3 gap-2 border rounded-lg border-gray-300 p-5">
            {cards.map((card) => (
              <div
                key={card.pay_card_master_id}
                className="flex items-center space-x-2"
              >
                <img
                  src={card.card_image}
                  alt={card.card_name}
                  className="cursor-pointer w-[20%] object-fill" // fixed width and height for uniformity
                />
                <ReactSwitch
                  checked={cardToggles[card.pay_card_master_id] || false}
                  onChange={(checked) => {
                    setCardToggles((prev) => ({
                      ...prev,
                      [card.pay_card_master_id]: checked,
                    }));
                    IsSavedInputs();
                  }}
                />
              </div>
            ))}
          </div>

          <button
            onClick={handleSubmit}
            className={`bg-gradient-to-r from-indigo-500 to-blue-600 mt-4 hover:from-indigo-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
              Object.keys(errors).length > 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={Object.keys(errors).length > 0}
          >
            {Object.keys(errors).length > 0
              ? "Fill the required fields"
              : dataSubmitted
              ? "Update"
              : "Save"}
          </button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FooterCustomization;
