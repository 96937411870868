import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import useTokenFromURL from "../../Context/useTokenFromURL";
import useAxios from "../../Axios/useAxios";
import { BASE_URL } from "../../config";
import Tooltip from "@mui/material/Tooltip";
import { FaEye } from "react-icons/fa";
import Antique from "./ThemeGroups/Antique";
import Contemporary from "./ThemeGroups/Contemporary";
import ArtDeco from "./ThemeGroups/ArtDeco";
import Modern from "./ThemeGroups/Modern";
import Rustic from "./ThemeGroups/Rustic";
import DivineGrace from "./ThemeGroups/DivineGrace";
import { AiOutlineClose } from "react-icons/ai";

import Modal from "react-modal";

const REACT_APP_BASE_URL = BASE_URL;

const ThemeCard = ({
  themeName,
  themeImage,
  isSelected,
  isClicked,
  onClick,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState();
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleViewTheme = () => {
    setModalIsOpen(true);
    setSelectedTheme(themeName);
  };

  return (
    <div
      className={`w-[90%] h-64 xl:h-72 p-3 m-4 border-2 rounded-lg cursor-pointer transition-all duration-300 shadow-md transform hover:scale-105 hover:rotate-3 ${
        isSelected ? "border-3 border-green-500" : ""
      } ${isClicked ? "border-blue-500" : "border-gray-300"}`}
      onClick={onClick}
    >
      <div
        className="w-full h-full rounded-t-lg bg-cover bg-center flex items-end justify-center relative"
        style={{ backgroundImage: `url(${themeImage})` }}
      >
        <button
          onClick={handleViewTheme}
          className="absolute top-1 right-2 bg-gray-500 text-white text-lg  w-7 h-7 rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110 cursor-pointer"
        >
          <FaEye size={14} />
        </button>
      </div>
      <div className="w-full h-1/3 p-4 text-center">
        <p className="font-semibold text-lg">{themeName}</p>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <div>
          <button
            onClick={() => setModalIsOpen(false)}
            className="absolute top-2 right-0 text-black rounded-full p-2"
          >
            <AiOutlineClose size={17} />
          </button>
          {selectedTheme === "Contemporary" && (
            <div>
              <Contemporary />
            </div>
          )}
          {selectedTheme === "Antique" && (
            <div>
              <Antique setModalIsOpen={setModalIsOpen} />
            </div>
          )}
          {selectedTheme === "Modern" && (
            <div>
              <Modern />
            </div>
          )}
          {selectedTheme === "Art Deco" && (
            <div>
              <ArtDeco />
            </div>
          )}
          {selectedTheme === "Rustic" && (
            <div>
              <Rustic />
            </div>
          )}
          {selectedTheme === "Divine Grace" && (
            <div>
              <DivineGrace />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

const ThemeSelector = ({
  onThemeSelected,
  themeDetails,
  setIsResetEnabled,
  themeSelected,
}) => {
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [clickedTheme, setClickedTheme] = useState(null);
  const [choosenTheme, setChoosenTheme] = useState(null);
  useTokenFromURL();
  useAxios();

  useEffect(() => {
    if (themeDetails) {
      setSelectedTheme(themeDetails.theme_master_id);
      setClickedTheme(themeDetails.theme_master_id - 1);
    } else {
      setSelectedTheme("");
      setClickedTheme("");
    }
  }, [themeDetails, themeSelected]);

  const handleClick = (index) => {
    setClickedTheme(index);
    setChoosenTheme(index);
  };

  const themes = [
    {
      name: "Antique",
      image:
        "https://ik.imagekit.io/6r88tgrnc/Antique.jpg?updatedAt=1718253693294",
    },
    {
      name: "Contemporary",
      image:
        "https://ik.imagekit.io/6r88tgrnc/Contemporary.jpg?updatedAt=1718253693225",
    },
    {
      name: "Modern",
      image:
        "https://ik.imagekit.io/6r88tgrnc/Modern.jpg?updatedAt=1718253693249",
    },
    {
      name: "Art Deco",
      image:
        "https://ik.imagekit.io/6r88tgrnc/Art_Deco.jpg?updatedAt=1718253693455",
    },
    {
      name: "Rustic",
      image:
        "https://ik.imagekit.io/6r88tgrnc/Rustic.jpg?updatedAt=1718253693460",
    },
    {
      name: "Divine Grace",
      image:
        "https://ik.imagekit.io/6r88tgrnc/Divine_Grace.jpg?updatedAt=1718253693365",
    },
  ];

  const saveTheme = async () => {
    if (themeDetails) {
      setIsResetEnabled(true);
      return false;
    }
    if (choosenTheme === null) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please select a theme before saving.",
        timer: 2000,
      });
      return;
    }

    try {
      const response = await axios.post(`${REACT_APP_BASE_URL}/theme-manager`, {
        theme_master_id: choosenTheme + 1,
      });
      if (response.status === 201) {
        onThemeSelected();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Theme saved successfully!",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to save theme.",
          timer: 2000,
        });
      }
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data.message === "User_id already exist"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Already selected the theme. Use Update Option",
          timer: 2000,
        });
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container py-4">
        <h1 className="text-center text-2xl xl:text-3xl mb-2">
          Select a theme
        </h1>
        <div className="grid grid-cols-3 gap-4 theme-card-container">
          {themes.map((theme, index) => (
            <ThemeCard
              key={index}
              themeName={theme.name}
              themeImage={theme.image}
              isSelected={selectedTheme - 1 === index}
              isClicked={clickedTheme === index}
              onClick={() => handleClick(index)}
              className={`border-2 rounded-lg cursor-pointer transition-all duration-300 shadow-md transform hover:scale-105 hover:rotate-3 ${
                index >= 4 ? "pt-8" : ""
              }`}
            />
          ))}
        </div>
        <div className="flex justify-center mt-14">
          <Tooltip
            title={
              selectedTheme
                ? "Theme is currently selected. Please reset to change your selection"
                : ""
            }
            placement="top"
            arrow
          >
            <button
              className={`px-6 py-3 mr-3 text-white text-lg rounded-md transform hover:scale-105 transition-transform duration-200 ease-in-out shadow-lg hover:shadow-2xl bg-gradient-to-r from-blue-500 to-blue-700 ${
                selectedTheme ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={saveTheme}
            >
              Save Theme
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ThemeSelector;
