import React, { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import axios from "axios";
import Swal from "sweetalert2";
import useTokenFromURL from "../../../Context/useTokenFromURL";
import Modals from "./Modals"
import useAxios from "../../../Axios/useAxios";
import Accordian from "./Accordian";
import Preview from "./Preview";
import Skeleton from "./Skeleton"
import ReactSwitch from "react-switch";
import { BASE_URL } from '../../../config';
const REACT_APP_BASE_URL = BASE_URL;
const InformationSlider = ({
  refresh,
  widgetType,
  widgetId,
  fetchWidgetOrder,
  setHasInteracted,
  setUnsavedChanges,
}) => {
  const [formValues, setFormValues] = useState({
    widgetNameInput: "",
    widgetTittleInput: "",
    widgetTypeInput: widgetType,
    number_of_info:5,
    is_slider_enabled:0
  });

  const infoBoxLimit = formValues.is_slider_enabled === 0 ? 6 : 20;  // If is_slider_enabled is 0, set the limit to 6. Otherwise, no limit.

  const isAnyModalOpen = () => {
    return isModalOpen || HeaderColorModalOpen || TextColorModalOpen || isGalleryModalOpen;
  };
  const IsSavedInputs = () => {
    setHasInteracted(true);
    setUnsavedChanges(true);
};
  const [currentInfoBoxId, setCurrentInfoBoxId] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tempBgColor, setTempBgColor] = useState("#E3BFAF");
  const [tempHeadColor, setTempHeadColor] = useState("#c5247e");
  const [tempTextColor, setTempTextColor] = useState("#c5247e");
  const [loading,setLoading]=useState(false);
  const [infoId, setInfoId] = useState(null);
  // New state for color picker
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [HeaderColorModalOpen, setHeaderColorModalOpen] = useState(false);
  const [TextColorModalOpen, setTextColorModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
const handleTempBgColorChange = (color) => {
  setTempBgColor(color.hex);
};
const handleTempHeaderColorChange = (color) => {
  setTempHeadColor(color.hex);
};
const handleTempTextColorChange = (color) => {
  setTempTextColor(color.hex);
};
const preventDirectInput = (event) => {
  // Prevent direct typing
  event.preventDefault();
};

  // New state to hold the list of accordions
  const [infoBoxes, setInfoBoxes] = useState([
    {
      id: 1,
      header_text: "",
      sub_text: "",
      background_colour: "#E3BFAF",
      header_colour: "#c5247e",
      text_colour: "#c5247e",
     
      icon: null,
      newColor:"",
    },
  ]);
  const [infoBoxErrors, setInfoBoxErrors] = useState({});
  const [DataSubmitted, setDataSubmitted] = useState(false);
  const checkErrors = () => {
    let errors = {};
    infoBoxes.forEach((box, idx) => {
      if (!box.header_text.trim()) {
        errors[`header_text-${idx}`] = "Header Text is required";
      }
      if (!box.sub_text.trim()) {
        errors[`sub_text-${idx}`] = "Sub Text is required";
      } else if (box.sub_text.length > 120) {
        errors[`sub_text-${idx}`] = "Sub Text length exceeds 60 characters";
      }
      if (!box.icon) {
        errors[`icon-${idx}`] = "Icon is required";
      }
    });
    return errors;
  };
  
  // Function to add a new info box
  const addInfoBox = () => {
    let errors = checkErrors();
    setInfoBoxErrors(errors); // always set errors whether they exist or not

    // Only add new accordion if no errors
    if (Object.keys(errors).length === 0) {
      setInfoBoxes((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          header_text: "",
          sub_text: "",
          background_colour: "#E3BFAF",
          header_colour: "#c5247e",
          text_colour: "#c5247e",
          icon: null,
        },
      ]);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.widgetNameInput?.trim()) {
      newErrors.widget_name = "Widget Name is Required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    let errors = checkErrors();
    setInfoBoxErrors(errors);
    validateForm();
  }, [infoBoxes, formValues]);

  const deleteImageIcon = (id) => {
    setInfoBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === id ? { ...box, icon: null } : box
      )
    );
    IsSavedInputs();
  };
  

  const deleteInfoBox = (id) => {
    setInfoBoxes((prev) => prev.filter((infoBox) => infoBox.id !== id));
    IsSavedInputs();
  };
 
  // New state for toggling color picker visibility
  useEffect(() => {
    if (infoId !== null) {
        // Reset formValues
        setFormValues({
            widgetNameInput: "",
            widgetTypeInput: widgetType,
        });
        
        // Reset infoBoxes
        setInfoBoxes([
            {
                id: 1,
                header_text: "",
                sub_text: "",
                background_colour: "#E3BFAF",
                header_colour: "#c5247e",
                text_colour: "#c5247e",
                icon: null,
            },
        ]);
    }
}, [infoId]);

  const handleColorChange = (color) => {
    setInfoBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === currentInfoBoxId
          ? { ...box, background_colour: color.hex }
          : box
      )
    );
    IsSavedInputs();
    setIsModalOpen(false); // Hide the picker after selecting the color
  };
  const handleHeaderColorChange = (color) => {
    setInfoBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === currentInfoBoxId ? { ...box, header_colour: color.hex } : box
      )
    );
    IsSavedInputs();
    setHeaderColorModalOpen(false); // Hide the picker after selecting the color
  };
  const handleTextColorChange = (color) => {
    setInfoBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === currentInfoBoxId ? { ...box, text_colour: color.hex } : box
      )
    );
    IsSavedInputs();
    setTextColorModalOpen(false); // Hide the picker after selecting the color
  };
  const handleImageSelect = (selectedImagePath) => {
    setInfoBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === currentInfoBoxId ? { ...box, icon: selectedImagePath } : box
      )
    );

    // If icon is set, remove the error
    setInfoBoxErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`icon-${currentInfoBoxId - 1}`];
      return newErrors;
    });
    IsSavedInputs();
    setIsGalleryModalOpen(false);
  };

  useTokenFromURL();
  useAxios();
  const handleChange = (eventOrValue) => {
    // If the event is from ReactSwitch, the first argument will be a boolean (checked value)
    if (typeof eventOrValue === "boolean") {
      setFormValues(prevState => ({
        ...prevState,
        is_slider_enabled: eventOrValue ? 1 : 0  // Convert boolean to 1 or 0
      }));
    } else {
      const { name, value } = eventOrValue.target;
      // If the input is the number input, convert the value to a number
      if (name === "number_of_info") {
        setFormValues(prevState => ({
          ...prevState,
          [name]: parseInt(value, 10)
        }));
      } else {
        setFormValues(prevState => ({
          ...prevState,
          [name]: value
        }));
      }
    }

    IsSavedInputs();
};



  const handleTextChange = (e, id) => {
    const { name, value } = e.target;

    setInfoBoxes((prevBoxes) =>
      prevBoxes.map((box) => (box.id === id ? { ...box, [name]: value } : box))
    );

    if (name === "header_text" && value.trim()) {
      // If header text is not empty, remove the error
      setInfoBoxErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[`header_text-${id - 1}`];
        return newErrors;
      });
    } else if (name === "sub_text" && value.trim()) {
      // If sub text is not empty, remove the error

      if (value.length > 120) {
        setInfoBoxErrors((prevErrors) => ({
          ...prevErrors,
          [`sub_text-${id - 1}`]: "Sub Text length exceeds 60 characters",
        }));
        return; // Prevent further processing if the length exceeds 60
      }

      setInfoBoxErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[`sub_text-${id - 1}`];
        return newErrors;
      });
    }

    IsSavedInputs();
  };

  const fetchInfoData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/info-slider/${widgetId}`
      );
      
      const transformedInfoSliderBox = response.data[0].info_slider_box.map((box, index) => {
        return {
          ...box,
          id: index + 1  // Adjust ID to start from 1
        };
      });
      
      const adjustedData = {
        ...response.data[0],
        info_slider_box: transformedInfoSliderBox
      };
        
      setFormFields(adjustedData);
  
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };
  
  const setFormFields = (data) => {
    setFormValues({
      widgetNameInput: data.widget_name,
      widgetTittleInput: data.widget_title,
      widgetTypeInput: data.widget_type,
      number_of_info:data.number_of_info,
      is_slider_enabled:data.is_slider_enabled,
    });

    setInfoId(data.info_slider_id);
    setInfoBoxes(data.info_slider_box);
    setLoading(true);
    const formsubmit = data.info_slider_box.length !== 0 ? true : false;
    setDataSubmitted(formsubmit);
  };
  useEffect(() => {
    fetchInfoData();
    setLoading(false);
  }, [refresh,infoId]);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
       await axios.patch(
        `${REACT_APP_BASE_URL}/info-slider/${widgetId}`,
        {
          widget_name: formValues.widgetNameInput,
          widget_title: formValues.widgetTittleInput,
          number_of_info:formValues.number_of_info,
          is_slider_enabled:formValues.is_slider_enabled,
          info_slider_box: infoBoxes,
        }
      );

      Swal.fire({
        icon: "success",
        title: "Data sent successfully.",
        showConfirmButton: false,
        timer: 1500,
      });
      setUnsavedChanges(false);
      fetchWidgetOrder();
      fetchInfoData();
      // setBannerDataSubmit(true);
      // fetchBannerData();
      // Clear all form values after successful submission
      // clearFormValues();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    } finally {
      // setIsFormSubmitted(false); // Set isFormSubmitted back to false when form submission is complete
    }
  };
  return (
    <>
    {!loading? <Skeleton/>: <div>
      <h1 className="text-lg font-poppins text-gray-600 text-center">
        Info Slider Customization
      </h1>
      {!isAnyModalOpen() && <Preview infoBoxes={infoBoxes} />}
      <form className="space-y-4 pt-6">
  
        <div className="flex space-x-4">
          <div className="w-1/2  ">
            <label
              htmlFor="widgetNameInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Name*
            </label>
            <input
              id="widgetNameInput"
              name="widgetNameInput"
              type="text"
              placeholder={formValues.widgetNameInput}
              value={formValues.widgetNameInput}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
             {errors.widget_name && (
                  <p className="text-red-500 text-sm font-poppins">
                    {errors.widget_name}
                  </p>
              )}
          </div>
          <div className="w-1/2 ">
              <label
                htmlFor="widgetTittleInput"
                className="block text-sm font-medium text-gray-700"
              >
                Widget Title
              </label>
              <input
                id="widgetTittleInput"
                name="widgetTittleInput"
                type="text"
                placeholder={formValues.widgetTittleInput}
                value={formValues.widgetTittleInput}
                onChange={handleChange}
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="widgetTypeInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Types
            </label>
            <input
              id="widgetTypeInput"
              name="widgetTypeInput"
              type="text"
              disabled
              placeholder={formValues.widgetTypeInput}
              value={formValues.widgetTypeInput}
              className="mt-1 focus:ring-indigo-500 bg-gray-200 opacity-50 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="flex space-x-4">
        <div className="w-1/2 flex items-center mt-5 relative"> {/* Added relative here for positioning tooltip */}
    
    <label 
        className="mr-3 text-sm font-poppins cursor-pointer" 
        onMouseEnter={() => setShowTooltip(true)} 
        onMouseLeave={() => setShowTooltip(false)}
    >
        Slider Available ?
    </label>

    {/* Tooltip */}
    {showTooltip && (
        <div className="absolute top-0 left-0 ml-32 mt-2 p-2 border rounded-md shadow-lg bg-white text-black text-xs z-10">
         <span className="font-poppins text-gray-600">if slider Available is on. N number of info boxes to be add, if slider available is off, info box limit is 4-6  </span>
        </div>
    )}

    <ReactSwitch
        handleDiameter={18}
        checked={formValues.is_slider_enabled}
        onChange={handleChange} 
        disabled={infoBoxes.length > 6} />
</div>
       

{formValues.is_slider_enabled === 1 &&   
    <div className="w-1/2">
      <label
        htmlFor="displayProducts"
        className="block text-sm font-medium text-gray-700"
      >
        Min Info Boxes to display in Web Screen(5)
      </label>
      <input
        id="displayProducts"
        name="number_of_info"  // Fix the name attribute
        type="number"
        min={5}
        // max={5}
        value={formValues.number_of_info}
        onChange={handleChange}
        onKeyDown={preventDirectInput}
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </div>}
      
    
          </div>
      </form>
      {formValues.is_slider_enabled === 1 && infoBoxes.length > 6 && <span className="font-poppins text-sm text-yellow-600"> ** Info box limit is max 6 to update the toggle slider</span>}
    
      <div className="mt-4">
      <Accordian
        infoBoxes={infoBoxes}
        setIsModalOpen={setIsModalOpen}
        setHeaderColorModalOpen={setHeaderColorModalOpen}
        deleteInfoBox={deleteInfoBox}
        handleTextChange={handleTextChange}
        setTextColorModalOpen={setTextColorModalOpen}
        setCurrentInfoBoxId={setCurrentInfoBoxId}
        setInfoBoxes={setInfoBoxes}
        currentInfoBoxId={currentInfoBoxId}
        infoBoxErrors={infoBoxErrors}
        deleteImageIcon={deleteImageIcon}
        setIsGalleryModalOpen={setIsGalleryModalOpen}
      />
      </div>

      {Object.keys(infoBoxErrors).length === 0 && (infoBoxes.length < infoBoxLimit) && (
    <button
      onClick={addInfoBox}
      className="flex items-center space-x-2 text-blue-600 hover:text-blue-600 mt-4"
    >
      <AiOutlinePlus size="1.5em" />
      <span> Add Info Box</span>
    </button>
)}

        <Modals
         setInfoBoxes={setInfoBoxes}
         handleTempBgColorChange={handleTempBgColorChange} 
         handleTempHeaderColorChange={handleTempHeaderColorChange}
          handleTempTextColorChange={handleTempTextColorChange}
          handleColorChange={handleColorChange}
          handleHeaderColorChange={handleHeaderColorChange}
          handleTextColorChange={handleTextColorChange}
          handleImageSelect={handleImageSelect}
           currentInfoBoxId={currentInfoBoxId} 
           IsSavedInputs={IsSavedInputs} 
           setInfoBoxErrors={setInfoBoxErrors} 
           tempBgColor={tempBgColor}
           tempHeadColor={tempHeadColor}
           tempTextColor={tempTextColor}
           isModalOpen={isModalOpen}
           setIsModalOpen={setIsModalOpen}
           isGalleryModalOpen={isGalleryModalOpen}
           setIsGalleryModalOpen={setIsGalleryModalOpen}
           HeaderColorModalOpen={HeaderColorModalOpen}
           TextColorModalOpen={TextColorModalOpen}
           />
 
      <div className="mt-4">
      {  Object.keys(infoBoxErrors).length !== 0 &&   <span className="font-poppins text-red-500 ">
              Must Fill all the required fields in Info Boxes
            </span>}
      </div>
    <button
        disabled={Object.keys(infoBoxErrors).length !== 0 || Object.keys(errors).length !== 0}
        onClick={handleFormSubmit}
        className={
          (Object.keys(infoBoxErrors).length !== 0 || Object.keys(errors).length !== 0)
          ? "text-white font-bold mt-4  py-2 px-4 rounded-md opacity-50 bg-blue-500 cursor-not-allowed"
          :
          `bg-blue-500 mt-4 hover:bg-blue-700  text-white font-bold py-2 px-4 rounded-md`
        }
      >
        {DataSubmitted ? "Update" : "Save"}
      </button> 
    
    </div>}
   
    </>
  );
};

export default InformationSlider;
