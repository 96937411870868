import React from 'react'

const Skeleton = () => {
  return (
    <>
     <div className="animate-pulse py-10">
          
            <div className="flex space-x-4">
                <div className="h-10 bg-gray-300 rounded w-1/2"></div>
                <div className="h-10 bg-gray-300 rounded w-1/2"></div>
            </div>
            <div className="mt-4 h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
            <div className="h-10 bg-gray-300 rounded w-full mb-4"></div>
            <div className="h-10 bg-gray-300 rounded w-1/3 mb-4"></div>
            <div className="flex space-x-4">
                <div className="h-10 bg-gray-300 rounded w-1/2"></div>
                <div className="h-10 bg-gray-300 rounded w-1/2"></div>
            </div>
            <div className="h-6 bg-gray-300 rounded w-1/3 mt-4 mb-4"></div>
            <div className="flex space-x-4">
                <div className="h-10 bg-gray-300 rounded w-1/4"></div>
                <div className="h-10 bg-gray-300 rounded w-1/4"></div>
                <div className="h-10 bg-gray-300 rounded w-1/4"></div>
            </div>
            <div className="mt-8 h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
            <div className="flex space-x-4">
                <div className="h-10 bg-gray-300 rounded w-1/2"></div>
                <div className="h-10 bg-gray-300 rounded w-1/2"></div>
            </div>
        </div>
    </>
  )
}

export default Skeleton