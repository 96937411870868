import React, { useState,useEffect } from 'react';
import { FiLink,FiUpload } from 'react-icons/fi';
import { CgClose } from 'react-icons/cg';
import Modal from "react-modal";
import Gallery from '../../Gallery';
import axios from "axios";
import Swal from "sweetalert2";
import PreviewCollage from './PreviewCollage';
import  useTokenFromURL  from '../../../Context/useTokenFromURL';
import useAxios from '../../../Axios/useAxios';
import Skeleton from "./Skeleton"
import { BASE_URL } from '../../../config';
// import DeleteIcon from '@mui/icons-material/Delete';
import {MdFileUpload } from "react-icons/md";
const REACT_APP_BASE_URL = BASE_URL;
const CollageCustomization = ({refresh, widgetType,widgetId,fetchWidgetOrder,  setHasInteracted,
  setUnsavedChanges, }) => {
  const [formValues, setFormValues] = useState({
    widgetNameInput: '',
    widgetTittleInput: '',
    widgetTypeInput: widgetType,
    displayType:""
  });
  
const handleRadioChange = (e) => {
  const { name, value } = e.target;
  setFormValues(prevState => ({
    ...prevState,
    [name]: value,
  }));
};

   useTokenFromURL();
   useAxios();
 
   const IsSavedInputs = () => {
    setHasInteracted(true);
    setUnsavedChanges(true);
};
  const [activeBlock, setActiveBlock] = useState(0);

  const [SelectedactiveBlock, setSelectedActiveBlock] = useState(null);
  const [collageId,setCollageId]= useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [blockOneimages, setBlockOneImages] = useState(Array(1).fill({ image_url: null, image_link: null }));
  const [menuLinkInput, setMenuLinkInput] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [blockTwoimages, setBlockTwoImages] = useState(Array(4).fill({ image_url: null, image_link: null }));
  const [showModal2, setShowModal2] = useState(false);
  const [currentEditingImageIndex, setCurrentEditingImageIndex] = useState(null);
  const [menuLinkInput2, setMenuLinkInput2] = useState("");

  const [blockThreeimages, setBlockThreeImages] = useState(Array(5).fill({ image_url: null, image_link: null }));
  const [showModal3, setShowModal3] = useState(false);
  const [currentEditingImageIndex3, setCurrentEditingImageIndex3] = useState(null);
  const [menuLinkInput3, setMenuLinkInput3] = useState("");

  const [blockFourimages, setBlockFourImages] = useState(Array(3).fill({ image_url: null, image_link: null }));
  const [showModal4, setShowModal4] = useState(false);
  const [currentEditingImageIndex4, setCurrentEditingImageIndex4] = useState(null);
  const [menuLinkInput4, setMenuLinkInput4] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading,setLoading]=useState(false);
  const [DataSubmitted, setDataSubmitted] = useState(false);
  const [reqDimension, setReqDimension] = useState('');
  const customModalStyles = {
    content: {
      width: '80%', // Adjust the width as per your requirement
      margin: 'auto', // Center the modal horizontally
    },
  };
// ... Other states ...

const [selectedButton, setSelectedButton] = useState(null); // Index of the button clicked
const handleUploadClick = (buttonIndex) => {
  if (buttonIndex === 0 ) {
    setReqDimension('3.7:3')
  } else {
    setReqDimension('6.4:2.5')
  }
  setSelectedButton(buttonIndex);
  setModalIsOpen(true);
};

// Mapping for each block's upload buttons
const blockButtonsMapping = {
  0: [0,1,2,3],
  1: [0,1],
  2: [0,1,2,3,4],
  3: [0, 1, 2],
};
const renderUploadButton1 = (buttonIndex) => {

  const handleDeleteClick = (index) => {

    IsSavedInputs();
    const newImages = [...blockOneimages];
    newImages[index] = { image_url: null, image_link: null };
    setBlockOneImages(newImages);
  };
  
  const handleLinkClick = (index) => {
    setMenuLinkInput(blockOneimages[index]?.image_link || '');
    setShowModal(true);
  };
  
  
  const closeModal = () => {
    setShowModal(false);
  };
  const handleModalSubmit = (e) => {
    e.preventDefault();
    const newImages = [...blockOneimages];
    if (newImages[buttonIndex]) {
      if (typeof newImages[buttonIndex] === 'string') {
        // Convert the string to an object with the URL as `image_url`
        newImages[buttonIndex] = {
          image_url: newImages[buttonIndex],
          image_link: menuLinkInput
        };
      } else {
        newImages[buttonIndex].image_link = menuLinkInput;
      }
    } else {
      newImages[buttonIndex] = { image_url: null, image_link: menuLinkInput };
    }
    setBlockOneImages(newImages);
    closeModal();
};


  

  return (
    <div
      style={{
        position: 'relative', width: '100%', height: '100%',
      }}
    >
      {blockOneimages[buttonIndex] && (
        <>
          <img
    src={blockOneimages[buttonIndex]?.image_url || blockOneimages[buttonIndex]}
    alt="Selected"
    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
/>
          <button
            onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleLinkClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '3.5rem', right: '0',
              backgroundColor: blockOneimages[buttonIndex]?.image_link ? 'green' : 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding:"6px",
              transform: 'translateY(-50%)'
            }}
          >
            <FiLink size="1.5em" color="white" />
          </button>
          <button
            onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleDeleteClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '0', right: '0',
              backgroundColor: 'red',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding:"6px"
            }}
          >
            <CgClose size="1.5em" color="white"  />
          </button>
          <div
            onClick={(e) => { e.preventDefault(); handleUploadClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '4.8rem', right: '0',
              backgroundColor: 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding: "6px"
            }}
            >
              <MdFileUpload  size="1.5em" color="white" />
          </div>
        </>
      )}
      {
  (!blockOneimages[buttonIndex] || !blockOneimages[buttonIndex].image_url) && (
    <button
      onClick={(e) => { e.preventDefault(); handleUploadClick(buttonIndex); }}
      style={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
      }}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
    >
      <FiUpload size="1.5em" />
    </button>
  )
}

       {
        showModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50"></div>

{/* Modal */}
<div className="relative bg-white rounded-lg shadow-lg p-6 w-96">
    {/* Close button */}
    <button onClick={closeModal} className="absolute  top-2 right-2 text-red-500 hover:text-red-700">
        <CgClose size="1.5em" />
    </button>
    
    <div className="flex flex-col items-start space-y-4">
      
        <label className="flex flex-col w-full">
            <span className="mb-2 text-lg">Enter Menu link URL:</span>
            <input
  type="text"
  name="linkURL"
  value={menuLinkInput}
  placeholder='https://jpencil.com/gold-jewellery/womens/necklace'
  className="p-2 border rounded-md focus:ring-1 focus:ring-indigo-500 focus:outline-none"
  onChange={(e) => setMenuLinkInput(e.target.value)}
/>


        </label>
        <button 
onClick={handleModalSubmit}
className="mt-4 bg-indigo-600 flex justify-center text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
>
Save
</button>
    </div>
</div>
          </div>
        )
      }
    </div>
  );
};
const renderUploadButton2 = (buttonIndex) => {
const handleDeleteClick = (index) => {
  IsSavedInputs();
  const newImages = [...blockTwoimages];
  newImages[index] = { image_url: null, image_link: null };
  setBlockTwoImages(newImages);
};

const handleLinkClick = (index) => {
  setCurrentEditingImageIndex(index);  // set the current image being edited
  setMenuLinkInput2(blockTwoimages[index]?.image_link || '');
  setShowModal2(true);
};


const closeModal = () => {
  setShowModal2(false);
};

const handleModalSubmit = (e) => {
  e.preventDefault();
  const newImages = [...blockTwoimages];
  
  if(currentEditingImageIndex !== null) {
    const currentImage = newImages[currentEditingImageIndex];
    
    if (typeof currentImage === 'string') {
      newImages[currentEditingImageIndex] = {
        image_url: currentImage,
        image_link: menuLinkInput2
      };
    } else {
      currentImage.image_link = menuLinkInput2;
    }
  }
  
  setBlockTwoImages(newImages);
  closeModal();
};


  return (
    <div
      style={{
        position: 'relative', width: '100%', height: '100%'
      }}
    >
      {blockTwoimages[buttonIndex] && (
        <>
          <img
            src={blockTwoimages[buttonIndex]?.image_url || blockTwoimages[buttonIndex]}
            alt="Selected"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
           <button
            onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleLinkClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '3rem', right: '0',
              backgroundColor: blockTwoimages[buttonIndex]?.image_link ? 'green' : 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding:"6px",
              transform: 'translateY(-50%)'
            }}
          >
            <FiLink size="1rem" color="white" />
          </button>
          <button
            onClick={(e) => { e.preventDefault();e.stopPropagation(); handleDeleteClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '0', right: '0',
              backgroundColor: 'red',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding: "6px"
            }}
          >
            <CgClose size="1rem" color="white" />
          </button>
          <div
            onClick={(e) => { e.preventDefault(); handleUploadClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '4.3rem', right: '0',
              backgroundColor: 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding: "6px"
            }}
            >
              <MdFileUpload  size="1rem" color="white" />
          </div>
        </>
      )}
     {!blockTwoimages[buttonIndex]?.image_url && (
    <button
      onClick={(e) => { e.preventDefault(); handleUploadClick(buttonIndex); }}
      style={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
      }}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
    >
      <FiUpload size="1.5em" />
    </button>
)}

{
        showModal2 && currentEditingImageIndex !== null && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-96">
              <button onClick={closeModal} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
                <CgClose size="1.5em" />
              </button>
              <div className="flex flex-col items-start space-y-4 font-poppins">
                <label className="flex flex-col w-full">
                  <span className="mb-2 text-lg"> Menu link URL for image {currentEditingImageIndex + 1}:</span>
                  <input
                    type="text"
                    name={`linkURL_${currentEditingImageIndex}`}
                    value={menuLinkInput2}
                    placeholder='https://jpencil.com/gold-jewellery/womens/necklace'
                    className="p-2 border rounded-md focus:ring-1 focus:ring-indigo-500 focus:outline-none"
                    onChange={(e) => setMenuLinkInput2(e.target.value)}
                  />
                </label>
                <button 
                  onClick={handleModalSubmit}
                  className="mt-4 flex justify-center bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

const renderUploadButton3 = (buttonIndex) => {

  const handleDeleteClick = (index) => {
    IsSavedInputs();
    const newImages = [...blockThreeimages];
    newImages[index] = { image_url: null, image_link: null };
    setBlockThreeImages(newImages);
  };
  
  const handleLinkClick = (index) => {
    setCurrentEditingImageIndex3(index);  // set the current image being edited
    setMenuLinkInput3(blockThreeimages[index]?.image_link || '');
    setShowModal3(true);
  };
  
  
  const closeModal = () => {
    setShowModal3(false);
  };
  
  const handleModalSubmit = (e) => {
    e.preventDefault();
    const newImages = [...blockThreeimages];
    
    if(currentEditingImageIndex3 !== null) {
      const currentImage = newImages[currentEditingImageIndex3];
      
      if (typeof currentImage === 'string') {
        newImages[currentEditingImageIndex3] = {
          image_url: currentImage,
          image_link: menuLinkInput3
        };
      } else {
        currentImage.image_link = menuLinkInput3;
      }
    }
    
    setBlockThreeImages(newImages);
    closeModal();
  };
  
  return (
    <div
      style={{
        position: 'relative', width: '100%', height: '100%'
      }}
    >
      {blockThreeimages[buttonIndex] && (
        <>
          <img
              src={blockThreeimages[buttonIndex]?.image_url || blockThreeimages[buttonIndex]}
            alt="Selected"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <button
            onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleLinkClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '3rem', right: '0',
              backgroundColor: blockThreeimages[buttonIndex]?.image_link ? 'green' : 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding:"6px",
              transform: 'translateY(-50%)'
            }}
          >
            <FiLink size="1rem" color="white" />
          </button>
          <button
         onClick={(e) => { e.preventDefault();e.stopPropagation(); handleDeleteClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '0', right: '0',
              backgroundColor: 'red',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding: "6px"
            }}
          >
            <CgClose size="1rem" color="white" />
          </button>
          <div
            onClick={(e) => { e.preventDefault(); handleUploadClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '4.3rem', right: '0',
              backgroundColor: 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding: "6px"
            }}
            >
              <MdFileUpload  size="1rem" color="white" />
          </div>
        </>
      )}
      {
        !blockThreeimages[buttonIndex]?.image_url && <button
          onClick={(e) => { e.preventDefault(); handleUploadClick(buttonIndex); }}
          style={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
        >
          <FiUpload size="1.5em" />
        </button>
      }
      {
        showModal3 && currentEditingImageIndex3 !== null && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-96">
              <button onClick={closeModal} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
                <CgClose size="1.5em" />
              </button>
              <div className="flex flex-col items-start space-y-4 font-poppins">
                <label className="flex flex-col w-full">
                  <span className="mb-2 text-lg"> Menu link URL for image {currentEditingImageIndex3 + 1}:</span>
                  <input
                    type="text"
                    name={`linkURL_${currentEditingImageIndex3}`}
                    value={menuLinkInput3}
                    placeholder='https://jpencil.com/gold-jewellery/womens/necklace'
                    className="p-2 border rounded-md focus:ring-1 focus:ring-indigo-500 focus:outline-none"
                    onChange={(e) => setMenuLinkInput3(e.target.value)}
                  />
                </label>
                <button 
                  onClick={handleModalSubmit}
                  className="mt-4  bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

const renderUploadButton4 = (buttonIndex) => {

  const handleDeleteClick = (index) => {
    IsSavedInputs();
    const newImages = [...blockFourimages];
    newImages[index] = { image_url: null, image_link: null };
    setBlockFourImages(newImages);
  };
  const handleLinkClick = (index) => {
    setCurrentEditingImageIndex4(index);  // set the current image being edited
    setMenuLinkInput4(blockFourimages[index]?.image_link || '');
    setShowModal4(true);
  };
  
  
  const closeModal = () => {
    setShowModal4(false);
  };
  
  const handleModalSubmit = (e) => {
    e.preventDefault();
    const newImages = [...blockFourimages];
    
    if(currentEditingImageIndex4 !== null) {
      const currentImage = newImages[currentEditingImageIndex4];
      
      if (typeof currentImage === 'string') {
        newImages[currentEditingImageIndex4] = {
          image_url: currentImage,
          image_link: menuLinkInput4
        };
      } else {
        currentImage.image_link = menuLinkInput4;
      }
    }
    
    setBlockFourImages(newImages);
    closeModal();
  };
  return (
    <div
      style={{
        position: 'relative', width: '100%', height: '100%',
      }}
    >
      {blockFourimages[buttonIndex] && (
        <>
          <img
             src={blockFourimages[buttonIndex]?.image_url || blockFourimages[buttonIndex]}
            alt="Selected"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
         <button
            onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleLinkClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '3.5rem', right: '0',
              backgroundColor: blockFourimages[buttonIndex]?.image_link ? 'green' : 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding:"6px",
              transform: 'translateY(-50%)'
            }}
          >
            <FiLink size="1.5em" color="white" />
          </button>
          <button
         onClick={(e) => { e.preventDefault();e.stopPropagation(); handleDeleteClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '0', right: '0',
              backgroundColor: 'red',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding: "6px"
            }}
          >
            <CgClose size="1.5em" color="white" />
          </button>
          <div
            onClick={(e) => { e.preventDefault(); handleUploadClick(buttonIndex); }}
            style={{
              position: 'absolute', top: '4.8rem', right: '0',
              backgroundColor: 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              border: 'none',
              padding: "6px"
            }}
            >
              <MdFileUpload  size="1.5em" color="white" />
          </div>
        </>
      )}
      {
        !blockFourimages[buttonIndex]?.image_url && <button
          onClick={(e) => { e.preventDefault(); handleUploadClick(buttonIndex); }}
          style={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
        >
          <FiUpload size="1.5em" />
        </button>
      }
      {
        showModal4 && currentEditingImageIndex4 !== null && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-96">
              <button onClick={closeModal} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
                <CgClose size="1.5em" />
              </button>
              <div className="flex flex-col items-start space-y-4 font-poppins">
                <label className="flex flex-col w-full">
                  <span className="mb-2 text-lg"> Menu link URL for image {currentEditingImageIndex4 + 1}:</span>
                  <input
                    type="text"
                    name={`linkURL_${currentEditingImageIndex4}`}
                    value={menuLinkInput4}
                    className="p-2 border rounded-md focus:ring-1 focus:ring-indigo-500 focus:outline-none"
                    placeholder='https://jpencil.com/gold-jewellery/womens/necklace'
                    onChange={(e) => setMenuLinkInput4(e.target.value)}
                  />
                </label>
                <button 
                  onClick={handleModalSubmit}
                  className="mt-4  bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

  const handleBlockClick = (blockIndex) => {
      setActiveBlock(blockIndex);
    return;
    
  };
  const handleImageSelect2 = (image) => {
    IsSavedInputs();
    setBlockTwoImages((prevImages) => {
      const updatedImages = [...prevImages];
       updatedImages[selectedButton] = { image_url: image, image_link: '' };
      return updatedImages;
    });
    setModalIsOpen(false);
  };
  const handleImageSelect1 = (image) => {
    IsSavedInputs();
   
    setBlockOneImages((prevImages) => {
      const updatedImages = [...prevImages];
       updatedImages[selectedButton] = { image_url: image, image_link: '' };
      return updatedImages;
    });
    setModalIsOpen(false);
  };
  const handleImageSelect3 = (image) => {
    IsSavedInputs();
    setBlockThreeImages((prevImages) => {
      const updatedImages = [...prevImages];
       updatedImages[selectedButton] = { image_url: image, image_link: '' };
      return updatedImages;
    });
    setModalIsOpen(false);
  };
  const handleImageSelect4 = (image) => {
    IsSavedInputs();
    setBlockFourImages((prevImages) => {
      const updatedImages = [...prevImages];
       updatedImages[selectedButton] = { image_url: image, image_link: '' };
      return updatedImages;
    });
    setModalIsOpen(false);
  };
// New function to fetch banner data and set form fields
const fetchCollageData = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/collage/${widgetId}`
    );
    setLoading(true);
    setFormFields(response.data[0]);
  } catch (error) {
    setLoading(true);
    console.error(error);
  }
};

const setFormFields = (data) => {
  const displayType = data.is_full_width === 1 ? 'fullWidth' : 'boxWidth';

  setFormValues({
    widgetNameInput: data.widget_name,
    widgetTittleInput : data.widget_title,
    widgetTypeInput: data.widget_type,
    displayType: displayType
  });

  setActiveBlock(data.collage_structure);
  setSelectedActiveBlock(data.collage_structure);
  setCollageId(data.collage_id);

  const isImagesPresent = data.collage_image && data.collage_image.length !== 0;
  setDataSubmitted(isImagesPresent);

  switch (data.collage_structure) {
    case 0:
      setBlockOneImages(data.collage_image);
      break;
    case 1:
      setBlockTwoImages(data.collage_image);
      break;
    case 2:
      setBlockThreeImages(data.collage_image);
      break;
    case 3:
      setBlockFourImages(data.collage_image);
      break;
    default:
      console.error("Invalid activeBlock");
      return;
  }
};

useEffect(() => {
  fetchCollageData();

}, [refresh]);

  const handleChange = (e) => {
    IsSavedInputs();
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    // setHasInteracted(true);
  };
  const validateImages = () => {
    return { isValid: true };
  };
  
  
  
  
  const getActiveImages = () => {
    switch (activeBlock) {
      case 0: return blockOneimages;
      case 1: return blockTwoimages;
      case 2: return blockThreeimages;
      case 3: return blockFourimages;
      default: return [];
    }
  };
  
  const isFormValids = () => {
    const filteredFormValues = Object.entries(formValues)
      .filter(([key]) => key !== "widgetTittleInput")
      .map(([value]) => value);

    const hasFormValues = filteredFormValues.every(
      (value) => value.trim() !== ""
    );
    const activeImages = getActiveImages();
  
    if (!hasFormValues) {
      return { isValid: false, error: 'Please fill out the required form values.(*)' };
    }
  
    const imageValidation = validateImages(activeImages);
    if (!imageValidation.isValid) {
      return imageValidation;
    }
  
    return { isValid: true };
  };
  
  const validation = isFormValids();
useEffect(() => {
  isFormValids();
}, [blockOneimages, blockTwoimages, blockThreeimages, blockFourimages]);


const handleFormSubmit = (e) => {
  e.preventDefault();

  try{
    let imageUrl;

    // Select the appropriate image URLs based on the activeBlock
    switch (activeBlock) {
      case 0:
      // For activeBlock 0 (i.e., blockOneimages)
      imageUrl = blockOneimages.map(imgObj => ({ 
        image_url: imgObj.image_url, 
        image_link: imgObj.image_link 
      })).filter(image => image.image_url !== null);

        break;
      case 1:
        imageUrl = blockTwoimages.map(imgObj => ({ 
          image_url: imgObj.image_url, 
          image_link: imgObj.image_link 
        })).filter(image => image.image_url !== null);
        break;
      case 2:
        imageUrl = blockThreeimages.map(imgObj => ({ 
          image_url: imgObj.image_url, 
          image_link: imgObj.image_link 
        })).filter(image => image.image_url !== null);
        break;
      case 3:
        imageUrl = blockFourimages.map(imgObj => ({ 
          image_url: imgObj.image_url, 
          image_link: imgObj.image_link 
        })).filter(image => image.image_url !== null);
        break;
      default:
        console.error("Invalid activeBlock");
        return;
    }
  
    // Construct the payload
    const payload = {
      collage_image: imageUrl,
      collage_structure: activeBlock,
      widget_name: formValues.widgetNameInput,
      widget_title: formValues.widgetTittleInput,
      is_full_width: formValues.displayType === 'fullWidth' ? 1 : 0,
      is_box_width: formValues.displayType === 'boxWidth' ? 1 : 0
    };
    
   // Make the API call
   axios.patch(`${REACT_APP_BASE_URL}/collage/${widgetId}`, payload).then(()=>{
    fetchWidgetOrder();
    fetchCollageData();
   
   })

     Swal.fire({
       icon: "success",
       title: "Data sent successfully.",
       showConfirmButton: false,
       timer: 1500,
     });
   
     setUnsavedChanges(false);
     // Handle successful submission as needes
  }
  catch{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
  }finally {
    setIsFormSubmitted(false); // Set isFormSubmitted back to false when form submission is complete
  }
 
};

useEffect(() => {
  if (collageId !== null) { // Check if collageId is set
    switch (activeBlock) {

      case null:
        setBlockOneImages(Array(1).fill(null));
        setBlockTwoImages(Array(4).fill(null));
        setBlockThreeImages(Array(5).fill(null));
        setBlockFourImages(Array(3).fill(null));
        break;
      case 0:
        setBlockTwoImages(Array(4).fill(null));
        setBlockThreeImages(Array(5).fill(null));
        setBlockFourImages(Array(3).fill(null));
        break;
      case 1:
        setBlockOneImages(Array(1).fill(null));
        setBlockThreeImages(Array(5).fill(null));
        setBlockFourImages(Array(3).fill(null));
        break;
      case 2:
        setBlockOneImages(Array(1).fill(null));
        setBlockTwoImages(Array(4).fill(null));
        setBlockFourImages(Array(3).fill(null));
        break;
      case 3:
        setBlockOneImages(Array(1).fill(null));
        setBlockTwoImages(Array(4).fill(null));
        setBlockThreeImages(Array(5).fill(null));
        break;
      default:
        console.error("Invalid activeBlock");
    }
  }
}, [collageId]); // This hook will run whenever collageId changes
// useEffect(() => {
//   // Nullify all images
//   setBlockOneImages(Array(1).fill(null));
//   setBlockTwoImages(Array(4).fill(null));
//   setBlockThreeImages(Array(5).fill(null));
//   setBlockFourImages(Array(3).fill(null));
  
// }, [collageId]); // Re-run this effect when the collageId changes
  return (
    <>
    {!loading ? <Skeleton/> :   <div>
      <h1 className="text-lg font-poppins text-gray-600 text-center">Collage Customization</h1>
      <form className="space-y-4 pt-3">
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label htmlFor="widgetNameInput" className="block text-sm font-medium text-gray-700">Widget Name*</label>
            <input
              id="widgetNameInput"
              name="widgetNameInput"
              type="text"
              placeholder={formValues.widgetNameInput}
              value={formValues.widgetNameInput}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-1/2 ">
            <label
              htmlFor="widgetTittleInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Title
            </label>
            <input
              id="widgetTittleInput"
              name="widgetTittleInput"
              type="text"
              placeholder={formValues.widgetTittleInput}
              value={formValues.widgetTittleInput}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="widgetTypeInput" className="block text-sm font-medium text-gray-700">Widget Type</label>
            <input
              id="widgetTypeInput"
              name="widgetTypeInput"
              type="text"
              disabled
              placeholder={formValues.widgetTypeInput}
              value={formValues.widgetTypeInput}
              className="mt-1 focus:ring-indigo-500 bg-gray-200 opacity-50 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="mt-4">
  <span className="block text-sm font-medium text-gray-700">Display Type</span>
  <div className="flex mt-2 gap-3">
    <label className="flex items-center w-1/2 px-3 py-2 bg-gray-100 rounded-lg cursor-pointer">
      <input
        type="radio"
        name="displayType"
        value="fullWidth"
        checked={formValues.displayType === 'fullWidth'}
        onChange={handleRadioChange}
        className="form-radio h-5 w-5 text-indigo-600"
      />
      <span className="ml-2">Full Width</span>
    </label>
    <label className="flex items-center w-1/2 px-3 py-2 bg-gray-100 rounded-lg cursor-pointer">
      <input
        type="radio"
        name="displayType"
        value="boxWidth"
        checked={formValues.displayType === 'boxWidth'}
        onChange={handleRadioChange}
        className="form-radio h-5 w-5 text-indigo-600"
      />
      <span className="ml-2">Box Width</span>
    </label>
  </div>
</div>

        <PreviewCollage 
        activeBlock={activeBlock}
        blockButtonsMapping={blockButtonsMapping}
        blockOneimages={blockOneimages}
        blockTwoimages={blockTwoimages}
        blockThreeimages={blockThreeimages}
        blockFourimages={blockFourimages}
        SelectedactiveBlock={SelectedactiveBlock}
      
        />
        <div>
      {/* Block - 1 Top part */}
      <h1 className="text-lg font-poppins text-center text-gray-600">Select Collage Structure</h1>
      <div className="mt-4 block lg:flex gap-4">
        {/* Left Side */}
        <div
          onClick={() => handleBlockClick(0)}
          className={`w-full lg:w-1/2 p-4 bg-gray-200 rounded-lg ${activeBlock === 0 ? 'border-red-500' : ''} border-2`}>
          <div className="h-40 border-2 rounded-lg border-indigo-500"></div>
        </div>
        {/* Block - 2 Right Side */}
        <div
          onClick={() => handleBlockClick(1)}
          className={`w-full lg:w-1/2 p-4 bg-gray-200 rounded-lg flex ${activeBlock === 1 ? 'border-red-500' : ''} border-2`}>
          <div className="w-2/3 h-40 border-2 rounded-lg border-indigo-500"></div>
          <div className="w-1/3 flex flex-col justify-between ml-2">
            <div className="h-12 border-2 rounded-sm border-indigo-500 mb-1"></div>
            <div className="h-12 border-2 rounded-sm border-indigo-500 mb-1"></div>
            <div className="h-12 border-2 rounded-sm border-indigo-500"></div>
          </div>
        </div>
      </div>
      {/* Bottom Part */}
      <div className="mt-6 flex gap-4">
        {/* Block - 3  - Bottom */}
        <div
          onClick={() => handleBlockClick(2)}
          className={`w-1/2 p-4 bg-gray-200 rounded-lg flex gap-3 ${activeBlock === 2 ? 'border-red-500' : ''} border-2`}>
          <div className="w-2/3 h-45 rounded-sm border-2 border-indigo-500"></div>
          <div className="w-1/3 flex flex-col justify-between gap-3">
            <div className="h-12 border-2 rounded-sm border-indigo-500 mb-1"></div>
            <div className="h-12 border-2 rounded-sm border-indigo-500 mb-1"></div>
          </div>
          <div className="w-1/3 flex flex-col justify-between gap-3">
            <div className="h-12 border-2 rounded-sm border-indigo-500 mb-1"></div>
            <div className="h-12 border-2 rounded-sm border-indigo-500 mb-1"></div>
          </div>
        </div>
        {/* Block - 4 - Bottom */}
        <div
          onClick={() => handleBlockClick(3)}
          className={`w-1/2 p-4 bg-gray-200 rounded-lg flex gap-4 justify-between ${activeBlock === 3 ? 'border-red-500' : ''} border-2`}>
          <div className="w-1/3 h-38 border-2 rounded-sm border-indigo-500 mb-1"></div>
          <div className="w-1/3 h-38 border-2 rounded-sm border-indigo-500 mb-1"></div>
          <div className="w-1/3 h-38 border-2 rounded-sm border-indigo-500 mb-1"></div>
        </div>
      </div>
    </div>
    
    <div className="mt-4">
  {SelectedactiveBlock !== null ? <h2 className="text-md font-poppins text-center text-gray-600 mb-3">Selected Block Customization</h2> :false}
  {activeBlock === 0 && (
        <div className={`w-full p-4 bg-gray-200 rounded-lg border-2`}>
          <div className="w-full h-60 border-2 rounded-lg border-indigo-500 relative">
            {renderUploadButton1(blockButtonsMapping[1][0])}
          </div>
          <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="modal"   style={customModalStyles} overlayClassName="overlay">
            <Gallery onImageSelect={handleImageSelect1} dimension='36:13' closeModal={() => setModalIsOpen(false)}/>
          </Modal>
        </div>
      )}

  {activeBlock === 1 && (
   <div className={`w-full p-4 bg-gray-200 rounded-lg flex border-2`}>
   <div className="w-2/3 h-74 border-2 rounded-lg border-indigo-500 relative">
   {renderUploadButton2(blockButtonsMapping[0][0])}
   </div>
   <div className="w-1/3 flex flex-col justify-between ml-2">
     <div className="h-24 border-2 rounded-sm border-indigo-500 mb-1 relative">
     {renderUploadButton2(blockButtonsMapping[0][1])}
     </div>
     <div className="h-24 border-2 rounded-sm border-indigo-500 mb-1 relative">
     {renderUploadButton2(blockButtonsMapping[0][2])}
     </div>
     <div className="h-24 border-2 rounded-sm border-indigo-500 relative">
     {renderUploadButton2(blockButtonsMapping[0][3])}
     </div>
     <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="modal"   style={customModalStyles}  overlayClassName="overlay">
      <Gallery onImageSelect={handleImageSelect2} dimension={reqDimension} closeModal={() => setModalIsOpen(false)}/>
    </Modal>
   </div>
 </div>
 
   
   
  )}
{activeBlock === 2 && (
  <div className={`w-full p-4 bg-gray-200 rounded-lg flex gap-3 border-2`}>
    <div className="w-2/4 h-60 border-2 rounded-sm border-indigo-500 relative">
      {renderUploadButton3(blockButtonsMapping[2][0])}
    </div>
    <div className="w-2/4 flex flex-col justify-between gap-3">
      <div className='flex row gap-3'>
        <div  className="w-2/4 h-28 border-2 rounded-sm border-indigo-500 mb-1 relative">
          {renderUploadButton3(blockButtonsMapping[2][1])}
        </div>
        <div  className="w-2/4 h-28 border-2 rounded-sm border-indigo-500 mb-1 relative">
          {renderUploadButton3(blockButtonsMapping[2][2])}
        </div>
      </div>
      <div className='flex row gap-3'>
        <div  className="w-2/4 h-28 border-2 rounded-sm border-indigo-500 mb-1 relative">
          {renderUploadButton3(blockButtonsMapping[2][3])}
        </div>
        <div  className="w-2/4 h-28 border-2 rounded-sm border-indigo-500 mb-1 relative">
          {renderUploadButton3(blockButtonsMapping[2][4])}
        </div>
      </div>
    </div>
    <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="modal"   style={customModalStyles}  overlayClassName="overlay">
      <Gallery onImageSelect={handleImageSelect3} dimension="4:3" closeModal={() => setModalIsOpen(false)}/>
    </Modal>
  </div>
)}
{activeBlock === 3 && (
  <div className={`w-full  p-4 bg-gray-200 rounded-lg flex gap-4 justify-between border-2`}>
   
      <div  className="w-1/3 h-60 border-2 rounded-sm border-indigo-500 mb-1 relative">
      {renderUploadButton4(blockButtonsMapping[3][0])}
      </div>
      <div  className="w-1/3 h-60 border-2 rounded-sm border-indigo-500 mb-1 relative">
      {renderUploadButton4(blockButtonsMapping[3][1])}
      </div>
      <div  className="w-1/3 h-60 border-2 rounded-sm border-indigo-500 mb-1 relative">
      {renderUploadButton4(blockButtonsMapping[3][2])}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="modal"   style={customModalStyles}  overlayClassName="overlay">
      <Gallery onImageSelect={handleImageSelect4}  dimension="1:1" closeModal={() => setModalIsOpen(false)}/>
    </Modal>
  </div>
)}

</div>
<div>
{!validation.isValid && (
      <span className="font-poppins text-red-500">
        {validation.error}
      </span>
    )}
</div>
<button
      onClick={handleFormSubmit}
      disabled={!validation.isValid}
      className={
        !validation.isValid 
          ? "text-white font-bold py-2 px-4 rounded-md opacity-50 bg-blue-500"
          : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
      }
    >
      {DataSubmitted ? "Update":"Save"}
    </button> 
      </form>
    </div>}
 
    </>
  );
};

export default CollageCustomization;

