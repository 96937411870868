import { useState, useRef, useEffect, React } from "react";
import { Tab } from "@headlessui/react";
import "cropperjs/dist/cropper.css";
import Swal from "sweetalert2";
import { AiOutlineClose } from "react-icons/ai";
import "./style.css";
// import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useToken } from "../Context/TokenContext";
import useTokenFromURL from "../Context/useTokenFromURL";
import useAxios from "../Axios/useAxios";
// Import BASE_URL from config.js
import { BASE_URL } from "../config";
import ImageCropper2 from "./ImageCropper2";
import { IoMdCloseCircle } from "react-icons/io";
import LazyImage from "./LazyImage";
import noDataFound from "../assets/NoDataImage/NoDataImage.png";

//  Gallery component for displaying and managing user's own photos, public photos, and wishlist
//  Features:
//  1. Display public photos fetched from API
//  2. Display own photos fetched from API
//  3. Upload new own photos with aspect ratio selection and image cropping
//  4. Add/remove photos to/from wishlist
//  5. Delete own photos with confirmation prompt
//  6. Shimmer loading effect for own and public photos
//  7. Display upload progress bar for new own photos

function Gallery({ onImageSelect, dimension = "", closeModal, fromTabs }) {
  const [activeTab, setActiveTab] = useState("own");
  const [publicPhotos, setPublicPhotos] = useState([]);
  const [selectedAspectRatioForGallery, setSelectedAspectRatioForGallery] =
    useState("1:1");
  let purposeFor = ""
  const [uploadedImage] = useState(null);
  const [wishlist, setWishlist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  // const imageElement = useRef(null);
  const cropperRef = useRef(null);
  const [ownPhotos, setOwnPhotos] = useState([]);
  const { jboId } = useToken();
  const [imgURL, setImgURL] = useState();
  const [filterByRatio, setFilterByRatio] = useState(dimension);
  const [publicFilterByRatio, setPublicFilterByRatio] = useState(dimension);
  const [refresh, setRefresh] = useState(true);
  // const [fileName, setFileName] = useState('');
  const [loader, setLoader] = useState(false);

  useTokenFromURL();
  useAxios();

  useEffect(() => {
    //  console.log('use effect called');
  }, [refresh, selectedAspectRatioForGallery]);

  const handleImageClick = (imageUrl) => {
    if (fromTabs) {
      return false;
    }
    setRefresh(!refresh);
    if (dimension == "gif") {
      onImageSelect(imageUrl);
    } else {
      setImgURL(imageUrl);
    }
    cropperRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCloseModal = () => {
    closeModal(); // Call the closeModal function to close the modal
    document.body.style.overflow = "auto";
  };

  const handleCropData = async (data, type) => {
    const croppedImageDataURL = data;
    const aspectRatio = dimension;
    await sendImageToBackend(
      croppedImageDataURL,
      jboId,
      aspectRatio,
      type,
      purposeFor
    );
  };

  const handleFilterByRatio = async (data, currentTab) => {
    if (currentTab == "public") {
      setPublicFilterByRatio(data);
    } else if (currentTab == "own") {
      setFilterByRatio(data);
    }
  };
  // Function to fetch public photos from API
  const fetchPublicPhotos = async (dimension) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${BASE_URL}/gallery-uploads?type=2&dimension=${dimension}`
      ); // Replace with the actual API URL for public photos
      const fetchedPhotos = response.data.map((photo, index) => ({
        id: photo.uploads_id,
        imageUrl: photo.url,
        isLiked: photo.is_liked,
      }));
      setPublicPhotos(fetchedPhotos);
    } catch (err) {
      setError(err.message);
    }
    setIsLoading(false);
  };

  // useEffect to fetch public photos when activeTab changes
  useEffect(() => {
    if (activeTab === "public") {
      fetchPublicPhotos(publicFilterByRatio);
    }
  }, [activeTab, publicFilterByRatio, refresh]);

  useEffect(() => {
    fetchOwnPhotos(filterByRatio);
  }, [filterByRatio]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dimension]);

  // useEffect(() =>{
  //   fetchPublicPhotos(publicFilterByRatio);
  // }, [publicFilterByRatio]);

  // Progress Bar component
  const ProgressBar = ({ progress }) => (
    <div className="w-full h-8 bg-gray-200 rounded-lg relative">
      <div
        className="h-full bg-gradient-to-r from-blue-500 mt-[16px]  via-purple-500 to-red-500 w-full transition-all duration-300 ease-in-out"
        style={{ width: `${progress}%` }}
      ></div>
      <span className="text-sm absolute top-0 left-0 w-full font-poppins h-full flex items-center justify-center ">
        {progress}%
      </span>
    </div>
  );

  // Function to fetch own photos from API
  const fetchOwnPhotos = async (dimension) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${BASE_URL}/gallery-uploads?type=1&dimension=${dimension}`
      ); // Replace with the actual API URL
      const fetchedPhotos = response.data.map((photo, index) => ({
        id: photo.uploads_id,
        imageUrl: photo.url,
        isLiked: photo.is_liked,
      }));
      setOwnPhotos(fetchedPhotos);
    } catch (err) {
      setError(err.message);
    }
    setIsLoading(false);
  };
  const fetchWishlistData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/gallery-uploads/likes?dimension=${dimension}` // Replace with the actual API URL for fetching wishlist data
      );
      const wishListPhotos = response.data.map((photo, index) => ({
        id: photo.uploads_id,
        imageUrl: photo.url,
        isLiked: photo.is_liked,
      }));
      setWishlist(wishListPhotos);

      // setWishlist([...wishlist, photo]);
    } catch (err) {
      console.error("Error fetching wishlist data:", err);
    }
  };

  //below fetchWishlistData() is commented out for api fail
  // useEffect to fetch own photos when the component mounts
  // useEffect(() => {
  //   fetchWishlistData();
  // }, []);

  // useEffect to handle the update after a new image is uploaded
  useEffect(() => {
    if (uploadedImage) {
      fetchOwnPhotos();
    }
  }, [uploadedImage]);

  // Function to send image to the backend
  const sendImageToBackend = async (
    croppedImage,
    jboId,
    aspectRatio,
    type,
    purposeFor
  ) => {
    try {
      const formData = new FormData();
      formData.append("uploadedImage", await dataUrlToBlob(croppedImage));
      formData.append("jbo_id", jboId);
      formData.append("dimension", selectedAspectRatioForGallery);
      formData.append("type", type);
      formData.append("purpose", purposeFor);
      // formData.append("type", 1);
      // const uploadedImageBlob = formData.get("uploadedImage");

      // const renamedBlob = new File([uploadedImageBlob], fileName, { type: uploadedImageBlob.type });

      // formData.set("uploadedImage", renamedBlob, fileName);

      const response = await axios.post(
        `${BASE_URL}/gallery-uploads`,
        formData,
        {
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      setLoader(false);
      fetchOwnPhotos(response.data.dimension);
      setUploadProgress(0);

      // Handle the response from the server
      if (type == 3) {
        onImageSelect(response.data.url);
      }
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Error response data: ", error.response.data);
        console.log("Error response status: ", error.response.status);
        console.log("Error response headers: ", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Error request instance: ", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error message: ", error.message);
      }
      console.error("Error sending image to backend:", error);
    }
  };

  // Convert dataUrl to Blob
  const dataUrlToBlob = async (dataUrl) => {
    const response = await fetch(dataUrl);
    const blob = await response.blob();
    const customTypeBlob = new Blob([blob], { type: "image/png" });
    return customTypeBlob;
  };

  // const onDrop = useCallback((acceptedFiles) => {
  //   handleImageUpload({ target: { files: acceptedFiles } });
  // }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  // Function to add photo to wishlist
  // const addToWishlist = async (photo) => {
  //   try {
  //     await axios.post(
  //       `${BASE_URL}/gallery-wishlist`, // Replace with the actual API URL for adding to wishlist
  //       { gallery_uploads_id: photo.id, is_liked: 1 }
  //     );
  //     fetchWishlistData();
  //     fetchOwnPhotos();
  //     fetchPublicPhotos();
  //   } catch (err) {
  //     console.error("Error adding photo to wishlist:", err);
  //     // Handle error case accordingly
  //   }
  // };
  // const removeFromWishlist = async (photoId) => {
  //   try {
  //     await axios.delete(
  //       `${BASE_URL}/gallery-wishlist/?gallery_uploads_id=${photoId}`
  //     );
  //     fetchWishlistData();
  //     fetchOwnPhotos();
  //     fetchPublicPhotos();
  //   } catch (err) {
  //     console.error("Error removing photo from wishlist:", err);
  //     // Handle error case accordingly
  //   }
  // };
  // const toggleWishlist = (photo) => {
  //   if (photo.isLiked === 0) {
  //     addToWishlist(photo);
  //   } else {
  //     removeFromWishlist(photo.id);
  //   }
  // };

  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setUploadedImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  // Shimmer effect component
  const Shimmer = () => (
    <div className="w-60 h-60 bg-gray-100 shimmer rounded-md mt-4"></div>
  );

  const confirmDelete = async (photoId, uploads_id, filterByRatio) => {
    // window.location.reload();
    Swal.fire({
      title: "Are you sure you want to delete this image?",
      // text: "Did u want to delete this session",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_URL}/gallery-uploads/${uploads_id}`);
          // setDeletedPhotoIds([...deletedPhotoIds, photoId]); // Replace with the actual API URL
          // setOwnPhotos(ownPhotos.filter((photo) => photo.id !== photoId)); // Update ownPhotos state
          Swal.fire({
            title: "Deleted!",
            text: "Image has been deleted successfully.",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            timer: 2000, // Auto-close after 2000ms (2 seconds)
            timerProgressBar: true,
          });
          fetchOwnPhotos(filterByRatio);
          fetchWishlistData();
        } catch (err) {
          console.error("Error deleting own photo:", err);
          Swal.fire("Error", "There was an error deleting the image.", "error");
        }
      } else Swal.fire("Cancelled", " Image is safe ", "success");
    });
  };

  // const dataURLToFile = (dataURL) => {
  //   const byteString = atob(dataURL.split(",")[1]);
  //   const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const ia = new Uint8Array(arrayBuffer);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   return new Blob([arrayBuffer], { type: mimeString });
  // };

  const tabs = [
    { id: "own", name: "Own photos" },
    { id: "public", name: "Public photos" },
    // { id: "wishlist", name: "Liked Photos" },
  ];

  // const handleOwnPhotoUpload = (e) => {
  //   const files = Array.from(e.target.files);
  //   const newPhotos = files.map((file) => ({
  //     id: ownPhotos.length + 1,
  //     imageUrl: URL.createObjectURL(file),
  //   }));
  //   setOwnPhotos([...ownPhotos, ...newPhotos]);
  // };

  const renderPhotos = (photos, deletable = false) => {
    if (isLoading) {
      return (
        <div className="grid grid-cols-4 gap-4">
          {Array.from({ length: ownPhotos.length }).map((_, index) => (
            <Shimmer key={index} />
          ))}
        </div>
      );
    }
    if (error) {
      return <div>Error: {error}</div>;
    }
    // Check if the photos array is empty and display a "No Image Found" message
    if (ownPhotos.length === 0 && activeTab == "own") {
      return (
        <div className="flex flex-col items-center justify-center py-10">
          <img src={noDataFound} alt="noimage" />
          <div className="text-center text-lg font-poppins font-semibold text-gray-700 pt-4">
            No Image Found. Add Images
          </div>
        </div>
      );
    }

    //All image rendering
    return (
      <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-6 rounded-md">
          {photos.map((photo, index) => (
            <div key={photo.id} className="relative photo-card">
              {/* <img
                src={photo.imageUrl}
                alt=""
                onLoad={(e) => e.target.classList.remove("shimmer")}
                loading="lazy"
                onClick={() => handleImageClick(photo.imageUrl)}
                className="shimmer w-full h-auto object-cover rounded-lg shadow-md transition duration-200 ease-in-out transform hover:scale-105"
              /> */}
              <LazyImage
                key={index}
                src={photo.imageUrl}
                alt={""}
                handleImageClick={handleImageClick}
              />

              {deletable && (
                <button
                  onClick={() =>
                    confirmDelete(photo.id, photo.id, filterByRatio)
                  }
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2"
                >
                  <AiOutlineClose className="h-5 w-5" />
                </button>
              )}

              {/* <button
                  onClick={() => toggleWishlist(photo)}
                  className="absolute top-2 left-2 bg-red-500 text-white rounded-full p-2"
                >
                  {photo.isLiked === 1 || photo.isLiked === "1" ? (
                    <AiFillHeart className="h-5 w-5" />
                  ) : (
                    <AiOutlineHeart className="h-5 w-5" />
                  )}
                </button> */}
            </div>
          ))}
        </div>
        {activeTab === "public" && publicPhotos.length === 0 && (
          <>
            <div className="flex flex-col items-center justify-center py-10">
              <img src={noDataFound} alt="noimage" />
              <div className="text-center text-lg font-poppins font-semibold text-gray-700 pt-4">
                No Image Found. Add Images
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {!fromTabs && (
        <div className="flex justify-end">
          <IoMdCloseCircle
            onClick={handleCloseModal}
            className="cursor-pointer"
            size={30}
          />
        </div>
      )}

      <div className="flex flex-col md:flex-row">
        <div
          className=" w-full md:w-[18%] rounded-lg sticky top-0 p-4  bg-gradient-to-br from-blue-500 via-purple-500 to-red-500"
          style={{ maxHeight: "500px" }}
        >
          <h1 className="font-bold text-xl mb-4 font-poppins text-white">
            Gallery
          </h1>
          <Tab.Group className="flex flex-col gap-3">
            <Tab.List className="space-y-2">
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  className={`${
                    activeTab === tab.id
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-600"
                  } px-4 py-2 rounded-lg mr-2 cursor-pointer font-poppins flex items-center justify-between`}
                  onClick={() => {
                    setImgURL(null);
                    setActiveTab(tab.id);
                    setRefresh(!refresh);
                  }}
                >
                  {tab.name}
                  {tab.id === "wishlist" && (
                    <span className="inline-flex items-center justify-center w-5 h-5 bg-red-500 text-white text-xs font-medium rounded-full ml-2">
                      {wishlist.length}
                    </span>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>
        <div className="w-full md:w-3/4 p-4" ref={cropperRef}>
          {fromTabs && (
            <div className="font-poppins text-xl font-medium pb-6 ">
              Upload Your Own photos Here
            </div>
          )}
          <ImageCropper2
            imgURL={imgURL}
            dimension={dimension}
            handleCropData={handleCropData}
            onFilterByRatio={handleFilterByRatio}
            activeTab={activeTab}
            refresh={refresh}
            fromTabs={fromTabs}
            // setFileName={setFileName}
            setLoader={setLoader}
            loader={loader}
            setSelectedAspectRatioForGallery={setSelectedAspectRatioForGallery}
          />

          {activeTab === "public"
            ? renderPhotos(publicPhotos)
            : renderPhotos(ownPhotos, true)}
          {activeTab === "wishlist" && (
            <div>
              <div className="flex justify-center items-center py-5">
                <span className="text-2xl font-medium font-poppins ">
                  Wishlist
                </span>
              </div>
              {renderPhotos(wishlist)}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Gallery;

