import React from "react";
import logo from "../../../images/logo.webp";
import artbanner from "../../../images/art_banner.webp";
import art1 from "../../../images/s_artdeco1.webp";
import art2 from "../../../images/s_artdeco2.webp";
import art3 from "../../../images/s_artdeco3.webp";
import art4 from "../../../images/s_artdeco4.webp";
import art5 from "../../../images/s_artdeco5.webp";
import art6 from "../../../images/s_artdeco6.webp";
import art7 from "../../../images/s_artdeco7.webp";
import art8 from "../../../images/s_artdeco8.webp";
import art9 from "../../../images/s_artdeco9.webp";
import art10 from "../../../images/s_artdeco10.webp";
import art11 from "../../../images/s_artdeco11.webp";
import art12 from "../../../images/s_artdeco12.webp";
import art13 from "../../../images/s_artdeco13.webp";
import art14 from "../../../images/s_artdeco14.webp";
import art15 from "../../../images/s_artdeco15.webp";
import art16 from "../../../images/s_artdeco16.webp";
import art17 from "../../../images/s_artdeco17.webp";
import art18 from "../../../images/s_artdeco18.webp";
import art19 from "../../../images/s_artdeco19.webp";
import { FaSearch } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaBars } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";

const ArtDeco = () => {
  return (
    <>
      <div className="bg-art_secondary py-2">
        <div className="container mx-auto grid grid-cols-2">
          <div className="text-lg font-semibold">Today's Metal Rate</div>
          <div className="flex justify-end items-center text-lg font-semibold">
            Plan Your Investment
          </div>
        </div>
      </div>

      <div className="container mx-auto flex items-center justify-between py-4">
        <div className="flex items-center">
          <img className="w-64 h-24" src={logo} alt="img" />
        </div>

        <div className="flex-1 px-4">
          <div className="relative flex items-center justify-center w-full">
            <input
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded-lg p-4 pr-12 w-2/3"
            />
            <FaSearch className="absolute right-40 mr-4 text-gray-400 bg-art_primary p-2 w-14 h-full rounded text-white" />
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex flex-col items-center mr-4">
            <IoBag className="text-[25px]" />
            <span className="text-md font-semibold">Cart</span>
          </div>

          <div className="flex flex-col items-center mr-4">
            <FaRegHeart className="text-[25px]" />
            <span className="text-md font-semibold">Wishlist</span>
          </div>

          <div className="flex flex-col items-center">
            <FaUser className="text-[25px]" />
            <span className="text-md font-semibold">Login</span>
          </div>
        </div>
      </div>

      <div className="bg-art_secondary">
        <div class="container mx-auto text-md flex items-center space-x-8">
          <div class="flex items-center">
            <h1 class="bg-art_primary text-white px-4 py-2 rounded flex items-center">
              <FaBars class="mr-2" />
              Categories
            </h1>
          </div>

          <ul class="list-none p-0 m-0 flex space-x-10 font-semibold">
            <li>Home</li>
            <li>About Us</li>
            <li>Contact Us</li>
            <li>Gold Plan</li>
          </ul>
        </div>
      </div>

      {/* End of Header Section */}
      <div className="">
        <img className="image" src={artbanner} alt="banner-image"></img>
      </div>

      {/* End of Banner Content */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Where passion molds matter into meaning
      </div>

      <div className=" container mx-auto grid grid-cols-2 gap-4">
        <img className="image" src={art1} alt="img"></img>
        <div className="grid grid-cols-2 grid-rows-2 gap-2">
          <img className="image" src={art2} alt="img"></img>
          <img className="image" src={art3} alt="img"></img>
          <img className="image" src={art4} alt="img"></img>
          <img className="image" src={art5} alt="img"></img>
        </div>
      </div>
      {/* End of section1 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Sculpture is the art of the hole and the lump
      </div>

      <div className=" container mx-auto grid grid-cols-5 gap-4">
        <div className="justify-self-center">
          <img className="image" src={art6} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">
            Dancing Bell Ganesha
          </p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={art7} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">
            Ambari Elephant
          </p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={art8} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">
            Horse Bracket
          </p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={art9} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">
            Center Table
          </p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={art10} alt="img"></img>
          <p className=" flex justify-center font-semibold  my-7"> Krishna </p>
        </div>
      </div>

      {/* End of section2 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Carving reality into dreams, one stroke at a time
      </div>

      <div className=" container mx-auto grid grid-cols-3 gap-4">
        <img className="image" src={art11} alt="img"></img>
        <img className="image" src={art12} alt="img"></img>
        <img className="image" src={art13} alt="img"></img>
      </div>

      {/* End of section3 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Sculpture is the art of giving form to imagination
      </div>

      <div className="flex justify-center">
        <img className="image" src={art14} alt="img"></img>
      </div>

      {/* End of section4 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Where passion molds matter into meaning
      </div>

      <div className=" container mx-auto grid grid-cols-2">
        <img className="image" src={art15} alt="img"></img>
        <div className="grid grid-cols-2 grid-rows-2 gap-2">
          <img className="image" src={art16} alt="img"></img>
          <img className="image" src={art17} alt="img"></img>
          <img className="image" src={art18} alt="img"></img>
          <img className="image" src={art19} alt="img"></img>
        </div>
      </div>

      {/* End of section5 */}

      <div className="bg-art_primary w-full mt-5">
        <div className="container mx-auto py-4">
          <div className="grid grid-cols-2 gap-8">
            <article className="text-white flex items-center space-x-4">
              <div className="flex items-center justify-center border border-slate-50 rounded-full bg-slate-50 w-10 h-10">
                <FaBell className="text-black" />
              </div>
              <div>
                <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                <p>
                  Get all the latest information on Events, Sales and Offers.
                </p>
              </div>
            </article>

            <form className="flex items-center">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full px-3 py-2 border rounded-lg mr-2"
              ></input>
              <button
                type="submit"
                className="bg-white text-black font-semibold px-4 py-2 rounded-lg"
              >
                <span>SUBSCRIBE</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-art_secondary grid grid-cols-3 gap-4 ">
        <div className="flex justify-center">
          <img className="image w-64 h-24  " src={logo} alt="img"></img>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">GET US HELP YOU</h2>
          <ul className="list-none p-0 m-0">
            <li className="py-2 ">Contact Us</li>
            <li className="py-2">Terms and Condition</li>
            <li className="py-2">FAQ</li>
          </ul>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">OUR POLICIES</h2>
          <ul className="list-none p-0 m-0 ">
            <li className="py-2 ">Privacy Policy</li>
            <li className="py-2">Shipping Policy</li>
            <li className="py-2 ">Cancellation Policy</li>
            <li className="py-2 ">Exchange Policy</li>
          </ul>
        </div>
      </div>

      <div className=" bg-art_primary ">
        <div className="container mx-auto text-white">
          ⓒ All rights reserved
        </div>
      </div>

      {/* End of footer section */}
    </>
  );
};

export default ArtDeco;
