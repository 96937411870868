import React, { useState } from "react";
import AuthAccordian from "./Auth";
import AboutUsAccordian from "./AboutUs";
import ContactUsAccordian from "./ContactUs";
import ProductsAccordian from "./Products";
import SEOAccordian from "./SEO";
import CustomAccordian from "./CustomPage";

/**
 * PagesCustomization Component
 *
 * This component is responsible for rendering various accordion sections
 * allowing users to customize different aspects of their website pages.
 *
 * @param {Function} setUnsavedChanges - Function to notify if there are unsaved changes.
 * @param {Function} setHasInteracted - Function to notify if a user has interacted with an input.
 */
const PagesCustomization = ({ setUnsavedChanges, setHasInteracted }) => {
  // State to manage the currently active accordion.
  const [activeAccordion, setActiveAccordion] = useState(null);

  /**
   * Toggles the accordion sections, allowing only one to be open at a time.
   *
   * @param {String} accordionName - Name of the accordion being toggled.
   */

  const handleToggleAccordion = (accordionName) => {
    if (activeAccordion === accordionName) {
      setActiveAccordion(null); // close the current accordion
    } else {
      setActiveAccordion(accordionName); // open the clicked accordion
    }
  };
  /**
   * Callback function to handle input changes in accordions.
   * It sets the indicators for unsaved changes and user interaction.
   */
  const IsSavedInputs = () => {
    setHasInteracted(true);
    setUnsavedChanges(true);
  };
  return (
    <div className="flex flex-col bg-white border-2 border-gray-200 m-3 shadow-lg rounded-lg items-center space-y-8 p-8">
      <h1 className="text-xl font-poppins text-gray-600 text-center">
        Pages Customization
      </h1>

      {/* Auth Accordian */}
      <div className="w-full max-w-5xl">
        <AuthAccordian
          onToggle={handleToggleAccordion}
          activeAccordion={activeAccordion}
          IsSavedInputs={IsSavedInputs}
        />
      </div>

      {/* About Us Accordian */}
      <div className="w-full max-w-5xl">
        <AboutUsAccordian
          onToggle={handleToggleAccordion}
          activeAccordion={activeAccordion}
          IsSavedInputs={IsSavedInputs}
        />
      </div>

      {/* Contact Us Accordian */}
      <div className="w-full max-w-5xl">
        <ContactUsAccordian
          onToggle={handleToggleAccordion}
          activeAccordion={activeAccordion}
          IsSavedInputs={IsSavedInputs}
        />
      </div>

      {/* Products Accordian */}
      <div className="w-full max-w-5xl">
        <ProductsAccordian
          onToggle={handleToggleAccordion}
          activeAccordion={activeAccordion}
          IsSavedInputs={IsSavedInputs}
        />
      </div>
      {/* SEO Accordian */}
      <div className="w-full max-w-5xl">
        <SEOAccordian
          onToggle={handleToggleAccordion}
          activeAccordion={activeAccordion}
          IsSavedInputs={IsSavedInputs}
        />
      </div>

      {/* Custom Accordian */}
      <div className="w-full max-w-5xl">
        <CustomAccordian
          onToggle={handleToggleAccordion}
          activeAccordion={activeAccordion}
          IsSavedInputs={IsSavedInputs}
        />
      </div>
    </div>
  );
};

export default PagesCustomization;
