import React from 'react'

const Skeleton = () => {
  return (
    <div className="animate-pulse">
    <h1 className="text-lg font-poppins text-center text-gray-600 bg-gray-300 h-6 w-3/4 mx-auto"></h1>
    <form className="space-y-4 pt-6">
        <div className="flex space-x-4">
            <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700 bg-gray-300 h-4 w-1/2"></label>
                <div className="mt-2 bg-gray-300 h-6 w-full rounded-md"></div>
                {/* <div className="mt-4 bg-gray-300 h-6 w-3/4 rounded-md"></div> For error message placeholder */}
            </div>
            <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700 bg-gray-300 h-4 w-1/2"></label>
                <div className="mt-2 bg-gray-300 h-6 w-full rounded-md"></div>
            </div>
        </div>
        <label className="font-medium font-poppins pt-3 text-gray-600 block bg-gray-300 h-5 w-1/3"></label>
        <div className="bg-gray-300 mt-3 h-64 w-full rounded-md"></div> {/* Placeholder for SunEditor */}
       
        <button className="bg-gray-300 mt-4 h-10 w-32 rounded-md self-center"></button>
    </form>
</div>

  )
}

export default Skeleton
