import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useAxios from "../../Axios/useAxios";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../config';
import { MdEmail, MdLocalPhone } from 'react-icons/md';
import { useParams } from 'react-router-dom';

const PaymentSuccess = () => {
  const [purchaseStatus, setPurchaseStatus] = useState(null); // To hold the purchase status
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const performPurchase = async (domainName) => {
      try {
        const response = await axios.post(`${BASE_URL}/domain/purchase`, { domain_name: domainName }, {
          headers: {
            Authorization: `Bearer ${id}`,
          },
        });
        if (response.data && response.data.status) {
          setPurchaseStatus(response.data); // Save the whole response object to state
        }
      } catch (error) {
        console.error('Purchase failed:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const savedDomainName = localStorage.getItem('Domain');
    if (savedDomainName) {
      performPurchase(savedDomainName);
    } else {
      console.error('No domain name found in local storage.');
      setIsLoading(false); // Update loading state if no domain name is found
    }
  }, [navigate]); // Depend only on 'navigate', as it doesn't change and the effect needs to run only once

  const handleBackToHome = () => {
    const urlWithToken = `/?token=${encodeURIComponent(id)}`;
    navigate(urlWithToken);
  };

  if (isLoading) {
    return (
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-xl text-center">
        {/* Loading content */}
        <div>
          <div className="h-48 bg-gray-200 rounded"></div>
          <h2 className="mt-5 mb-4 text-xl font-semibold text-gray-700">Payment is confirmed, waiting for approval...</h2>
          <p className="text-gray-600">Just a moment, we're finalizing everything for your purchased domain!</p>
        </div>
      </div>
    );
  }

  if (purchaseStatus && purchaseStatus.status === 'success') {
    // Render this content if the status is 'success'
    return (
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-xl">
        <div className="text-center">
          <img
            src="https://cdn.dribbble.com/users/614270/screenshots/14575431/media/4907a0869e9ed2ac4e2d1c2beaf9f012.gif"
            alt="Payment Success"
            className="mx-auto w-full h-auto"
            style={{ maxHeight: '480px' }}
          />
          <h2 className="text-2xl font-semibold text-indigo-700 mb-4">Payment Successful!</h2>
          <p className="text-gray-700">
            Your transaction for the domain <span className="font-semibold">{localStorage.getItem('Domain')}</span> has been processed successfully.
          </p>
          <p className="text-gray-600 mt-2 mb-4">
            Please wait for 24-48 hours for your domain to be set up. We appreciate your patience.
          </p>
          <button
            onClick={handleBackToHome}
            className="mt-4 bg-indigo-700 text-white font-semibold py-2 px-4 rounded-lg hover:bg-indigo-800"
          >
            Back to Home
          </button>
        </div>
      </div>
    );
  } else {
    // Default or error state
    return (
          <div className="flex h-screen items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-4xl mx-auto space-y-4">
        <h2 className="text-2xl font-bold text-red-600">Error in Domain Purchase Approval</h2>
        <p className="text-gray-700 text-lg">We encountered an issue while finalizing your domain purchase. Reach out to us for assistance:</p>
        <div className="space-y-3">
          <a href="mailto:aswani@jpencil.com" className="flex justify-center items-center text-red-500 hover:text-red-600 transition duration-300 ease-in-out">
            <MdEmail className="text-2xl" />
            <span className="text-lg font-medium text-gray-800 ml-2">aswani@jpencil.com</span>
          </a>
          <a href="tel:+914224510121" className="flex justify-center items-center text-red-500 hover:text-red-600 transition duration-300 ease-in-out">
            <MdLocalPhone className="text-2xl" />
            <span className="text-lg font-medium text-gray-800 ml-2">+91.4224510121</span>
          </a>
        </div>
        <button 
         onClick={handleBackToHome}
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Back to Home
        </button>
      </div>
    </div>


  



    );
  }
};

export default PaymentSuccess;
