import React, { useState, useEffect } from "react";
import BannerCustomization from "./Banner/BannerCustomization";
import CollageCustomization from "./Collage/CollageCustomization";
import InfoSliderCustomization from "./Information Slider/InformationSlider";
import SubscriptionCustomization from "./Subscription/Subscription";
import ProductSliderCustomization from "./ProductSlider/ProductSlider";
import CategorySliderCustomization from "./CategorySldier/CategorySlider";
import CmsBlockCustomization from "./CMSBlock/CmsBlock";
// Import BASE_URL from config.js
import { BASE_URL } from '../../config';

const WidgetDetails = ({
    activeWidget,
    clickedWidgetId,
    setToggleBanner,
    toggleBanner,
    refresh,
    setBannerDataSubmit,
    fetchWidgetOrder,
    widgetName,
    setActiveWidget,
    setHasInteracted,
    setUnsavedChanges,
    unsavedChanges,
    hasInteracted,
  }) => {
    const widgetType = activeWidget ? activeWidget.match(/^\D+/)[0].trim() : "";
    
    const renderContent = () => {
      if (!activeWidget) return null; // Add guard clause if activeWidget is not set
  
      switch (widgetType) {
        case "Banner":
          return toggleBanner ? (
            <BannerCustomization
              widgetType={widgetType}
              widgetName={widgetName}
              widgetId={clickedWidgetId}
              setToggleBanner={setToggleBanner}
              hasInteracted={hasInteracted}
              setHasInteracted={setHasInteracted}
              setBannerDataSubmit={setBannerDataSubmit}
              setUnsavedChanges={setUnsavedChanges}
              fetchWidgetOrder={fetchWidgetOrder}
            />
          ) : null;
        case "Collage":
          return (
            <CollageCustomization
              widgetType={widgetType}
              unsavedChanges={unsavedChanges}
              fetchWidgetOrder={fetchWidgetOrder}
              setUnsavedChanges={setUnsavedChanges}
              setHasInteracted={setHasInteracted}
              hasInteracted={hasInteracted}
              refresh={refresh}
              widgetId={clickedWidgetId}
            />
          );
        case "Info Slider":
          return(<InfoSliderCustomization widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}/>) 
        case "Product Slider":
          return (<ProductSliderCustomization widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}/>);
        case "Category Slider":
          return (<CategorySliderCustomization widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}/>);
        case "Subscription":
          return (<SubscriptionCustomization widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}/>);
        case "Cms Custom":
          return (<CmsBlockCustomization widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}/>);
        default:
          return (
            <BannerCustomization
              widgetType={widgetType}
              widgetName={widgetName}
            />
          );
      }
    };
  
    return (
      <div className="w-full  md:w-1/2 lg:w-full xl:w-full  border-gray-300 p-4 overflow-y-auto h-screen">
        {renderContent()}
      </div>
    );
  };

  export default WidgetDetails;