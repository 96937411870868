import React from 'react'

const Skeleton = () => {
  return (
    <div>
        <div className="p-4 space-y-6 animate-pulse">



{/* Preview skeleton (optional since it might not be visible during loading) */}
<div className="h-24 bg-gray-300 rounded shimmer"></div>

{/* Form skeleton */}
<div className="space-y-4 pt-6">
  <div className="flex space-x-4">
    <div className="w-1/2">
      <div className="h-4 bg-gray-300 rounded w-1/3 shimmer"></div>
      <div className="mt-2 h-6 bg-gray-300 rounded shimmer"></div>
    </div>
    <div className="w-1/2">
      <div className="h-4 bg-gray-300 rounded w-1/3 shimmer"></div>
      <div className="mt-2 h-6 bg-gray-300 rounded shimmer"></div>
    </div>
  </div>
</div>

{/* Accordian skeleton */}
<div className="mt-4 h-40 bg-gray-300 rounded shimmer"></div>

{/* Add Info Box button skeleton */}
<div className="flex items-center space-x-2 mt-4">
  <div className="h-6 w-6 bg-gray-300 rounded shimmer"></div>
  <div className="h-6 w-24 bg-gray-300 rounded shimmer"></div>
</div>

{/* Modals skeleton - not included since modals are usually hidden by default. */}



{/* Button skeleton */}
<div className="h-10 bg-gray-300 rounded w-1/4 mt-4 shimmer"></div>
</div>



    </div>
  )
}

export default Skeleton