import React from 'react'
const Skeleton = () => {
  return (
    <>
<div>
    <div className="shimmer   w-full h-8 my-4"></div>
    <form>
        <div className="flex space-x-4">
            <div className="w-1/2">
                <div className="shimmer   w-3/4 h-6 mb-2"></div>
                <div className="shimmer   w-full h-10"></div>
            </div>
            <div className="w-1/2">
                <div className="shimmer   w-3/4 h-6 mb-2"></div>
                <div className="shimmer   w-full h-10"></div>
            </div>
        </div>
        <div className="flex space-x-4 items-center mt-4">
            <div className="w-1/2">
                <div className="shimmer   w-3/4 h-6 mb-2"></div>
                <div className="shimmer   w-20 h-10"></div>
            </div>
            <div className="w-1/2">
                <div className="shimmer   w-3/4 h-6 mb-2"></div>
                <div className="shimmer   w-full h-10"></div>
            </div>
        </div>
        <div className="shimmer   w-full h-8 my-4"></div>
        {/* Repeat similar shimmer    blocks for the accordion items and other content */}
        <div className="shimmer   w-full h-8 my-4"></div>
    </form>
</div>



    </>
  )
}

export default Skeleton