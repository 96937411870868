import React, { useState, useRef, useEffect } from 'react';
import mainImage from './mainImage.jpeg';
import logoImage from './logo.png';

const ImageOverlay = () => {
  const [addLogo, setAddLogo] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [finalImage, setFinalImage] = useState(null);
  const mainImageRef = useRef();
  const logoWrapperRef = useRef();

  const handleToggle = () => {
    setAddLogo(!addLogo);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    const offsetX = e.clientX - e.target.getBoundingClientRect().left;
    const offsetY = e.clientY - e.target.getBoundingClientRect().top;
    setPosition({ x: offsetX, y: offsetY });
  };

  const saveImage = () => {
    const canvas = document.createElement('canvas');
    const mainImg = mainImageRef.current;
    const logoImg = logoWrapperRef.current.querySelector('img');

    canvas.width = mainImg.width;
    canvas.height = mainImg.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(mainImg, 0, 0, canvas.width, canvas.height);
    if (addLogo) {
      ctx.drawImage(logoImg, position.x, position.y, logoImg.width, logoImg.height);
    }

    setFinalImage(canvas.toDataURL());
  };

  useEffect(() => {
    if (addLogo) {
      const logoWrapper = logoWrapperRef.current;
      logoWrapper.style.left = `${position.x}px`;
      logoWrapper.style.top = `${position.y}px`;
    }
  }, [addLogo, position]);

  return (
    <div className="flex flex-col items-center">
      <label htmlFor="addLogo" className="mb-4 text-lg">
        Add Logo:
        <input
          type="checkbox"
          id="addLogo"
          name="addLogo"
          className="ml-2"
          onChange={handleToggle}
        />
      </label>
      {addLogo && (
        <p className="text-sm text-gray-600 mb-2">Drag the logo to place it on the image</p>
      )}
      <div
        className="relative"
        onDragOver={addLogo ? onDragOver : null}
        onDrop={addLogo ? onDrop : null}
      >
        <img ref={mainImageRef} src={mainImage} alt="Main" className="w-full rounded-lg" />
        {addLogo && (
          <div
            ref={logoWrapperRef}
            className="absolute"
          >
            <img
              src={logoImage}
              alt="Logo"
              className="h-20"
              draggable="true"
            />
          </div>
        )}
      </div>
      <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={saveImage}>
        Save
      </button>
      {finalImage && (
        <div className="mt-4">
          <h2 className="mb-2 text-xl">Preview Image:</h2>
          <img src={finalImage} alt="Final" className="rounded-lg" />
        </div>
      )}
    </div>
  );
};

export default ImageOverlay;