import React, { useState, useEffect } from "react";
import useAxios from "../../../Axios/useAxios";
import useTokenFromURL from "../../../Context/useTokenFromURL";
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "./Skeleton";
import { BASE_URL } from '../../../config';
const REACT_APP_BASE_URL =BASE_URL;
const Subscription = ({widgetType,unsavedChanges,fetchWidgetOrder,setUnsavedChanges,setHasInteracted,refresh,widgetId}) => {
    useTokenFromURL();
    useAxios();
    const [formValues, setFormValues] = useState({
        widget_name: "",
        widget_title : "",
        widgetTypeInput: "",
        header_text:"",
        sub_text:"",
        text_side:""
      });
      const [formErrors, setFormErrors] = useState({});
      const [loading,setLoading]=useState(false);
      const checkErrors = () => {
        let errors = {};

        if (!formValues.widget_name?.trim()) {
          errors[`widget_name`] = "Widget name is required";
        }
        if (!formValues.header_text?.trim()) {
            errors[`header_text`] = "Header Text is required";
        }
        if (!formValues.sub_text?.trim()) {
            errors[`sub_text`] = "Sub Text is required";
        }
        if (!formValues.text_side) {
            errors[`text_side`] = "Text Side is required";
        }
    
        return errors;
    };
    
      const [DataSubmitted, setDataSubmitted] = useState(false);
      
      const IsSavedInputs = () => {
        setHasInteracted(true);
        setUnsavedChanges(true);
    };
    const handleChange = (e) => {
        // setIsFormSubmitted(true);
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        IsSavedInputs();
      };
      const handleFormSubmit = (e) => {
        e.preventDefault();
        
        // Create a new payload without widget_name and widgetTypeInput
        const payload = { ...formValues };
        // delete payload.widget_name;
        delete payload.widgetTypeInput;
    
        try {
            axios.patch(`${REACT_APP_BASE_URL}/subscription/${widgetId}`, payload).then((res)=>{
              if (DataSubmitted) {
                Swal.fire({
                  title: "Success!",
                  text: "Datas Updated successfully.",
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false,
                });
              } else {
                Swal.fire({
                  title: "Success!",
                  text: "Datas saved successfully.",
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false,
                });
              }
                fetchWidgetOrder();
                setUnsavedChanges(false);
                fetchSubscriptionData();
            })
        } catch (error) {
            console.error("Error submitting form:", error); // Log the error or handle it as required
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
        }
    };
    
    const fetchSubscriptionData = async ()=>{
        try {
            const response = await axios.get(
              `${REACT_APP_BASE_URL}/subscription/${widgetId}`
            );
            setLoading(true);
            setFormFields(response.data[0]);
          } catch (error) {
            setLoading(true);
            console.error(error);
          }
    }
    const setFormFields =(data)=>{
        setFormValues({
            widget_name: data.widget_name,
            widget_title : data.widget_title,
            widgetTypeInput: data.widget_type,
            header_text:data.header_text,
            sub_text:data.sub_text,
            text_side:data.text_side

          });
          const formsubmit = data.header_text !== "" ? true : false;
          setDataSubmitted(formsubmit);
    }
    useEffect(() => {
        fetchSubscriptionData();
      
      }, [refresh]);

      useEffect(() => {
        let errors = checkErrors();
        setFormErrors(errors);
      }, [formValues]);

  return (
    <>
    {!loading?<Skeleton/>: <div>

<h1 className="text-lg  font-poppins text-center text-gray-600">
        Subscription Customization
      </h1>
      <form className="space-y-4 pt-6">
   
      <div className="flex space-x-4">
          <div className="w-1/2  ">
            <label
              htmlFor="widget_name"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Name*
            </label>
            <input
              id="widget_name"
              name="widget_name"
              type="text"
              placeholder={formValues.widget_name
              }
              value={formValues.widget_name
              }
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {formErrors[`widget_name`] && (
              <span className="text-red-500 font-poppins text-sm">
                {formErrors[`widget_name`]}
              </span>
            )}
          </div>
          <div className="w-1/2 ">
            <label
              htmlFor="widget_title"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Title
            </label>
            <input
              id="widget_title"
              name="widget_title"
              type="text"
              placeholder={formValues.widget_title}
              value={formValues.widget_title}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="widgetTypeInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Type
            </label>
            <input
              id="widgetTypeInput"
              name="widgetTypeInput"
              type="text"
              disabled
              placeholder={formValues.widgetTypeInput}
              value={formValues.widgetTypeInput}
              className="mt-1 focus:ring-indigo-500 bg-gray-200 opacity-50 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="flex space-x-4">
        <div className="w-1/2">
            <label
              htmlFor="header_text"
              className="block text-sm font-medium text-gray-700"
            >
              Header Text*
            </label>
            <input
              id="header_text"
              name="header_text"
              type="text"
              placeholder={formValues.header_text}
              value={formValues.header_text}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
              {formErrors[`header_text`] && (
                    <span className="text-red-500 font-poppins text-sm">
                      {formErrors[`header_text`]}
                    </span>
                  )}
          </div>
        <div className="w-1/2">
            <label
              htmlFor="sub_text"
              className="block text-sm font-medium text-gray-700"
            >
              Sub Text*
            </label>
            <input
              id="sub_text"
              name="sub_text"
              type="text"
              placeholder={formValues.sub_text}
              value={formValues.sub_text}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
             {formErrors[`sub_text`] && (
                    <span className="text-red-500 font-poppins text-sm">
                      {formErrors[`sub_text`]}
                    </span>
                  )}
          </div>
            </div>
          <div className="mt-4">
    <span className="text-sm font-medium text-gray-700 mr-4">Text Side:</span>
    <div className="flex mt-3">

    <div className="inline-flex justify-center items-center mr-4">
        <input
            id="leftText"
            name="text_side"
            type="radio"
            value="left"
            checked={formValues.text_side === "left"}
            onChange={handleChange}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
        />
        <label htmlFor="leftText" className="ml-2 block text-sm text-gray-700">
            Left Text
        </label>
    </div>
    <div className="inline-flex justify-center items-center">
        <input
            id="rightText"
            name="text_side"
            type="radio"
            value="right"
            checked={formValues.text_side === "right"}
            onChange={handleChange}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
        />
        <label htmlFor="rightText" className="ml-2 block text-sm text-gray-700">
            Right Text
        </label>
    </div>
       
    </div>
    {formErrors[`text_side`] && (
                    <span className="text-red-500 font-poppins  text-sm">
                      {formErrors[`text_side`]}
                    </span>
                  )}
</div>
<div className="mt-4">
      {  Object.keys(formErrors).length !== 0 &&   <span className="font-poppins text-red-500 ">
              Must Fill all the required fields(*)
            </span>}

    
      </div>
<button
            disabled={Object.keys(formErrors).length !== 0}
          onClick={handleFormSubmit}
        //   disabled={!isFormValids() }
          className={
            Object.keys(formErrors).length !== 0
              ? "text-white font-bold py-2 px-4 rounded-md opacity-50 bg-blue-500"
              :
               `bg-blue-500 hover:bg-blue-700  text-white font-bold py-2 px-4 rounded-md`
          }
        >
         {DataSubmitted ? "Update":"Save"}
         
        </button>
        </form>
    </div>}
   
    </>
  )
}

export default Subscription
