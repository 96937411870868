import React, { useState, useEffect } from 'react'
import { FiTrash, FiUpload } from 'react-icons/fi'

const PreviewCollage = ({
  blockButtonsMapping,
  blockOneimages,
  blockTwoimages,
  blockThreeimages,
  blockFourimages,
  SelectedactiveBlock
}) => {
  const hasImages = () => {
    return [
      blockOneimages,
      blockTwoimages,
      blockThreeimages,
      blockFourimages
    ].some(blockImages => blockImages.some(image => image))
  }
  const renderUploadButton1 = () => {
    // setActiveBlockValidation(false);

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {blockOneimages && blockOneimages[0] && blockOneimages[0].image_url && (
          <>
            <img
              src={blockOneimages[0].image_url}
              alt='Selected'
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            {/* {blockOneimages[0].image_link && (
                  <a 
                    href={blockOneimages[0].image_link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{
                      position: 'absolute', 
                      bottom: '10px', 
                      left: '10px', 
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      textDecoration: 'none',
                      color: 'black'
                    }}
                  >
                    {blockOneimages[0].image_link}
                  </a>
                )} */}
          </>
        )}
      </div>
    )
  }

  const renderUploadButton2 = buttonIndex => {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}
      >
        {blockTwoimages[buttonIndex] && blockTwoimages[buttonIndex].image_url && (
          <>
            <img
              src={blockTwoimages[buttonIndex].image_url}
              alt='Selected'
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            {/* {blockTwoimages[buttonIndex].image_link && (
                <a 
                className='font-poppins'
                  href={blockTwoimages[buttonIndex].image_link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{
                    position: 'absolute', 
                    bottom: '10px', 
                    left: '10px', 
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    color: 'black'
                  }}
                >
                  {blockTwoimages[buttonIndex].image_link}
                </a>
              )} */}
          </>
        )}
      </div>
    )
  }

  const renderUploadButton3 = buttonIndex => {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}
      >
        {blockThreeimages[buttonIndex] &&
          blockThreeimages[buttonIndex].image_url && (
            <>
              <img
                src={blockThreeimages[buttonIndex].image_url}
                alt='Selected'
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              {/* {blockThreeimages[buttonIndex].image_link && (
                <a 
                  href={blockThreeimages[buttonIndex].image_link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className='font-poppins'
                  style={{
                    position: 'absolute', 
                    bottom: '10px', 
                    left: '10px', 
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    color: 'black'
                  }}
                >
                  {blockThreeimages[buttonIndex].image_link}
                </a>
              )} */}
            </>
          )}
      </div>
    )
  }
  const renderUploadButton4 = buttonIndex => {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}
      >
        {blockFourimages[buttonIndex] &&
          blockFourimages[buttonIndex].image_url && (
            <>
              <img
                src={blockFourimages[buttonIndex].image_url}
                alt='Selected'
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              {/* {blockFourimages[buttonIndex].image_link && (
                <a 
                  href={blockFourimages[buttonIndex].image_link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className='font-poppins'
                  style={{
                    position: 'absolute', 
                    bottom: '10px', 
                    left: '10px', 
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    color: 'black'
                  }}
                >
                  {blockFourimages[buttonIndex].image_link}
                </a>
              )} */}
            </>
          )}
      </div>
    )
  }
  return (
    <>
      {hasImages() ? (
        <div className='mt-4'>
          {SelectedactiveBlock !== null ? (
            <h2 className='text-md font-poppins text-center text-gray-600 mb-3'>
              Preview{' '}
            </h2>
          ) : (
            false
          )}
          {SelectedactiveBlock === 0 ? (
            <div className={`w-full p-4 bg-gray-200 rounded-lg border-2`}>
              <div className='w-full h-60 border-2 rounded-lg border-indigo-500 relative'>
                {renderUploadButton1()}
              </div>
            </div>
          ) : null}

          {SelectedactiveBlock === 1 ? (
            <div className={`w-full p-4 bg-gray-200 rounded-lg flex border-2`}>
              <div className='w-2/3 h-74 border-2 rounded-lg border-indigo-500 relative'>
                {renderUploadButton2(blockButtonsMapping[0][0])}
              </div>
              <div className='w-1/3 flex flex-col justify-between ml-2'>
                <div className='h-24 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                  {renderUploadButton2(blockButtonsMapping[0][1])}
                </div>
                <div className='h-24 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                  {renderUploadButton2(blockButtonsMapping[0][2])}
                </div>
                <div className='h-24 border-2 rounded-sm border-indigo-500 relative'>
                  {renderUploadButton2(blockButtonsMapping[0][3])}
                </div>
              </div>
            </div>
          ) : null}
          {SelectedactiveBlock === 2 ? (
            <div
              className={`w-full p-4 bg-gray-200 rounded-lg flex gap-3 border-2`}
            >
              <div className='w-2/4 h-60 border-2 rounded-sm border-indigo-500 relative'>
                {renderUploadButton3(blockButtonsMapping[2][0])}
              </div>
              <div className='w-2/4 flex flex-col justify-between gap-3'>
                <div className='flex row gap-3'>
                  <div className='w-2/4 h-28 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                    {renderUploadButton3(blockButtonsMapping[2][1])}
                  </div>
                  <div className='w-2/4 h-28 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                    {renderUploadButton3(blockButtonsMapping[2][2])}
                  </div>
                </div>
                <div className='flex row gap-3'>
                  <div className='w-2/4 h-28 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                    {renderUploadButton3(blockButtonsMapping[2][3])}
                  </div>
                  <div className='w-2/4 h-28 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                    {renderUploadButton3(blockButtonsMapping[2][4])}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {SelectedactiveBlock === 3 ? (
            <div
              className={`w-full  p-4 bg-gray-200 rounded-lg flex gap-4 justify-between border-2`}
            >
              <div className='w-1/3 h-60 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                {renderUploadButton4(blockButtonsMapping[3][0])}
              </div>
              <div className='w-1/3 h-60 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                {renderUploadButton4(blockButtonsMapping[3][1])}
              </div>
              <div className='w-1/3 h-60 border-2 rounded-sm border-indigo-500 mb-1 relative'>
                {renderUploadButton4(blockButtonsMapping[3][2])}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        false
      )}
    </>
  )
}

export default PreviewCollage