import React, { useState, useEffect } from "react";
import axios from "axios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import useAxios from "../../Axios/useAxios";
import { BASE_URL } from '../../config';
import Widgets from "./Widgets";
import WidgetDetails from "./WidgetDetails";
const REACT_APP_BASE_URL = BASE_URL;

const HomePageCustomization = () => {
  useTokenFromURL();
  useAxios();
  const [activeWidget, setActiveWidget] = useState('Banner');
  const [widgetName, setWidgetName] = useState(null);
  const [clickedWidgetId, setClickedWidgetId] = useState(7432);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [bannerDataSubmit, setBannerDataSubmit] = useState(false);
  const initialWidgetCounts = {
    "Banner": 1,
    " Collage": 1,
    "Info Slider": 1,
    "Product Slider": 1,
    "Category Slider": 1,
    "Subscription": 1,
    "Cms Custom": 1,
  };
  const [widgets, setWidgets] = useState([]);
  const [toggleBanner, setToggleBanner] = useState(false);
  const [loading,setLoading]=useState(true);
  const [widgetCounts, setWidgetCounts] = useState(initialWidgetCounts);
  const fetchWidgetOrder = () => {
    axios
      .get(`${REACT_APP_BASE_URL}/widget-order/`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'If-Modified-Since': '0'
        }
      })
      .then((res) => {
        setLoading(false);
        // Check data is in correct format
        if (res.data && Array.isArray(res.data)) {
          // Sort the data based on the 'order_by' field
          const sortedData = res.data.sort((a, b) => a.order_by - b.order_by);

          let widgetArray = [];
          let widgetTypesList = [];
          const counts = {};
          sortedData.forEach((item) => {
            const type = item.widget_name; // 'Banner', 'Collage', etc.
            if (!widgetTypesList?.includes(type) && type !== "Banner")
              widgetTypesList.push(type); // Exclude 'Banner'
            counts[type] = (counts[type] || 0) + 1;

            // Add widget object to array
            widgetArray.push({
              widget_name: item.widget_name,
              widgetType: item.widget_type,
              order_by: item.order_by,
              widgetId: item.widget_order_id,
            });
          });
          setToggleBanner(res.data[0].is_enabled === 1 ? true : false);
          setWidgets(widgetArray);
          setWidgetCounts(counts);
        }
      })
      .catch((err) =>
      {
        setLoading(false);
        console.error(err)
      }
      );
  };

  useEffect(() => {
    fetchWidgetOrder()
  }, []);

  useEffect(() => {
   const widgetType = localStorage.getItem('widgetType') || 'Banner';
   const widget_name = localStorage.getItem('widget_name');
   const widgetId = localStorage.getItem('widgetId') || 7432 ;

    setActiveWidget(widgetType)
    setWidgetName(widget_name)
    setClickedWidgetId(parseInt(widgetId))
  }, []);
  
  return (
    <>
    <div className="flex h-screen flex-col sm:flex-row">
      <Widgets
        loading={loading}
        activeWidget={activeWidget}
        setActiveWidget={setActiveWidget}
        clickedWidgetId={clickedWidgetId}
        setClickedWidgetId={setClickedWidgetId}
        bannerDataSubmit={bannerDataSubmit}
        fetchWidgetOrder={fetchWidgetOrder}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
        hasInteracted={hasInteracted}
        setHasInteracted={setHasInteracted}
        refresh={refresh}
        setRefresh={setRefresh}
        widgets={widgets}
        setWidgets={setWidgets}
        widgetCounts={widgetCounts}
        setWidgetCounts={setWidgetCounts}
        setWidgetName={setWidgetName}
        setToggleBanner={setToggleBanner}
        toggleBanner={toggleBanner}
      />
      <WidgetDetails
        activeWidget={activeWidget}
        setActiveWidget={setActiveWidget}
        toggleBanner={toggleBanner}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
        hasInteracted={hasInteracted}
        refresh={refresh}
        setHasInteracted={setHasInteracted}
        setToggleBanner={setToggleBanner}
        clickedWidgetId={clickedWidgetId}
        setBannerDataSubmit={setBannerDataSubmit}
        fetchWidgetOrder={fetchWidgetOrder}
        widgetName={widgetName}
      />
    </div>
    </>
  );
};

export default HomePageCustomization;
