import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdEmail, MdLocalPhone } from 'react-icons/md';

const PaymentFailure = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen font-poppins items-center justify-center bg-gray-50 px-4">
      <div className="text-center max-w-lg mx-auto space-y-6">
        <img 
          src="https://inai.io/hubfs/Header%20-%20Online%20payment%20failure%20reasons%20and%20how%20to%20avoid%20it_%20%281%29.png#keepProtocol" 
          alt="Payment Failure" 
          className="mx-auto mb-6"
        />
        <h2 className="text-2xl font-bold text-red-600">Payment Failure</h2>
        <p className="text-gray-700 text-lg mb-4">Oops! Something went wrong with your payment. Please try again or reach out to our support team for help:</p>
        <div className="space-y-3 mb-4">
          <a href="mailto:aswani@jpencil.com" className="flex justify-center items-center text-red-500 hover:text-red-600 transition duration-300 ease-in-out">
            <MdEmail className="text-2xl" />
            <span className="text-lg font-medium text-gray-800 ml-2">aswani@jpencil.com</span>
          </a>
          <a href="tel:+914224510121" className="flex justify-center items-center text-red-500 hover:text-red-600 transition duration-300 ease-in-out">
            <MdLocalPhone className="text-2xl" />
            <span className="text-lg font-medium text-gray-800 ml-2">+91.4224510121</span>
          </a>
        </div>
        <button 
          onClick={() => navigate('/')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Try Again
        </button>
      </div>
    </div>
  );
}

export default PaymentFailure;
