import React, { useState, useEffect, Children } from "react";
import GoDaddyUI from "./DomainManagement/GoDaddy";
import ExistingDomain from "./DomainManagement/ExistingDomain";
import WebsiteBuildeSteps from "./WebsiteBuilderSteps";
import "./style.css";
import ThemeSelector from "./ThemeManagement/ThemeManagement";
import WebsiteCustomization from "./WebsiteCustomization/WebsiteCustomization";
import HomePageCustomization from "./HomePageCustomization/HomePageCustomization";
import PagesCustomization from "./PagesCustomization/PagesCustomization";
import axios from "axios";
import useTokenFromURL from "../Context/useTokenFromURL";
import useAxios from "../Axios/useAxios";
import { useToken } from "../Context/TokenContext";
import Swal from "sweetalert2";
// Import BASE_URL from config.js
import { BASE_URL } from "../config";
import WebsiteLive from "./websiteLive/websiteLive";
import BasicModal from "./ForSupport";
import Gallery from "./Gallery";
import { useNavigate } from "react-router-dom";

/**
 * MultiStepForm Component:
 * This component manages a multi-step form which includes sections for
 * website building, domain management, customization and more.
 */
const MultiStepForm = () => {
  // Hooks to manage the authentication token and setup Axios configurations.
  useTokenFromURL();
  useAxios();
  // State declarations.
  const [step, setStep] = useState(1); // Current step in the multi-step form.
  const [themeSelected, setThemeSelected] = useState(false); // Whether a theme has been selected or not.
  const [clientName, setClientName] = useState("");
  const [liveUrl, setLiveUrl] = useState("");
  const [themeloading, setThemeLoading] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state for async operations.
  const [tokenError, setTokenError] = useState(""); // Error related to token validation.
  const { token } = useToken();
  const { domainStatusTracker } = useToken();
  // New state for domain selection
  const [domainSelection, setDomainSelection] = useState("existing"); // 'existing' or 'new'
  // const {token} = useToken();
  const { setJboId } = useToken();
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Flag to check if there are any unsaved changes.
  const [hasInteracted, setHasInteracted] = useState(false); // Flag to check if the user has interacted with the form.
  const [themeDetails, setThemeDetails] = useState(""); // Flag to check if
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const [bounce, setBounce] = useState(false);
  const history = useNavigate();

  const [domainStatus, setDomainStatus] = useState();

  useEffect(() => {
    const referrer = document.referrer;
    // j3sitebuilder.ta4.in, sitebuilder.jpencil.in, websitebuilder.jpencil.com
    var hostname = window.location.hostname;
    const hostnameReferrerMap = {
      // "j3sitebuilder.ta4.in": "http://jpjbo.ta4.in/",
      "sitebuilder.jpencil.in": "https://jbo.tanjorepainting.info/",
      "websitebuilder.jpencil.com": "https://dashboard2.jpencil.com/",
    };

    const isLocalhost = hostname.startsWith("localhost");
    const isDev = hostname.startsWith("j3sitebuilder");
    // const isStage = hostname.startsWith("sitebuilder");

    const expectedReferrer = hostnameReferrerMap[hostname];
    if (
      !isLocalhost &&
      !isDev &&
      // !isStage &&
      (!expectedReferrer || !referrer.includes(expectedReferrer))
    ) {
      window.location.href = "/errorPage";
      return;
    }
  }, [history]);

  useEffect(() => {
    if (isResetEnabled) {
      setBounce(true);
      const timer = setTimeout(() => {
        setBounce(false);
        setIsResetEnabled(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isResetEnabled]);

  useEffect(() => {
    const _step = localStorage.getItem("newStep");
    if (_step !== null) {
      setStep(parseInt(_step, 10));
    }
  }, []);

  const fetchThemeManagerData = async () => {
    try {
      await axios
        .get(`${BASE_URL}/theme-manager`)
        .then((res) => {
          setThemeDetails(res.data[0]);
          setJboId(res.data[0].jbo_id);
          setThemeLoading(false);
          setTokenError("");
          res.data[0]?.selected_theme !== 4
            ? setThemeSelected(true)
            : setThemeSelected(false);
        })
        .catch((error) => {
          const errorData =
            error?.response?.data?.message === "jwt malformed"
              ? "Token is Invalid"
              : "";
          console.log("error message", error.response.data.message);
          setTokenError(errorData);
          setThemeLoading(false);
          console.log("errorData", errorData);
          setThemeSelected(false);
          setThemeDetails("");
        });
    } catch (error) {
      setThemeDetails("");
      setTokenError(error);
      console.error(error);
      setThemeSelected(false);
    }
  };

  const fetchDomainData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/domain/domain_status`);

      setDomainStatus(response?.data.domain_status);
    } catch (error) {
      console.error("Error fetching domain data:", error);
    }
  };

  const fetchClientData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/client`);
      setClientName(response.data[0].client_name);
    } catch (error) {
      console.error(error);
    }
  };
  // const fetchDomainData = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/domain/domain_status`);
  //     // setDomainData(response.data);
  //     setDomainStatus(response.data.domain_status);
  //   } catch (error) {
  //     console.error('Error fetching domain data:', error);
  //   }
  // };
  useEffect(() => {
    fetchThemeManagerData();
    fetchClientData();
  }, [token]);

  const handleMakeWebSiteLive = (newStep) => {
    Swal.fire({
      title:
        '<span style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; color: #333;">Are you sure?</span>',
      html: '<p style="font-family: Georgia, serif; color: #555;">Do you want to make your website live?</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Second confirmation with inline styles
        Swal.fire({
          title:
            '<span style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; color: #333;">Final Confirmation</span>',
          html: '<p style="font-family: Georgia, serif; color: #555;">By Proceeding, the customizations in your preview will be set as the live content of your website. Do you want to go live?</p>',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, make it live!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            try {
              const response = await axios.post(`${BASE_URL}/go_live`);
              setLoading(false); // Turn off loading indicator after getting response
              // Success message with inline styles
              Swal.fire({
                title:
                  '<span style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; color: #333;">Congratulations!</span>',
                html: '<p style="font-family: Georgia, serif; color: #555;">Your website is live now.</p>',
                icon: "success",
                confirmButtonText: "Go Live",
              }).then(() => {
                setLiveUrl(response.data.live_url);
                setStep(newStep);
                // window.open(response.data.live_url, '_blank');
              });
            } catch (error) {
              console.error("Error going live:", error);
              setLoading(false); // Ensure loading is turned off even if there's an error
              // Error message with inline styles
              Swal.fire({
                title:
                  '<span style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; color: #333;">Error!</span>',
                html: '<p style="font-family: Georgia, serif; color: #555;">There was a problem making your website live. Please try again.</p>',
                icon: "error",
                confirmButtonText: "OK",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "custom-confirm-button",
                  cancelButton: "custom-cancel-button",
                },
              });
              // setStep(newStep);
            }
          }
        });
      }
    });
  };

  /**
   * Handler for tab change. It considers unsaved changes and user interactions
   * before deciding to change the tab.
   * @param {number} newStep - The step to navigate to.
   */
  const handleTabChange = async (newStep, tab) => {
    // If the "Website Live" tab is clicked (assuming step number 8 is for "Website Live")
    if (tab === "Website Live") {
      // Your existing logic for handling the "Website Live" tab
      handleMakeWebSiteLive(newStep, tab);
      // If the "Reset Theme" tab is clicked (assuming step number 6 is for "Reset Theme")
    } else if (tab === "Reset theme") {
      //newStep 7 is reset theme
      // Call the Swal function for resetting the theme
      Swal.fire({
        title: "Are you sure?",
        text: "Resetting the theme will erase all your current settings and data. Do you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reset it!",
      }).then((result) => {
        if (result.isConfirmed) {
          resetTheme(); // Call your resetTheme function here
        }
      });
    } else if (!unsavedChanges && hasInteracted && tab !== "Preview") {
      //8 is preview
      // Your existing logic for handling unsaved changes and user interaction for other tabs
      Swal.fire({
        title: "Unsaved changes",
        text: "You have unsaved changes. Are you sure you want to leave?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, leave",
        cancelButtonText: "No, stay",
      }).then((result) => {
        if (result.isConfirmed) {
          setUnsavedChanges(false); // Reset the unsaved changes flag
          setHasInteracted(false); // Reset the user interaction flag
          proceedTabChange(newStep); // Proceed to change the tab
        }
      });
    } else if (tab === "Preview") {
      // Assuming step number 8 is for "Preview"
      try {
        const response = await axios.get(`${BASE_URL}/preview`);
        // Open the preview URL in a new tab
        // window.open(response.data.preview_url, '_blank');
        // Open the preview URL in a new tab
        const previewWindow = window.open(response.data.preview_url, "_blank");
        // Optionally attempt to force reload in the new tab
        if (previewWindow) {
          previewWindow.location.reload(true); // This may or may not force reload from server depending on browser
        }
      } catch (error) {
        console.error("Error fetching preview:", error);
        // Handle the error appropriately (e.g., show an error message)
        Swal.fire({
          title: "Error!",
          text: "There was a problem fetching the preview. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      // No unsaved changes, user interaction, reset, or preview, proceed to change the tab
      proceedTabChange(newStep);
    }
  };

  const resetTheme = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/theme-manager/reset_theme`);
      if (response.status === 200) {
        setThemeSelected(false); // Reset the theme selection state
        setStep(3); // Switch to the ThemeSelector component
        fetchThemeManagerData();
        // setThemeDetails("");
        Swal.fire("Reset!", "Theme has been reset successfully.", "success");
      }
    } catch (error) {
      console.error("Error resetting theme:", error);
      Swal.fire("Error!", "There was a problem resetting the theme.", "error");
    }
  };

  const proceedTabChange = (newStep) => {
    if (!themeSelected && newStep > 2 && newStep !== 9) {
      // Ensure the Preview step can still be accessed to trigger the API call
      setStep(3);
    } else {
      localStorage.setItem("newStep", newStep);
      setStep(newStep);
      localStorage.setItem("widgetType", "");
      localStorage.setItem("widget_name", "");
      localStorage.setItem("widgetId", "");
    }
  };

  /**
   * Method to update the state when a theme is selected.
   */
  const onThemeSelected = () => {
    fetchThemeManagerData();
    setThemeSelected(true);
  };
  // Tab labels for the multi-step form.
  // Define the tabs, always including the "Preview" tab after a theme is selected
  // Initialize the base tabs
  const tabs = [
    "Website Builder Steps",
    "Domain Management",
    "Theme Customization",
    "HomePage Customization",
    "Pages Customization",
    "Website Customization",
    "Gallery",
    "Reset theme",
    ...(themeSelected ? ["Preview"] : []), // Keep "Preview" logic as is
  ];

  // Conditionally include "Website Live" tab
  if (localStorage.getItem("domainTracker") === "active" && themeSelected) {
    tabs.push("Website Live");
  }
  if (themeloading) {
    return (
      <>
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      </>
    );
  }

  const changeTab = (val) => {
    switch (val) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      case 3:
        setStep(4);
        break;
      case 4:
        if (!themeDetails) {
          return setStep(3);
        }
        setStep(6);
        break;
      case 5:
        handleMakeWebSiteLive(10);
        break;
    }
  };

  return (
    <div className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 min-h-screen font-poppins">
      {tokenError ? (
        <div className="flex items-center justify-center h-screen">
          <div className="w-[50rem]  shadow-lg rounded-lg p-5">
            <div className="flex justify-center items-center r">
              <img
                className="rounded-lg"
                src="https://blog.scorechain.com/wp-content/uploads/2022/10/Untitled-design-14.jpg"
                alt=""
              />
            </div>
            <h2 className="text-2xl text-white font-poppins mt-4 text-center">
              Invalid Token
            </h2>
            <p className="text-white text-xl font-poppins mt-2 text-center">
              Your Token is Invalid . Please,try again or register .
            </p>
          </div>
        </div>
      ) : (
        <div className="container mx-auto px-4 py-5">
          <div className="text-center mb-6">
            <h1 className="text-2xl md:text-3xl lg:text-2xl text-white font-bold mb-2 ">
              Delighted to Welcome
              <span className="uppercase text-yellow-300"> {clientName} </span>
              on board! Together, Let's Craft Something Remarkable.
            </h1>
          </div>
          <div className="bg-white  p-6 rounded-lg shadow-lg grid grid-cols-1 lg:grid-cols-5 gap-4 ">
            <div className="col-span-1">
              <div className="space-y-4 font-poppins sticky top-8">
                <div className="space-y-4 font-poppins sticky top-8">
                  {tabs.map((tab, index) => (
                    <button
                      key={tab}
                      onClick={() => handleTabChange(index + 1, tab)}
                      className={`${
                        step === index + 1
                          ? "bg-indigo-500 text-white border-indigo-500"
                          : "bg-white text-indigo-500 border-indigo-500"
                      } w-full px-4 text-sm py-2 rounded-lg shadow-md focus:outline-none transition-all duration-300 ease-in-out transform hover:scale-105 ${
                        step === index + 1 ? "scale-110" : ""
                      } ${
                        index === 7 && !themeSelected
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      } ${
                        bounce && index === 7
                          ? "animate-bounce border-x-4 border-indigo-500"
                          : ""
                      }`}
                      disabled={index === 7 && !themeSelected}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-span-4 transition-all duration-300 ease-in-out">
              <div className="overflow-auto h-screen">
                {step === 1 && (
                  <WebsiteBuildeSteps
                    callback={changeTab}
                    themeSelected={themeSelected}
                    themeDetails={themeDetails}
                  />
                )}
                {step === 2 && (
                  <div className="flex flex-col items-center justify-center mt-4">
                    <div className="">
                      {domainStatusTracker !== "active" &&
                        domainStatusTracker !== "pending" && (
                          <>
                            <label className="inline-flex items-center">
                              <input
                                type="radio"
                                className="w-5 h-5 text-blue-600 form-radio focus:ring-blue-500 border-gray-300"
                                name="domainType"
                                value="existing"
                                checked={domainSelection === "existing"}
                                onChange={() => setDomainSelection("existing")}
                              />
                              <span className="ml-2 text-gray-700">
                                Existing Domain
                              </span>
                            </label>
                            <label className="inline-flex items-center ml-6">
                              <input
                                type="radio"
                                className="w-5 h-5 text-pink-600 form-radio focus:ring-pink-500 border-gray-300"
                                name="domainType"
                                value="new"
                                checked={domainSelection === "new"}
                                onChange={() => setDomainSelection("new")}
                              />
                              <span className="ml-2 text-gray-700">
                                New Domain
                              </span>
                            </label>
                          </>
                        )}
                    </div>
                    <div className="w-full pt-10">
                      {domainSelection === "existing" ? (
                        <ExistingDomain />
                      ) : (
                        <GoDaddyUI />
                      )}
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <ThemeSelector
                    onThemeSelected={onThemeSelected}
                    themeDetails={themeDetails}
                    setIsResetEnabled={setIsResetEnabled}
                    themeSelected={themeSelected}
                    setThemeDetails={setThemeDetails}
                  />
                )}

                {!themeSelected && (step === 4 || step === 5) && (
                  <ThemeSelector
                    onThemeSelected={onThemeSelected}
                    themeDetails={themeDetails}
                    setIsResetEnabled={setIsResetEnabled}
                    themeSelected={themeSelected}
                    setThemeDetails={setThemeDetails}
                  />
                )}
                {themeSelected && step === 4 && (
                  <>
                    <HomePageCustomization />
                  </>
                )}
                {themeSelected && step === 5 && (
                  <>
                    <PagesCustomization
                      unsavedChanges={unsavedChanges}
                      setUnsavedChanges={setUnsavedChanges}
                      hasInteracted={hasInteracted}
                      setHasInteracted={setHasInteracted}
                    />
                  </>
                )}
                {themeSelected && step === 6 && (
                  <>
                    <WebsiteCustomization
                      unsavedChanges={unsavedChanges}
                      setUnsavedChanges={setUnsavedChanges}
                      hasInteracted={hasInteracted}
                      setHasInteracted={setHasInteracted}
                    />
                  </>
                )}
                {/* here comes the gallery component */}
                {step === 7 && (
                  <div>
                    <Gallery dimension="1:1" fromTabs="true" />
                  </div>
                )}
                {step === 9 && <div></div>}
                {step === 10 && (
                  <div>
                    <WebsiteLive liveUrl={liveUrl} />
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-2">
                <BasicModal />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
