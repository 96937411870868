import React from "react";
import logo from "../../../images/logo.webp";
import ctbanner from "../../../images/ct_banner.webp";
import ct1 from "../../../images/ct_haram.webp";
import ct2 from "../../../images/ct_haram1.webp";
import ct3 from "../../../images/ct_bangle.webp";
import ct4 from "../../../images/ct_haram2.webp";
import ct5 from "../../../images/ct_necklace.webp";
import ct6 from "../../../images/ct_slider1.webp";
import ct7 from "../../../images/ct_slider2.webp";
import ct8 from "../../../images/ct_slider3.webp";
import ct9 from "../../../images/ct_slider4.webp";
import ct10 from "../../../images/ct_slider5.webp";
import ct11 from "../../../images/ct_necklace1.webp";
import ct12 from "../../../images/ct_stonebangle.webp";
import ct13 from "../../../images/ct_ring1.webp";
import ct14 from "../../../images/ct_snecklace.webp";
import ct15 from "../../../images/ct_snethichutti.webp";
import ct16 from "../../../images/ct_spendent.webp";
import info1 from "../../../images/m1.webp";
import info2 from "../../../images/m2.webp";
import info3 from "../../../images/m3.webp";
import info4 from "../../../images/m4.webp";
import info5 from "../../../images/m5.webp";
import { FaSearch } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaBars } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";

const Contemporary = () => {
  return (
    <>
      <div className="bg-ct_secondary py-2">
        <div className="container mx-auto grid grid-cols-2">
          <div className="text-lg font-semibold font-primary">
            Today's Metal Rate
          </div>
          <div className="flex justify-end items-center text-lg font-semibold ">
            Plan Your Investment
          </div>
        </div>
      </div>

      <div className="container mx-auto flex items-center justify-between py-4">
        <div className="flex items-center">
          <img className="w-64 h-24" src={logo} alt="img" />
        </div>

        <div className="flex-1 px-4">
          <div className="relative flex items-center justify-center w-full">
            <input
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded-lg p-4 pr-12 w-2/3"
            />
            <FaSearch className="absolute right-40 mr-4 text-gray-400 bg-ct_primary p-2 w-14 h-full rounded text-white" />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex flex-col items-center mr-4">
            <IoBag className="text-[25px]" />
            <span className="text-md font-semibold ">Cart</span>
          </div>

          <div className="flex flex-col items-center mr-4">
            <FaRegHeart className="text-[25px]" />
            <span className="text-md font-semibold">Wishlist</span>
          </div>

          <div className="flex flex-col items-center">
            <FaUser className="text-[25px]" />
            <span className="text-md font-semibold ">Login</span>
          </div>
        </div>
      </div>

      <div className="bg-ct_secondary">
        <div class="container mx-auto text-md flex items-center space-x-8">
          <div class="flex items-center">
            <h1 class="bg-ct_primary text-white px-4 py-2 rounded flex items-center">
              <FaBars class="mr-2" />
              Categories
            </h1>
          </div>

          <ul class="list-none p-0 m-0 flex space-x-10 font-semibold">
            <li>Home</li>
            <li>About Us</li>
            <li>Contact Us</li>
            <li>Gold Plan</li>
          </ul>
        </div>
      </div>

      {/* End of Header Section */}

      <div className="">
        <img className="image" src={ctbanner} alt="banner-image"></img>
      </div>

      {/* End of Banner Content */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Embrace the Beauty of Our Jewels
      </div>

      <div className=" container mx-auto grid grid-cols-1 sm:grid-cols-2 gap-3">
        <div className="row-span-3">
          <img className="image " src={ct1} alt="" />
        </div>

        <img className="image" src={ct2} alt="" />
        <img className="image" src={ct3} alt="" />
        <img className="image" src={ct4} alt="" />
      </div>

      {/* End of section1 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        {" "}
        Discover Our Unique Jewellery Collection
      </div>

      <div className="flex justify-center">
        <img className="image" src={ct5} alt="img"></img>
      </div>

      {/* End of section2 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        A Visual Feast of Jewellery Delights
      </div>

      <div className=" container mx-auto grid grid-cols-5 gap-4">
        <div className="justify-self-center">
          <img className="image" src={ct6} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">
            Wooden Handicrafts
          </p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={ct7} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Haram</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={ct8} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Ganesha</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={ct9} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Bangles</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={ct10} alt="img"></img>
          <p className=" flex justify-center font-semibold  my-7">
            Brass Sculptures
          </p>
        </div>
      </div>

      {/* End of section3 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        {" "}
        Dive into Our Luxurious World of Jewels
      </div>

      <div className=" container mx-auto grid grid-cols-3 gap-4">
        <img className="image" src={ct11} alt="img"></img>
        <img className="image" src={ct12} alt="img"></img>
        <img className="image" src={ct13} alt="img"></img>
      </div>

      {/* End of section4 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        {" "}
        Promises
      </div>

      <div className="container mx-auto text-center grid grid-cols-5 gap-4">
        <div className="bg-pink-50 text-center rounded-lg">
          <div className="flex justify-center py-5 border-b-2 border-Slate-50">
            <img className="image " src={info1} alt="img"></img>
          </div>
          <div className="text-center border-b-2 border-Slate-50 py-3">
            100% Trust
          </div>
          <div className="text-center  py-5">
            Trust the BIS hallmark your guarantee of superior quality and
            purity.
          </div>
        </div>

        <div className="bg-pink-50 text-center rounded-lg">
          <div className="flex justify-center py-5 border-b-2 border-Slate-50">
            <img className="image " src={info2} alt="img"></img>
          </div>
          <div className="text-center border-b-2 border-Slate-50 py-3">
            Complete Transparency
          </div>
          <div className="text-center  py-5">
            Enjoy a lifetime of reassurance with our exchange policy.
          </div>
        </div>

        <div className="bg-pink-50 text-center rounded-lg">
          <div className="flex justify-center py-5 border-b-2 border-Slate-50">
            <img className="image " src={info3} alt="img"></img>
          </div>
          <div className="text-center border-b-2 border-Slate-50 py-3">
            Life time exchange
          </div>
          <div className="text-center  py-5">
            Enjoy a lifetime of reassurance with our exchange policy.
          </div>
        </div>

        <div className="bg-pink-50 text-center rounded-lg">
          <div className="flex justify-center py-5 border-b-2 border-Slate-50">
            <img className="image " src={info4} alt="img"></img>
          </div>
          <div className="text-center border-b-2 border-Slate-50 py-3">
            Free Shipping
          </div>
          <div className="text-center  py-5">
            Enjoy complimentary shipping on all orders today!
          </div>
        </div>

        <div className="bg-pink-50 text-center rounded-lg">
          <div className="flex justify-center py-5 border-b-2 border-Slate-50">
            <img className="image " src={info5} alt="img"></img>
          </div>
          <div className="text-center border-b-2 border-Slate-50 py-3">
            15 day exchange policy
          </div>
          <div className="text-center  py-5">
            Enjoy a worry-free exchange within 15 days of purchase.
          </div>
        </div>
      </div>

      {/* End of section5 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        {" "}
        Explore Our World of Timeless Elegance
      </div>

      <div className=" container mx-auto grid grid-cols-3 gap-4">
        <img className="image" src={ct14} alt="img"></img>
        <img className="image" src={ct15} alt="img"></img>
        <img className="image" src={ct16} alt="img"></img>
      </div>

      {/* End of section6 */}

      <div className="bg-ct_primary w-full mt-5">
        <div className="container mx-auto py-4">
          <div className="grid grid-cols-2 gap-8">
            <article className="text-white flex items-center space-x-4">
              <div className="flex items-center justify-center border border-slate-50 rounded-full bg-slate-50 w-10 h-10">
                <FaBell className="text-black" />
              </div>
              <div>
                <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                <p>
                  Get all the latest information on Events, Sales and Offers.
                </p>
              </div>
            </article>

            <form className="flex items-center">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full px-3 py-2 border rounded-lg mr-2"
              ></input>
              <button
                type="submit"
                className="bg-ct_secondary text-black font-semibold px-4 py-2 rounded-lg"
              >
                <span>SUBSCRIBE</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-ct_secondary grid grid-cols-3 gap-4 ">
        <div className="flex justify-center">
          <img className="image w-64 h-24  " src={logo} alt="img"></img>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">GET US HELP YOU</h2>
          <ul className="list-none p-0 m-0">
            <li className="py-2 ">Contact Us</li>
            <li className="py-2">Terms and Condition</li>
            <li className="py-2">FAQ</li>
          </ul>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">OUR POLICIES</h2>
          <ul className="list-none p-0 m-0 ">
            <li className="py-2 ">Privacy Policy</li>
            <li className="py-2">Shipping Policy</li>
            <li className="py-2 ">Cancellation Policy</li>
            <li className="py-2 ">Exchange Policy</li>
          </ul>
        </div>
      </div>

      <div className=" bg-ct_primary ">
        <div className="container mx-auto text-white">
          ⓒ All rights reserved
        </div>
      </div>

      {/* End of footer section */}
    </>
  );
};

export default Contemporary;
