import React from "react";
import logo from "../../../images/logo.webp";
import mdbanner from "../../../images/md_banner.webp";
import md1 from "../../../images/md_haram.webp";
import md2 from "../../../images/md_necklace.webp";
import md3 from "../../../images/md_ring.webp";
import md4 from "../../../images/md_bangle.webp";
import md5 from "../../../images/md_earring.webp";
import md6 from "../../../images/md_slider1.webp";
import md7 from "../../../images/md_slider2.webp";
import md8 from "../../../images/md_slider3.webp";
import md9 from "../../../images/md_slider4.webp";
import md10 from "../../../images/md_slider5.webp";
import md11 from "../../../images/md_haram1.webp";
import md12 from "../../../images/md_snecklace.webp";
import md13 from "../../../images/md_schain.webp";
import md14 from "../../../images/md_sbracelet.webp";
import md15 from "../../../images/md_sbangle.webp";
import md16 from "../../../images/md_bracelet.webp";
import md17 from "../../../images/md_pendent.webp";
import md18 from "../../../images/md_gearring.webp";
import md19 from "../../../images/md_haram2.webp";
import { FaSearch } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaBars } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";

const Modern = () => {
  return (
    <>
      <div className="bg-md_secondary py-2">
        <div className="container mx-auto grid grid-cols-2">
          <div className="text-lg font-semibold">Today's Metal Rate</div>
          <div className="flex justify-end items-center text-lg font-semibold">
            Plan Your Investment
          </div>
        </div>
      </div>

      <div className="container mx-auto flex items-center justify-between py-4">
        <div className="flex items-center">
          <img className="w-64 h-24" src={logo} alt="img" />
        </div>

        <div className="flex-1 px-4">
          <div className="relative flex items-center justify-center w-full">
            <input
              type="text"
              placeholder="Search..."
              className="border border-gray-300 rounded-lg p-4 pr-12 w-2/3"
            />
            <FaSearch className="absolute right-40 mr-4 text-gray-400 bg-md_primary p-2 w-14 h-full rounded text-white" />
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex flex-col items-center mr-4">
            <IoBag className="text-[25px]" />
            <span className="text-md font-semibold">Cart</span>
          </div>

          <div className="flex flex-col items-center mr-4">
            <FaRegHeart className="text-[25px]" />
            <span className="text-md font-semibold">Wishlist</span>
          </div>

          <div className="flex flex-col items-center">
            <FaUser className="text-[25px]" />
            <span className="text-md font-semibold">Login</span>
          </div>
        </div>
      </div>

      <div className="bg-md_secondary">
        <div class="container mx-auto text-md flex items-center space-x-8">
          <div class="flex items-center">
            <h1 class="bg-md_primary text-white px-4 py-2 rounded flex items-center">
              <FaBars class="mr-2" />
              Categories
            </h1>
          </div>

          <ul class="list-none p-0 m-0 flex space-x-10 font-semibold">
            <li>Home</li>
            <li>About Us</li>
            <li>Contact Us</li>
            <li>Gold Plan</li>
          </ul>
        </div>
      </div>

      {/* End of Header Section */}

      <div className="">
        <img className="image" src={mdbanner} alt="banner-image"></img>
      </div>

      {/* End of Banner Content */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Where Every Jewel Tells a Story
      </div>

      <div className=" container mx-auto grid grid-cols-2 ">
        <img className="image" src={md1} alt="img"></img>
        <div className="grid grid-cols-2 grid-rows-2 gap-2">
          <img className="image" src={md2} alt="img"></img>
          <img className="image" src={md3} alt="img"></img>
          <img className="image" src={md4} alt="img"></img>
          <img className="image" src={md5} alt="img"></img>
        </div>
      </div>
      {/* End of section1 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Experience Our Jewellery Showcase
      </div>

      <div className=" container mx-auto grid grid-cols-5 gap-4">
        <div className="justify-self-center">
          <img className="image" src={md6} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7 ">
            Wooden Handicrafts
          </p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={md7} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Haram</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={md8} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Ganesha</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={md9} alt="img"></img>
          <p className=" flex justify-center font-semibold my-7">Bangles</p>
        </div>

        <div className="justify-self-center">
          <img className="image" src={md10} alt="img"></img>
          <p className=" flex justify-center font-semibold  my-7">
            Brass Sculptures
          </p>
        </div>
      </div>

      {/* End of section2 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Revel in Our Timeless Treasures
      </div>

      <div className="flex justify-center">
        <img className="image" src={md11} alt="img"></img>
      </div>

      {/* End of section3 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Revel in the Beauty of Classic Jewellery
      </div>

      <div className=" container mx-auto grid grid-cols-1 sm:grid-cols-2 gap-3">
        <div className="row-span-3">
          <img className="image " src={md12} alt="" />
        </div>

        <img className="image" src={md13} alt="" />
        <img className="image" src={md14} alt="" />
        <img className="image" src={md15} alt="" />
      </div>

      {/* End of section4 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Adorn Yourself with Antique Elegance
      </div>

      <div className=" container mx-auto grid grid-cols-3 gap-4">
        <img className="image" src={md16} alt="img"></img>
        <img className="image" src={md17} alt="img"></img>
        <img className="image" src={md18} alt="img"></img>
      </div>

      {/* End of section5 */}

      <div className="flex justify-center text-2xl font-semibold my-7">
        Journey into Our Celestial Collection of Jewels
      </div>

      <div className="container mx-auto flex justify-center">
        <img className="image" src={md19} alt="img"></img>
      </div>

      {/* End of section6 */}

      <div className="bg-md_primary w-full mt-5">
        <div className="container mx-auto py-4">
          <div className="grid grid-cols-2 gap-8">
            <article className="text-white flex items-center space-x-4">
              <div className="flex items-center justify-center border border-slate-50 rounded-full bg-slate-50 w-10 h-10">
                <FaBell className="text-black" />
              </div>
              <div>
                <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                <p>
                  Get all the latest information on Events, Sales and Offers.
                </p>
              </div>
            </article>

            <form className="flex items-center">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full px-3 py-2 border rounded-lg mr-2"
              ></input>
              <button
                type="submit"
                className="bg-md_secondary text-black font-semibold px-4 py-2 rounded-lg"
              >
                <span>SUBSCRIBE</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-md_secondary grid grid-cols-3 gap-4 ">
        <div className="flex justify-center">
          <img className="image w-64 h-24  " src={logo} alt="img"></img>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">GET US HELP YOU</h2>
          <ul className="list-none p-0 m-0">
            <li className="py-2 ">Contact Us</li>
            <li className="py-2">Terms and Condition</li>
            <li className="py-2">FAQ</li>
          </ul>
        </div>

        <div>
          <h2 className="my-4 text-black font-semibold">OUR POLICIES</h2>
          <ul className="list-none p-0 m-0 ">
            <li className="py-2 ">Privacy Policy</li>
            <li className="py-2">Shipping Policy</li>
            <li className="py-2 ">Cancellation Policy</li>
            <li className="py-2 ">Exchange Policy</li>
          </ul>
        </div>
      </div>

      <div className=" bg-md_primary ">
        <div className="container mx-auto text-white">
          ⓒ All rights reserved
        </div>
      </div>

      {/* End of footer section */}
    </>
  );
};

export default Modern;
