import React, { createContext, useContext, useState } from 'react';

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqYm9faWQiOjI0OSwidXNlcl9pZCI6MTY0LCJvdHBfdmVyaWZpY2F0aW9uIjowLCJreWNfdmVyaWZpY2F0aW9uIjoxLCJhY2NlcHRfcG9saWN5IjoxLCJpYXQiOjE3MDA3MzIyMDV9.N5ZS3Pi7xoxs4SH62-G7FArrid0xio5AQy3bssmcbzo'); // Set the default token value here
 
  const [jboId, setJboId] = useState(201); // Set the default token value here
  const [domainStatusTracker,setDomainStatusTracker]=useState();
  
  return (
    <TokenContext.Provider value={{ token, setToken,jboId ,setJboId,domainStatusTracker,setDomainStatusTracker}}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error('useToken must be used within a TokenProvider');
  }
  return context;
};

