import { useEffect } from 'react';
import axios from 'axios';
import { useToken } from '../Context/TokenContext';

const useAxios = () => {
  const { token } = useToken();
  const { setToken} = useToken();

  useEffect(() => {
    const interceptor = axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    // When the component unmounting or token changes, eject the interceptor
    return () => axios.interceptors.request.eject(interceptor);
  }, [token,setToken]); // Run this effect whenever the token changes
};

export default useAxios;
