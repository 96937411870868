import React from 'react'

const Skeleton = () => {
  return (
    <div className="p-4 space-y-6 animate-pulse">
      <div className="space-y-4">
        <div className="flex space-x-4">
          <div className="w-1/2">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="mt-2 h-6 bg-gray-300 rounded"></div>
          </div>
          <div className="w-1/2">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="mt-2 h-6 bg-gray-300 rounded"></div>
          </div>
        </div>

        <div className="flex space-x-4">
          <div className="w-1/2">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="mt-2 h-6 bg-gray-300 rounded"></div>
            <div className="mt-2 h-4 bg-gray-300 rounded w-2/3"></div>
          </div>
          <div className="w-1/2">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="mt-2 h-6 bg-gray-300 rounded"></div>
            <div className="mt-2 h-4 bg-gray-300 rounded w-2/3"></div>
          </div>
        </div>

        <div className="mt-4 flex">
          <div className="h-4 bg-gray-300 rounded w-1/4 mr-4"></div>
          <div className="flex space-x-4">
            <div className="h-4 w-4 bg-gray-300 rounded mr-2"></div>
          </div>
          <div className="flex space-x-4">
            <div className="h-4 w-4 bg-gray-300 rounded mr-2"></div>
          </div>
        </div>

        <div className="h-10 bg-gray-300 rounded w-1/4"></div>
      </div>
    </div>
  )
}

export default Skeleton