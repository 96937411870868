import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const ErrorUI = ({ message }) => {
  return (
    <Stack sx={{ width: "100%", marginBottom: "1rem" }} spacing={2}>
      <Alert severity="error">{message}</Alert>
    </Stack>
  );
};

export default ErrorUI;
