import React from 'react'

const Skeleton = () => {
  return (
    <>

<div>
  <div className="shimmer h-8 w-3/4 mb-5"></div>
  
  <div className="flex space-x-4">
    <div className="w-1/2">
      <div className="shimmer h-5 w-1/4 mb-2"></div>
      <div className="shimmer h-10 w-full"></div>
    </div>
    <div className="w-1/2">
      <div className="shimmer h-5 w-1/4 mb-2"></div>
      <div className="shimmer h-10 w-full"></div>
    </div>
  </div>
  
  <div className="mt-5">
    <div className="shimmer h-5 w-1/3 mx-auto mb-5"></div>
    <div className="flex gap-4">
      <div className="shimmer h-40 w-full lg:w-1/2 p-4"></div>
      <div className="shimmer h-40 w-full lg:w-1/2 p-4"></div>
    </div>
    <div className="flex mt-5 gap-4">
      <div className="shimmer h-48 w-1/2 p-4"></div>
      <div className="shimmer h-48 w-1/2 p-4"></div>
    </div>
  </div>

  <div className="mt-5">
    <div className="shimmer h-5 w-2/5 mx-auto mb-5"></div>
    <div className="shimmer h-60 w-full p-4"></div>
  </div>

  <div className="mt-5">
    <div className="shimmer h-6 w-1/3 mx-auto mb-5"></div>
    <div className="shimmer h-10 w-full"></div>
  </div>
  
  <div className="mt-4">
    <div className="shimmer h-6 w-1/3 mx-auto mb-3"></div>
    <div className="shimmer h-10 w-full mb-3"></div>
  </div>
  
  <div className="mt-5">
    <div className="shimmer h-5 w-3/5 mx-auto mb-5"></div>
    <div className="shimmer h-10 w-1/4 mx-auto"></div>
  </div>
</div>

    </>
  )
}

export default Skeleton