import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import axios from "axios";
import useAxios from "../../Axios/useAxios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import { BASE_URL } from '../../config';
const REACT_APP_BASE_URL = BASE_URL;

/**
 * MenuCustomization Component
 *
 * This component allows users to customize their menus, and provides an interactive interface
 * to toggle between different menu styles, view associated errors, and submit changes.
 */

const MenuCustomization = ({ activeAccordion, onToggle, IsSavedInputs }) => {
  // Utilizing custom hooks to manage tokens from the URL and axios configurations.
  useTokenFromURL();
  useAxios();
  // State definitions:
  const [selectedMenuType, setSelectedMenuType] = useState("Megamenu"); // Default to "Megamenu"
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  /**
   * fetchMenuData - Asynchronous function to retrieve menu data.
   *
   * This function connects to the backend to fetch the current configuration of the menu.
   * On successful data fetch, it updates the component state with the fetched data.
   */
  const fetchMenuData = async () => {
    try {
      const endpoint = `${REACT_APP_BASE_URL}/menu-bar`;
      const response = await axios.get(endpoint);
      const submitValidate = response.data[0] !== null ? true : false;
      setDataSubmitted(submitValidate);
      if (response.status === 200) {
        setSelectedMenuType(response.data[0].type_of_menu); // assuming you want the first object from the returned array
      } else {
        // Handle non-200 status codes as needed
        Swal.fire({
          title: "Error!",
          text: "Failed to retrieve menu data.",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An error occurred while fetching data. Please try again.",
        icon: "error",
      });
    }
  };

  // useEffect hook to fetch menu data once when the component mounts
  useEffect(() => {
    fetchMenuData();
  }, [dataSubmitted]); // empty dependency array ensures this runs once when the component mounts

  /**
   * handleSubmit - Asynchronous function to handle data submission.
   *
   * This function pushes menu changes to the backend and updates the user interface accordingly.
   */
  const handleSubmit = async () => {
    try {
      // Define the API endpoint and payload
      const endpoint = `${REACT_APP_BASE_URL}/menu-bar`;
      const payload = {
        type_of_menu: selectedMenuType,
      };

      // Make the API call
      const response = await axios.patch(endpoint, payload);

      if (response.status === 200 || response.status === 201) {
        // Success message or any other operation after successful submission

        if (dataSubmitted) {
          Swal.fire({
            title: "Success!",
            text: "Datas Updated successfully.",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: "Success!",
            text: "Datas saved successfully.",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        }
        fetchMenuData();
      } else {
        // Handle any other status code accordingly
        Swal.fire({
          title: "Error!",
          text: "An error occurred. Please try again.",
          icon: "error",
        });
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      Swal.fire({
        title: "Error!",
        text: "An error occurred. Please try again.",
        icon: "error",
      });
    }
  };

  /**
   * handleAccordionChange - Function to manage accordion behavior.
   *
   * This toggles between opening and closing the 'MenuAccordian'.
   */
  const handleAccordionChange = () => {
    if (activeAccordion === "MenuAccordian") {
      onToggle && onToggle(null); // close the current accordion if it's 'AuthAccordian'.
    } else {
      onToggle && onToggle("MenuAccordian"); // open the 'AuthAccordian' if another accordion is currently active.
    }
  };
  return (
    <div>
      <Accordion
        className="bg-opacity-50"
        expanded={activeAccordion === "MenuAccordian"}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontFamily: "poppins" }}>Menu Bar</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex items-center">
            <label
              htmlFor="
                        "
            >
              Type of Menu : &nbsp;
            </label>
            <label className="flex items-center mr-4">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                checked={selectedMenuType === "Megamenu"}
                onChange={() => {
                  setSelectedMenuType("Megamenu");
                  IsSavedInputs();
                }}
              />
              <span className="ml-2">Mega menu</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                checked={selectedMenuType === "Simple menu"}
                onChange={() => {
                  setSelectedMenuType("Simple menu");
                  IsSavedInputs();
                }}
              />
              <span className="ml-2">Simple menu</span>
            </label>
          </div>
          <button
            onClick={handleSubmit}
            className={`bg-gradient-to-r from-indigo-500 to-blue-600 mt-4 hover:from-indigo-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
              Object.keys.length > 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            //   disabled={Object.keys(errors).length > 0}
          >
            {dataSubmitted ? "Update" : "Save"}
          </button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MenuCustomization;
