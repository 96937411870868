import React, { useState, useEffect } from "react";
import useAxios from "../../../Axios/useAxios";
import useTokenFromURL from "../../../Context/useTokenFromURL";
import axios from "axios";
import Swal from "sweetalert2";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Skeleton from "./Skeleton"
import { BASE_URL } from '../../../config';
import { useToken } from '../../../Context/TokenContext';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// Base URL fetched from global config
const REACT_APP_BASE_URL = BASE_URL;

const CmsBlock = ({widgetType,unsavedChanges,fetchWidgetOrder,setUnsavedChanges,setHasInteracted,refresh,widgetId}) => {

       // Using hooks to handle authentication and setup for axios
  useTokenFromURL();
  useAxios();

  // Component state initialization
  const [formValues, setFormValues] = useState({
    widget_name: "",
    widget_title: "",
    widgetTypeInput: "",
    
  });

  const {jboId} = useToken();
  const [customId, setCustomId] = useState(null);
  const [loading,setLoading]=useState(false);
  const [DataSubmitted, setDataSubmitted] = useState(false);
  const [CustomContentData, setCustomContentData] = useState(); // For SunEditor content
  const [errors, setErrors] = useState([]);
  const [selectedEditor, setSelectedEditor] = React.useState('editor');
  const [htmlContent, setHtmlContent] = useState('');
  const [IsHtmlOrWysiwyg, setIsHtmlOrWysiwyg] = useState();

  // Handlers for custom editor
  const handleEditorChange = (contentData) => {
    setCustomContentData(contentData);
    IsSavedInputs();
  };

  // toggle b/w Wysiwyg and html textarea
  const handleEditorToggle = (event, type) => {
    if (type !== null && type !== selectedEditor) {
      setSelectedEditor(type);
    }
  };

  //Handlers for Html  textarea
  const handleInputChange = (e) => {
    const { value } = e.target;
    setHtmlContent(value)
  };

  // choose one to show
  const handleWysiwygOrHtml = (event) => {
    const radioValue = Number(event.target.value);
    setIsHtmlOrWysiwyg(radioValue);
  };

  // Handlers for unsaved Changes data
  const IsSavedInputs = () => {
    setHasInteracted(true);
    setUnsavedChanges(true);
};
   // Handlers for formValues
  const handleChange = (e) => {
    // setIsFormSubmitted(true);
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    IsSavedInputs();
  };

   // Fetching existing widget data using widgetId
  const fetchCustomData = async ()=>{
    try {
        const response = await axios.get(
          `${REACT_APP_BASE_URL}/cms-custom/${widgetId}`
        );
        setLoading(true);
        setFormFields(response.data[0]);
        if (response.data[0].is_html_tag === 1) {
          setSelectedEditor('html')
        }
        setIsHtmlOrWysiwyg(response.data[0].is_html_tag);
        // setHtmlContent(response.data[0].cms_custom_editor);
      } catch (error) {
        setLoading(true);
        console.error(error);
      }
}
 // Setting form fields from fetched data
const setFormFields =(data)=>{
    setFormValues({
        widget_name: data.widget_name,
        widget_title: data.widget_title,
        widgetTypeInput: data.widget_type,
      });
      setHtmlContent(data.html_editor)
      setCustomContentData(data.cms_custom_editor);

      setCustomId(data.cms_custom_id);
      const formsubmit = data.cms_custom_editor !== null ? true : false;
      setDataSubmitted(formsubmit);
}
 // Fetch data when 'refresh' changes
useEffect(() => {
    fetchCustomData();
  
  },[refresh]);

  // useEffect(() => {
  //   if (recivedData.is_html_tag === 1) {
      
  //   }
  // },[recivedData.is_html_tag]);

      // On submitting the datas for custom editor
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await axios.patch(`${REACT_APP_BASE_URL}/cms-custom/${widgetId}`, {
            cms_custom_id: customId,
            widget_name: formValues.widget_name, 
            widget_title: formValues.widget_title,
            widget_type: formValues.widgetTypeInput,
            cms_custom_editor: CustomContentData,
            html_editor : htmlContent,
            is_html_tag : IsHtmlOrWysiwyg
        });
        fetchWidgetOrder();
        setUnsavedChanges(false);
        fetchCustomData();
        if (DataSubmitted) {
          Swal.fire({
            title: "Success!",
            text: "Datas Updated successfully.",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: "Success!",
            text: "Datas saved successfully.",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        }
    } catch (error) {
        console.error("Error updating data:", error);
        Swal.fire("Error", "Failed to save data", "error");
    }
};
// Validation: Check for errors in the custom Block errors
const checkErrors = () => {
    let errors = {};

    // const cleanedContent = CustomContentData !== "<p><br></p>";
    if ((!CustomContentData || CustomContentData ===  "<p><br></p>") && !htmlContent) {
        errors.content = "Content is required";
    }

    // Check if widget_name in formValues is empty
    if (!formValues.widget_name || formValues.widget_name.trim() === "") {
        errors.widget_name = "Widget Name is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
};

 // Run validation every time the content or widget_name changes
useEffect(() => {
    checkErrors();
}, [CustomContentData, htmlContent, formValues]);

  // Render component
  return (
    <>
    {!loading?<Skeleton/>: <div>
      <h1 className="text-lg  font-poppins text-center text-gray-600">
        Custom Blocks Customization
      </h1>
      <form className="space-y-4 pt-6">
      <div className="flex space-x-4">
          <div className="w-1/2  ">
            <label
              htmlFor="widget_name"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Name*
            </label>
            <input
              id="widget_name"
              name="widget_name"
              type="text"
              placeholder={formValues.widget_name
              }
              value={formValues.widget_name
              }
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              
            />
            <div className="mt-4">
             {errors.widget_name && (
                  <span className="text-red-700 mt-6 bg-red-50 font-poppins px-2 py-1 rounded shadow-md">
                      {errors.widget_name}
                  </span>
              )}
            </div>
          </div>
          <div className="w-1/2 ">
            <label
              htmlFor="widget_title"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Title
            </label>
            <input
              id="widget_title"
              name="widget_title"
              type="text"
              placeholder={formValues.widget_title}
              value={formValues.widget_title}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="widgetTypeInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Type
            </label>
            <input
              id="widgetTypeInput"
              name="widgetTypeInput"
              type="text"
              disabled
              placeholder={formValues.widgetTypeInput}
              value={formValues.widgetTypeInput}
              className="mt-1 focus:ring-indigo-500 bg-gray-200 opacity-50 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
                {/* {customPage.content &&  <PreviewPage content={customPage.content}/>} */}
        </div>
                <label className="font-medium font-poppins pt-3 text-gray-600 block">
                  Custom Page Content
                </label>

                <ToggleButtonGroup
                  color="primary"
                  value={selectedEditor}
                  exclusive
                  onChange={handleEditorToggle}
                  aria-label="Platform"
                  >
                  <ToggleButton value="editor">Web Editor</ToggleButton>
                  <ToggleButton value="html">HTML</ToggleButton>
                </ToggleButtonGroup>

                {selectedEditor === 'editor' ?
                <div>
                <SunEditor
                    setDefaultStyle="font-family: poppins; font-size: 15px;"
                    setOptions={{
                      height: 400,
                      attributesWhitelist: {
                        all: 'style'
                    },
                      buttonList: [
                        [
                          "font",
                          "fontSize",
                          "bold",
                          "subscript",
                          "superscript",
                          "underline",
                        ], // Added 'font'
                        ["fontColor", "hiliteColor", "outdent", "indent", "link"],
                        ["codeView", "undo", "redo", "align", "list", "table"], // Added 'codeView'
                        ["image", "imageGallery", "video", "fullScreen", "preview"],
                      ],
                      imageGalleryUrl:`${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
                      // To define custom fonts:
                      font: [
                        "Arial",
                        "Calibri",
                        "Comic Sans",
                        "Courier",
                        "Poppins",
                        "Times New Roman",
                      ],
                      // You can add other options according to your needs
                    }}
                    setContents={CustomContentData}  // set the editor content
                    onChange={handleEditorChange}
                  />
                </div> : 
                <div> 
                <textarea
                  name="postContent"
                  rows={20} 
                  cols={74}
                  value={htmlContent}
                  onChange={handleInputChange}
                  
                />
                </div>}
                <div className="mt-1">
                    {errors.content && (
                        <span className="text-red-700 mt-6 bg-red-50 font-poppins px-2 py-1 rounded shadow-md">
                            {errors.content}
                        </span>
                    )}
                </div>
                <div>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">Select One</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={IsHtmlOrWysiwyg}
                    onChange={handleWysiwygOrHtml}
                  >
                    <FormControlLabel disabled={(!CustomContentData || CustomContentData ===  "<p><br></p>")} value="0" control={<Radio />} label="Web Editor" />
                    <FormControlLabel disabled={!htmlContent} value="1" control={<Radio />} label="HTML" />
                  </RadioGroup>
                </FormControl>
                </div>
                <button
                  disabled={Object.keys(errors).length !== 0}
                  onClick={handleSubmit}
                  className={
                    Object.keys(errors).length !== 0
                      ? "text-white font-bold py-2 px-4 rounded-md opacity-50 bg-blue-500"
                      :
                      `bg-blue-500 hover:bg-blue-700  text-white font-bold py-2 px-4 rounded-md`
                  }
                  >
                  {DataSubmitted ? "Update":"Save"}
                </button>
        </form>
    </div>}
   
    </>
  )
}

export default CmsBlock
