// Function to determine the base URL and domain URL based on the hostname
const getUrls = () => {
  const hostname = window.location.hostname;
  
  let baseUrl, domainUrl;
  if (hostname === "localhost") {
    baseUrl = "https://dev.darjewellery.com/api/v3"; // Development base URL
    domainUrl = "https://sitebuilder.jpencil.in/api"; // Development domain URL
  } else {
    baseUrl = `https://${hostname}/api/v3`; // Production base URL
    domainUrl = `https://${hostname}/api`; // Adjusted for consistency in production domain URL
  }

  return { baseUrl, domainUrl };
};

// Retrieve URLs
const urls = getUrls();

// Export the URLs so they can be used throughout your application
export const BASE_URL = urls.baseUrl;
export const DOMAIN_URL = urls.domainUrl;
