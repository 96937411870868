import React, { useState } from "react";
import Gallery from "./Gallery";
import "./upload.css"
import Modal from "react-modal";

Modal.setAppElement("#root");

const Upload = () => {
  const [selectedImage, setSelectedImage] = useState(
    "https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1"
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleImageSelect = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <div className="container mx-auto w-1/3 p-5 items-center">
        <div className="flex justify-center items-center">
          <img
            src={selectedImage}
            alt=""
            className="w-full h-auto object-cover rounded-lg shadow-md transition duration-200 ease-in-out transform hover:scale-105 cursor-pointer"
            onClick={openModal}
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal"
        overlayClassName="overlay"
      >
        <button
          onClick={() => setModalIsOpen(false)}
          className="close-button"
        >
          X
        </button>
        <Gallery onImageSelect={handleImageSelect} closeModal={() => setModalIsOpen(false)}/>
      </Modal>
    </>
  );
};

export default Upload;