import React, { useState, useEffect } from "react";
import ReactSwitch from "react-switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { FiLink } from 'react-icons/fi';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "react-modal";
import Gallery from "../../Gallery";
import { AiOutlinePlus } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";
import { MdDelete, MdFileUpload } from "react-icons/md";
import { CgClose } from 'react-icons/cg';
import axios from "axios";
import Swal from "sweetalert2";
import  useTokenFromURL  from '../../../Context/useTokenFromURL';
import useAxios from '../../../Axios/useAxios';
import Skelton from "./Skeleton"
// Import BASE_URL from config.js
import { BASE_URL } from '../../../config';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
const REACT_APP_BASE_URL = BASE_URL;
const BannerCustomization = ({
  setBannerDataSubmit,
  widgetType,
  hasInteracted,
  setHasInteracted,
  setUnsavedChanges,
  widgetName,
  widgetId,
  fetchWidgetOrder,
}) => {
  // Your original state variables
  const [formValues, setFormValues] = useState({
    widgetNameInput: "",
    widgetTittleInput : "",
    widgetTypeInput: "",
    initialVideoBanner: false,
    videoLinkInput: "",
    rotationSeconds: 0,
  });
  useTokenFromURL();
  useAxios();
  const [selectedImages, setSelectedImages] = useState(Array(3).fill({ image_url: null, image_link: null }));
  const [showModal, setShowModal] = useState(false);
  const [currentEditingImageIndex, setCurrentEditingImageIndex] = useState(null);
  const [menuLinkInput, setMenuLinkInput] = useState("");

  const [selectedTabImages, setSelectedTabImages] = useState(
    Array(3).fill({ image_url: null, image_link: null })
  );
  const [showModal2, setShowModal2] = useState(false);
  const [currentEditingImageIndex2, setCurrentEditingImageIndex2] = useState(null);
  const [menuLinkInput2, setMenuLinkInput2] = useState("");

  const [selectedMobileImages, setSelectedMobileImages] = useState(
    Array(3).fill({ image_url: null, image_link: null })
  ); // New state for mobile images
  const [showModal3, setShowModal3] = useState(false);
  const [currentEditingImageIndex3, setCurrentEditingImageIndex3] = useState(null);
  const [menuLinkInput3, setMenuLinkInput3] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading,setLoading]=useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [DataSubmitted, setDataSubmitted] = useState(false);
  // New state variable for current view
  const [currentView, setCurrentView] = useState("");
  const [dimension, setDimesnion] = useState('');

  const handleChange = (e) => {
    setIsFormSubmitted(true);
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormValues({ ...formValues, [e.target.name]: value });
    setHasInteracted(true);
  };
  useEffect(() => {
    if (isFormSubmitted === false) {
      // Add this check to avoid setting unsaved changes to true when form is being submitted
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
    }
  }, [formValues, isFormSubmitted]);
  const toggleFunc = (checked) => {
    setFormValues({ ...formValues, initialVideoBanner: checked });
  };
  // Function to validate form and images
  const validateFormAndImages = () => {
    // Check if there's any required input is missing
    let isFormValid = formValues.widgetNameInput && formValues.widgetTypeInput;

    // Check if at least one image is added in each array
    let isImagesValid =
      selectedImages.some(Boolean) &&
      selectedTabImages.some(Boolean) &&
      selectedMobileImages.some(Boolean);

    // Update save button status
    setIsSaveButtonDisabled(!(isFormValid && isImagesValid));
  };

  // Your code ...

  // Monitor changes of your form values and images arrays
  useEffect(() => {
    validateFormAndImages();
  }, [formValues, selectedImages, selectedTabImages, selectedMobileImages]);

  // Updated openModal functions
  const openModal = (index) => {
    setDimesnion('36:13')
    setCurrentIndex(index);
    setCurrentView("computer");
    setModalIsOpen(true);
  };

  const openTabModal = (index) => {
    setDimesnion('36:18')
    setCurrentIndex(index);
    setCurrentView("tab");
    setModalIsOpen(true);
  };
  // New openMobileModal function
  const openMobileModal = (index) => {
    setDimesnion('38:18')
    setCurrentIndex(index);
    setCurrentView("mobile");
    setModalIsOpen(true);
  };
  const handleImageSelect = (imageUrl) => {
    const updateImageArray = (images) =>
      images.map((img, idx) =>
        idx === currentIndex ? { ...img, image_url: imageUrl } : img
      );
  
    if (currentView === "computer") {
      setSelectedImages(updateImageArray(selectedImages));
    } else if (currentView === "tab") {
      setSelectedTabImages(updateImageArray(selectedTabImages));
    } else if (currentView === "mobile") {
      setSelectedMobileImages(updateImageArray(selectedMobileImages));
    }
    closeModal();
  };
  
  // Updated closeModal function
  const closeModal = () => {
    setCurrentView("");
    setModalIsOpen(false);
  };

  const removeTabImageInput = (index) => {
    setSelectedTabImages(selectedTabImages.filter((_, idx) => idx !== index));
  };

  const addTabImageInput = (e) => {
    e.preventDefault();
    if (selectedTabImages.length < 5) {
      setSelectedTabImages([...selectedTabImages, { image_url: null, image_link: null }]);
    }
  };
  const removeMobileImageInput = (index) => {
    setSelectedMobileImages(
      selectedMobileImages.filter((_, idx) => idx !== index)
    );
  };

  const addMobileImageInput = (e) => {
    e.preventDefault();
   
    if (selectedMobileImages.length < 5) {
      setSelectedMobileImages([...selectedMobileImages, { image_url: null, image_link: null }]);
    }
  };
  const addImageInput = (e) => {
    e.preventDefault();
    if (selectedImages.length < 5) {
      setSelectedImages([...selectedImages, { image_url: null, image_link: null }]);
    }
};


  const removeImageInput = (index) => {
    setSelectedImages(selectedImages.filter((_, idx) => idx !== index));
  };
  const clearFormValues = () => {
    // Update the form values to the initial state
    setFormValues({
      widgetNameInput: "",
      rotationSeconds: "",
      videoLinkInput: "",
    });

    // Clear the selected images
    setSelectedMobileImages([]);
    setSelectedImages([]);
    setSelectedTabImages([]);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
        try {
      const response = await axios.patch(
        `${REACT_APP_BASE_URL}/custom-banner/${widgetId}`,
        {
          widget_name: formValues.widgetNameInput,
          widget_title: formValues.widgetTittleInput,
          banner_rotation: Number(formValues.rotationSeconds),
          mobile_view_image: selectedMobileImages,
          computer_view_image: selectedImages,
          is_video_enabled: formValues.initialVideoBanner === true ? 1 : 0,
          tab_view_image: selectedTabImages,
          video_link: formValues.videoLinkInput,
        }
      );

      Swal.fire({
        icon: "success",
        title: "Data sent successfully.",
        showConfirmButton: false,
        timer: 1500,
      });
      setUnsavedChanges(false);
      fetchWidgetOrder();
      setBannerDataSubmit(true);
      fetchBannerData();
      // Clear all form values after successful submission
      // clearFormValues();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    } finally {
      setIsFormSubmitted(false); // Set isFormSubmitted back to false when form submission is complete
    }
  };
  // New function to fetch banner data and set form fields
  const fetchBannerData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/custom-banner/${widgetId}`
      );
      
      setFormFields(response.data[0]);
      setLoading(true);
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };

  // New function to set form fields
  const setFormFields = (data) => {
    setFormValues({
      widgetNameInput: data.widget_name,
      widgetTittleInput: data.widget_title,
      widgetTypeInput: data.widget_type,
      initialVideoBanner: data.is_video_enabled === 1 ? true : false,
      videoLinkInput: data.video_link,
      rotationSeconds: data.banner_rotation,
    });
  
    const formsubmit = data.computer_view_image && data.computer_view_image.length !== 0 ? true: false ;
    setDataSubmitted(formsubmit);
    
    // Note: I'm setting the full objects containing both image_url and image_link
    setSelectedImages(data.computer_view_image); // adjusted key
    setSelectedTabImages(data.tab_view_image); // adjusted key
    setSelectedMobileImages(data.mobile_view_image); // adjusted key
  };
  

  useEffect(() => {
    fetchBannerData();
  }, []);

  // Function for computer view starts 
  const handleLinkClick = (index) => {
    setCurrentEditingImageIndex(index);  // set the current image being edited
    setMenuLinkInput(selectedImages[index]?.image_link || '');
    setShowModal(true);
  };
  const closeModal1 = () => {
    setShowModal(false);
  };
  const handleModalSubmit = (e) => {
    e.preventDefault();
    const newImages = [...selectedImages];
    
    if(currentEditingImageIndex !== null) {
      newImages[currentEditingImageIndex] = {
        ...newImages[currentEditingImageIndex], 
        image_link: menuLinkInput
      };
    }
    
    setSelectedImages(newImages);
    closeModal1();
  };
   // Function for computer view Ends

  // Function for Tab view starts 
  const handleLinkClick2 = (index) => {
    setCurrentEditingImageIndex2(index);  // set the current image being edited
    setMenuLinkInput2(selectedTabImages[index]?.image_link || '');
    setShowModal2(true);
  };
  const closeModal2 = () => {
    setShowModal2(false);
  };
  const handleModalSubmit2 = (e) => {
    e.preventDefault();
    const newImages = [...selectedTabImages];
    
    if(currentEditingImageIndex2 !== null) {
      newImages[currentEditingImageIndex2] = {
        ...newImages[currentEditingImageIndex2], 
        image_link: menuLinkInput2
      };
    }
    
    setSelectedTabImages(newImages);
    closeModal2();
  };
   // Function for Tab view Ends
  // Function for Mobile view starts 
  const handleLinkClick3 = (index) => {
    setCurrentEditingImageIndex3(index);  // set the current image being edited
    setMenuLinkInput3(selectedMobileImages[index]?.image_link || '');
    setShowModal3(true);
  };
  const closeModal3 = () => {
    setShowModal3(false);
  };
  const handleModalSubmit3 = (e) => {
    e.preventDefault();
    const newImages = [...selectedMobileImages];
    
    if(currentEditingImageIndex3 !== null) {
      newImages[currentEditingImageIndex3] = {
        ...newImages[currentEditingImageIndex3], 
        image_link: menuLinkInput3
      };
    }
    
    setSelectedMobileImages(newImages);
    closeModal3();
  };
   // Function for Mobile view Ends
  return (
    <>
    {!loading ? <Skelton/> :  <div>
      <h1 className="text-lg text-center font-poppins text-gray-600">
      Banner Customization
      </h1>
      <form className="space-y-4 pt-3">
        <div className="flex space-x-4">
          <div className="w-1/2 ">
            <label
              htmlFor="widgetNameInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Name*
            </label>
            <input
              id="widgetNameInput"
              name="widgetNameInput"
              type="text"
              placeholder={formValues.widgetNameInput}
              value={formValues.widgetNameInput}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-1/2 ">
            <label
              htmlFor="widgetTittleInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Title
            </label>
            <input
              id="widgetTittleInput"
              name="widgetTittleInput"
              type="text"
              placeholder={formValues.widgetTittleInput}
              value={formValues.widgetTittleInput}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="widgetTypeInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Type
            </label>
            <input
              id="widgetTypeInput"
              name="widgetTypeInput"
              type="text"
              disabled
              placeholder={widgetType}
              value={formValues.widgetTypeInput}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 bg-gray-200 opacity-50  focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="flex space-x-4 items-center">
          <div className="w-1/2 ">
            <label
              htmlFor="initialVideoBanner"
              className="block text-sm font-medium  text-gray-700"
            >
              Initial Video Banner
            </label>
            <div className="pt-3">
              <ReactSwitch
                id="initialVideoBanner"
                name="initialVideoBanner"
                checked={formValues.initialVideoBanner}
                onChange={toggleFunc}
                offColor="#767777"
                onColor="#81b0ff"
                offHandleColor="#ffffff"
                onHandleColor="#ffffff"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={40}
                className="react-switch"
              />
            </div>
          </div>
          {formValues.initialVideoBanner && (
            <div className="w-1/2">
              <label
                htmlFor="videoLinkInput"
                className="block text-sm font-medium text-gray-700"
              >
                Video Link
              </label>
              <input
                id="videoLinkInput"
                name="videoLinkInput"
                type="text"
                placeholder={formValues.videoLinkInput}
                value={formValues.videoLinkInput}
                onChange={handleChange}
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          )}
        </div>
        <h2 className="text-md font-poppins text-gray-500">Banner Images</h2>{" "}
        {/*Add Banner Images title*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>General Instructions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <label
              htmlFor="rotationSeconds"
              className="block text-sm font-medium text-gray-700"
            >
              Rotation Seconds (0 for No rotations)
            </label>
            <input
              id="rotationSeconds"
              name="rotationSeconds"
              type="number"
              min={0}
              max={10}
              value={formValues.rotationSeconds}
              onChange={handleChange}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="font-semibold">
              Computer View (1080 * 720)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
            {selectedImages.map((imageObj, index) => (
    <div className="my-2 bg-gray-100 p-4 rounded-md" key={index}>
      <div className="flex items-center justify-between space-x-4">
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontSize: "1rem", fontFamily: "Poppins" }}
        >
          Banner Image {index + 1}
        </Typography>
        <div className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer h-24 w-32">
          {imageObj.image_url ? (
          <img alt='banner'
              src={imageObj.image_url}
              className="w-full h-full object-cover rounded-md"
          />
            ) : (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        openModal(index);
                    }}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
                >
                    <FiUpload size="1.5em" />
                </button>
            )}
        </div>
        <div
          className="text-red-500  cursor-pointer"
          style={{
            color:"white",
            backgroundColor: imageObj.image_link ? 'green' : 'blue',
            borderRadius: '50%',
            cursor: 'pointer',
            border: 'none',
            padding: "6px",
          }}
          onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleLinkClick(index); }}
        >
          <FiLink size="1em" />
        </div>
        <div
          onClick={(e) => {
            e.preventDefault();
            openModal(index);
        }}
        >
          <Tooltip title="Upload New" placement="top">
            <IconButton color="primary">
              <MdFileUpload  />
            </IconButton>
          </Tooltip>
        </div>
        <div
          onClick={() => removeImageInput(index)}
        >
          <Tooltip title="Delete" placement="top">
            <IconButton>
              <DeleteIcon className="text-red-500 cursor-pointer"/>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  ))}
{
        showModal && currentEditingImageIndex !== null && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-96">
              <button onClick={closeModal1} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
                <CgClose size="1.5em" />
              </button>
              <div className="flex flex-col items-start space-y-4 font-poppins">
                <label className="flex flex-col w-full">
                  <span className="mb-2 text-lg"> Menu link URL for image {currentEditingImageIndex + 1}:</span>
                  <input
                    type="text"
                    name={`linkURL_${currentEditingImageIndex}`}
                    value={menuLinkInput}
                    className="p-2 border rounded-md focus:ring-1 focus:ring-indigo-500 focus:outline-none"
                    onChange={(e) => setMenuLinkInput(e.target.value)}
                  />
                </label>
                <button 
                  onClick={handleModalSubmit}
                  className="mt-4 flex justify-center bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )
      }
              {selectedImages.length < 5 && (
                <button
                  onClick={addImageInput}
                  className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 mt-4"
                >
                  <AiOutlinePlus size="1.5em" />
                  <span>Add Banner</span>
                </button>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Gallery Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal"
          overlayClassName="overlay"
        >
          <Gallery onImageSelect={handleImageSelect} dimension={dimension} closeModal={closeModal}/>
        </Modal>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="font-semibold">
              Tab View (800 * 600)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {selectedTabImages.map((imageObj, index) => (
                <div className="my-2 bg-gray-100 p-4 rounded-md" key={index}>
                  <div className="flex items-center justify-between space-x-4">
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: "1rem", fontFamily: "Poppins" }}
                    >
                      Banner Image {index + 1}
                    </Typography>
                    <div className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer h-24 w-32">
                      {imageObj.image_url ?  (
                        <img alt="banner"
                        src={imageObj.image_url}
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            openTabModal(index);
                          }}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
                        >
                          <FiUpload size="1.5em" />
                        </button>
                      )}
                    </div>
                    <div
                      className="text-red-500  cursor-pointer"
                      style={{
                        color:"white",
                        backgroundColor: imageObj.image_link ? 'green' : 'blue',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        border: 'none',
                        padding: "6px",
                      }}
                      onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleLinkClick2(index); }}
                    >
                      <FiLink size="1em" />
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        openTabModal(index);
                    }}
                    >
                      <Tooltip title="Upload New" placement="top">
                        <IconButton color="primary">
                          <MdFileUpload  />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div
                      className="text-red-500 cursor-pointer"
                      onClick={() => removeTabImageInput(index)}
                    >
                      <MdDelete size="1.5em" />
                    </div>                   
                  </div>
                </div>
              ))}
{
        showModal2 && currentEditingImageIndex2 !== null && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-96">
              <button onClick={closeModal2} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
                <CgClose size="1.5em" />
              </button>
              <div className="flex flex-col items-start space-y-4 font-poppins">
                <label className="flex flex-col w-full">
                  <span className="mb-2 text-lg"> Menu link URL for image {currentEditingImageIndex2 + 1}:</span>
                  <input
                    type="text"
                    name={`linkURL_${currentEditingImageIndex2}`}
                    value={menuLinkInput2}
                    className="p-2 border rounded-md focus:ring-1 focus:ring-indigo-500 focus:outline-none"
                    onChange={(e) => setMenuLinkInput2(e.target.value)}
                  />
                </label>
                <button 
                  onClick={handleModalSubmit2}
                  className="mt-4 flex justify-center bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )
      }
              {selectedTabImages.length < 5 && (
                <button
                  onClick={addTabImageInput}
                  className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 mt-4"
                >
                  <AiOutlinePlus size="1.5em" />
                  <span>Add Banner</span>
                </button>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Gallery Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal"
          overlayClassName="overlay"
        >
          <Gallery onImageSelect={handleImageSelect} dimension={dimension} closeModal={closeModal}/>
        </Modal>
        {/* Mobile view */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="font-semibold">
              Mobile View (640 * 1136)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {selectedMobileImages.map((imageObj, index) => (
                <div className="my-2 bg-gray-100 p-4 rounded-md" key={index}>
                  <div className="flex items-center justify-between space-x-4">
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: "1rem", fontFamily: "Poppins" }}
                    >
                      Banner Image {index + 1}
                    </Typography>
                    <div className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer h-24 w-32">
                      {imageObj.image_url  ? (
                        <img alt="banner"
                        src={imageObj.image_url}
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            openMobileModal(index);
                          }}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
                        >
                          <FiUpload size="1.5em" />
                        </button>
                      )}
                    </div>
                    <div
                      className="text-red-500  cursor-pointer"
                      style={{
                        color:"white",
                        backgroundColor: imageObj.image_link ? 'green' : 'blue',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        border: 'none',
                        padding: "6px",
                      }}
                      onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleLinkClick3(index); }}
                    >
                      <FiLink size="1em" />
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        openMobileModal(index);
                    }}
                    >
                      <Tooltip title="Upload New" placement="top">
                        <IconButton color="primary">
                          <MdFileUpload  />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div
                      className="text-red-500 cursor-pointer"
                      onClick={() => removeMobileImageInput(index)}
                    >
                      <MdDelete size="1.5em" />
                    </div>
                    
                  </div>
                </div>
              ))}
              {
        showModal3 && currentEditingImageIndex3 !== null && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-96">
              <button onClick={closeModal3} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
                <CgClose size="1.5em" />
              </button>
              <div className="flex flex-col items-start space-y-4 font-poppins">
                <label className="flex flex-col w-full">
                  <span className="mb-2 text-lg"> Menu link URL for image {currentEditingImageIndex3 + 1}:</span>
                  <input
                    type="text"
                    name={`linkURL_${currentEditingImageIndex3}`}
                    value={menuLinkInput3}
                    className="p-2 border rounded-md focus:ring-1 focus:ring-indigo-500 focus:outline-none"
                    onChange={(e) => setMenuLinkInput3(e.target.value)}
                  />
                </label>
                <button 
                  onClick={handleModalSubmit3}
                  className="mt-4 flex justify-center bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )
      }
              {selectedMobileImages.length < 5 && (
                <button
                  onClick={addMobileImageInput}
                  className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 mt-4"
                >
                  <AiOutlinePlus size="1.5em" />
                  <span>Add Banner</span>
                </button>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <div>
          {isSaveButtonDisabled ? (
            <span className="font-poppins text-red-500 ">
              Must fill required feilds(*) and Upload One image in three Views{" "}
            </span>
          ) : (
            false
          )}
        </div>
        <button
          disabled={isSaveButtonDisabled}
          onClick={handleFormSubmit}
          className={
            isSaveButtonDisabled
              ? "text-white font-bold py-2 px-4 rounded-md opacity-50 bg-blue-500"
              : `bg-blue-500 hover:bg-blue-700  text-white font-bold py-2 px-4 rounded-md`
          }
        >
      {DataSubmitted ? "Update":"Save"}
        </button>
        {/* Gallery Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal"
          overlayClassName="overlay"
        >
          <Gallery onImageSelect={handleImageSelect} dimension={dimension} closeModal={closeModal}/>
        </Modal>
      </form>
    </div>}
  
    </>
  );
};

export default BannerCustomization;

